import {
  POST_LIST_REQUEST,
  POST_LIST_SUCCESS,
  POST_LIST_FAIL,
  POST_LIST_RESET,
  POST_SEARCH_REQUEST,
  POST_SEARCH_SUCCESS,
  POST_SEARCH_FAIL,
  POST_SEARCH_RESET,
  POST_SINGLE_REQUEST,
  POST_SINGLE_SUCCESS,
  POST_SINGLE_FAIL,
  POST_SINGLE_RESET,
} from "constants/postConstants";

export const listPostsReducer = (state = { postsList: [] }, action) => {
  switch (action.type) {
    case POST_LIST_REQUEST:
      return { loading: true };
    case POST_LIST_SUCCESS:
      return { loading: false, postsList: action.payload, success: true };
    case POST_LIST_FAIL:
      return { loading: false, error: action.payload };
    case POST_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const searchPostsReducer = (state = { postsSearch: [] }, action) => {
  switch (action.type) {
    case POST_SEARCH_REQUEST:
      return { loading: true };
    case POST_SEARCH_SUCCESS:
      return { loading: false, postsSearch: action.payload, success: true };
    case POST_SEARCH_FAIL:
      return { loading: false, error: action.payload };
    case POST_SEARCH_RESET:
      return [];
    default:
      return state;
  }
};

export const singlePostReducer = (state = { postSingle: {} }, action) => {
  switch (action.type) {
    case POST_SINGLE_REQUEST:
      return { loading: true };
    case POST_SINGLE_SUCCESS:
      return { loading: false, postSingle: action.payload, success: true };
    case POST_SINGLE_FAIL:
      return { loading: false, error: action.payload };
    case POST_SINGLE_RESET:
      return { postSingle: {} };
    default:
      return state;
  }
};
