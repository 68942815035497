import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKPageHeaderVideoTemplate from "components/MKPageHeaderVideoTemplate";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import ScrollIcons from "pages/SustainabilityCircularity/components/ScrollIcons";
import chartImage from "assets/images/chart.jpg";

// Videos
import videoSustain from "assets/sustain.mp4";

function SustainabilityCircularity() {
  const theme = useTheme();
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.between("xxs", "xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXXLargeScreen = useMediaQuery(theme.breakpoints.between("xl", "xxl"));
  const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <>
      <MKPageHeaderVideoTemplate
        bgVideo={videoSustain}
        title="Sustainability & Circularity"
        subTitle="A blueprint for Sustainable Aquaculture"
      >
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", my: 2, mx: "auto", px: 0.75 }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            Sustainability is at the heart of everything we do and that’s why we
            developed a truly circular system that is a unique and innovative
            sustainable solution for protein production and fertilizer
            production.
          </MKTypography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", my: 2, mx: "auto", px: 0.75 }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            We are mitigating the drawbacks of conventional aquaculture/lake or
            cage farming recycling water, maximising space and using no
            chemicals that are environmentally harmful and polluting.
          </MKTypography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          lg={12}
          flexDirection="column"
          alignItems="center"
          sx={{
            textAlign: "center",
            my: 6,
            mx: "auto",
            px: 0.75,
          }}
        >
          <Card>
            <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
              <MKBox
                component="img"
                src={chartImage}
                alt="chart"
                borderRadius="lg"
                width="100%"
                height={isSmallScreen ? "350px" : "600px"}
                sx={{
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                position="relative"
                zIndex={1}
              />
              <MKBox
                borderRadius="lg"
                shadow="md"
                width="100%"
                height="100%"
                position="absolute"
                left={0}
                top={0}
                sx={{
                  backgroundImage: `url(${chartImage})`,
                  transform: "scale(0.94)",
                  filter: "blur(12px)",
                  backgroundSize: "cover",
                }}
              />
            </MKBox>
          </Card>
        </Grid>

        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", my: 2, mx: "auto", px: 0.75 }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            Our entire production cycle is designed to be truly circular and
            minimize environmental impact. We use organic feeds sourced locally,
            we are >90% water-efficient with a rainwater top-up, we are
            space-efficient, producing x15 more yield per m2 floor space and our
            operations are powered by renewable solar energy. We use no
            chemicals or antibiotics; our waste is reused and we produce net
            zero carbon emissions.
          </MKTypography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", my: 2, mx: "auto", px: 0.75 }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            Not only do we have sustainable practices, but we also care about
            our local communities, demonstrating our commitment to not just
            sustaining but enhancing the environment we operate in.
          </MKTypography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            UN sustainable development goals (SDGs). Sustainability is at the
            heart of what we do and we look towards the UN sustainable
            development goals as our guide to how we contribute most. These are
            our adopted SDG's.
          </MKTypography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          lg={12}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 2, mx: "auto", px: 0.75 }}
        >
          <ScrollIcons />
        </Grid>
      </MKPageHeaderVideoTemplate>
    </>
  );
}

export default SustainabilityCircularity;
