import Axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = "https://vertical-lake-be.vercel.app";
Axios.defaults.baseURL = API_URL;

export class HttpService {
  _axios = Axios.create();

  addRequestInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.request.use(onFulfilled, onRejected);
  };

  addResponseInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.response.use(onFulfilled, onRejected);
  };

  get = async (url) => await this.request(this.getOptionsConfig("get", url));

  post = async (url, data) =>
    await this.request(this.getOptionsConfig("post", url, data));

  put = async (url, data) =>
    await this.request(this.getOptionsConfig("put", url, data));

  patch = async (url, data) =>
    await this.request(this.getOptionsConfig("patch", url, data));

  delete = async (url, data) =>
    await this.request(this.getOptionsConfig("delete", url, data));

  getOptionsConfig = (method, url, data) => {
    return {
      method,
      url,
      data,
      headers: {
        // "Content-Type": "application/vnd.api+json",
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
  };

  request(options) {
    return new Promise((resolve, reject) => {
      this._axios
        .request(options)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(
            "🚀 ~ file: htttp.service.js:45 ~ HttpService ~ returnnewPromise ~ err:",
            err
          );
          reject(err.response?.data);
        });
    });
  }
}

export default new HttpService();
