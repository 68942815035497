import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  listAdminsReducer,
  singleAdminReducer,
  createAdminReducer,
  updateAdminReducer,
} from "reducers/adminReducers";
import {
  myListOrdersReducer,
  statsOrdersReducer,
  orderSingleReducer,
  createOrderReducer,
  updateOrderReducer,
  deleteOrderReducer,
  listIncompleteOrdersReducer,
  listApprovedOrdersReducer,
  listShippingOrderReducer,
  listDeliveriesOrdersReducer,
} from "reducers/orderReducers";
import {
  listClientsReducer,
  singleClientReducer,
  createClientReducer,
  updateClientReducer,
  deleteClientReducer,
} from "reducers/clientReducers";
import {
  listUsersReducer,
  singleUserReducer,
  createUserReducer,
  updateUserReducer,
  deleteUserReducer,
} from "reducers/userReducers";
import { listServicesReducer } from "reducers/serviceReducers";
import { listAddonsReducer, singleAddonsReducer } from "reducers/addonReducers";
import {
  listSuppliersReducer,
  singleSuppliersReducer,
} from "reducers/supplierReducers";
import {
  myListTransactionsReducer,
  statsTransactionsReducer,
  incompleteTransactionsReducer,
  orderTransactionReducer,
  singleTransactionReducer,
  createTransactionReducer,
  mpesaTransactionReducer,
  creditTransactionReducer,
  bankTransferTransactionReducer,
  chequeTransactionReducer,
  updateTransactionReducer,
  deleteTransactionReducer,
} from "reducers/transactionReducers";
import { paymentInfoReducer } from "reducers/infoReducers";
import {
  listProductsReducer,
  statsProductsReducer,
  searchProductsReducer,
  singleProductReducer,
  listProductsPerServiceReducer,
} from "reducers/productReducers";
import {
  listPostsReducer,
  searchPostsReducer,
  singlePostReducer,
} from "reducers/postReducers";
import {
  listProductRequirementsReducer,
  singleProductRequirementsReducer,
} from "reducers/productRequirementReducers";
import {
  listResponsePerClientRequirementsReducer,
  singleResponseRequirementsReducer,
  responseRequirementCreateReducer,
  responseRequirementUpdateReducer,
  responseRequirementDeleteReducer,
} from "reducers/responseRequirementReducers";
import {
  myListComplaintReducer,
  statsComplaintReducer,
  singleComplaintReducer,
  pendingComplaintReducer,
  postComplaintReducer,
  updateComplaintReducer,
  deleteComplaintReducer,
} from "reducers/complaintReducers";
import {
  listComplaintRequirementsReducer,
  singleComplaintRequirementsReducer,
} from "reducers/complaintRequirementReducers";
import {
  supplierStatsReducer,
  statsByAgeReducer,
  statsClientsJoinedPerMonthReducer,
  statsNewOrdersPerMonthReducer,
  proSupSerComTopPerformingReducer,
  overallSupplierStatsReducer,
} from "reducers/statsReducers";

const reducer = combineReducers({
  listAdmins: listAdminsReducer,
  singleAdmin: singleAdminReducer,
  createAdmin: createAdminReducer,
  updateAdmin: updateAdminReducer,
  listProducts: listProductsReducer,
  statsProducts: statsProductsReducer,
  searchProducts: searchProductsReducer,
  singleProduct: singleProductReducer,
  listPosts: listPostsReducer,
  searchPosts: searchPostsReducer,
  singlePost: singlePostReducer,
  listProductsPerService: listProductsPerServiceReducer,
  listProductRequirements: listProductRequirementsReducer,
  singleProductRequirements: singleProductRequirementsReducer,
  listClients: listClientsReducer,
  singleClient: singleClientReducer,
  createClient: createClientReducer,
  updateClient: updateClientReducer,
  deleteClient: deleteClientReducer,
  listUsers: listUsersReducer,
  singleUser: singleUserReducer,
  createUser: createUserReducer,
  updateUser: updateUserReducer,
  deleteUser: deleteUserReducer,
  listAddons: listAddonsReducer,
  singleAddons: singleAddonsReducer,
  listServices: listServicesReducer,
  listSuppliers: listSuppliersReducer,
  singleSuppliers: singleSuppliersReducer,
  myListTransactions: myListTransactionsReducer,
  statsTransactions: statsTransactionsReducer,
  incompleteTransactions: incompleteTransactionsReducer,
  orderTransaction: orderTransactionReducer,
  singleTransaction: singleTransactionReducer,
  createTransaction: createTransactionReducer,
  mpesaTransaction: mpesaTransactionReducer,
  creditTransaction: creditTransactionReducer,
  bankTransferTransaction: bankTransferTransactionReducer,
  chequeTransaction: chequeTransactionReducer,
  updateTransaction: updateTransactionReducer,
  deleteTransaction: deleteTransactionReducer,
  paymentInfo: paymentInfoReducer,
  myListOrders: myListOrdersReducer,
  statsOrders: statsOrdersReducer,
  orderSingle: orderSingleReducer,
  createOrder: createOrderReducer,
  updateOrder: updateOrderReducer,
  deleteOrder: deleteOrderReducer,
  listIncompleteOrders: listIncompleteOrdersReducer,
  listApprovedOrders: listApprovedOrdersReducer,
  listShippingOrder: listShippingOrderReducer,
  listDeliveriesOrders: listDeliveriesOrdersReducer,
  myListComplaint: myListComplaintReducer,
  statsComplaint: statsComplaintReducer,
  singleComplaint: singleComplaintReducer,
  pendingComplaints: pendingComplaintReducer,
  postComplaint: postComplaintReducer,
  updateComplaints: updateComplaintReducer,
  deleteComplaints: deleteComplaintReducer,
  listComplaintRequirements: listComplaintRequirementsReducer,
  singleComplaintRequirements: singleComplaintRequirementsReducer,
  listResponsePerClientRequirements: listResponsePerClientRequirementsReducer,
  singleResponseRequirements: singleResponseRequirementsReducer,
  responseRequirementCreate: responseRequirementCreateReducer,
  responseRequirementUpdate: responseRequirementUpdateReducer,
  responseRequirementDelete: responseRequirementDeleteReducer,
  supplierStats: supplierStatsReducer,
  statsByAge: statsByAgeReducer,
  statsClientsJoinedPerMonth: statsClientsJoinedPerMonthReducer,
  statsNewOrdersPerMonth: statsNewOrdersPerMonthReducer,
  proSupSerComTopPerforming: proSupSerComTopPerformingReducer,
  overallSupplierStats: overallSupplierStatsReducer,
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
