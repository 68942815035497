export default {
  values: {
    xxs: 320, // Extra extra small devices (phones, portrait)
    xs: 480, // Extra small devices (phones, landscape)
    sm: 768, // Small devices (tablets, portrait)
    md: 1024, // Medium devices (tablets, landscape)
    lg: 1280, // Large devices (desktops)
    xl: 1440, // Extra large devices (large desktops)
    xxl: 1920, // Extra extra large devices (very large desktops)
  },
};
