import { useState } from "react";
import { List } from "./List";
import "./styles.css";

export default function ArrangeImages() {
  const [selectedId, setSelectedId] = useState(null);

  return (
    <div className="container">
      <List selectedId={selectedId} setSelectedId={setSelectedId} />
    </div>
  );
}
