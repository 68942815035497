import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

// Material Kit 2 React components
import MKPageHeaderTemplate from "components/MKPageHeaderTemplate";

// Material Kit 2 React examples
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

import { getPostList } from "actions/postActions";
import moment from "moment";

// Images
import newsBg from "assets/images/news-bg.jpg";

function NewsAndEvents() {
  const dispatch = useDispatch();

  const listPosts = useSelector((state) => state.listPosts);
  const { postsList, loading } = listPosts;

  useEffect(() => {
    dispatch(getPostList());
  }, [dispatch]);

  return (
    <>
      <MKPageHeaderTemplate
        bgImage={newsBg}
        title="News And Events"
        subTitle="Get Up To Speed"
      >
        <Container sx={{ py: 5 }}>
          <Grid container spacing={3}>
            {loading
              ? Array.from(new Array(6)).map((_, index) => (
                  <Grid key={index} item xxs={12} xs={12} sm={6} lg={4}>
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={400}
                      sx={{
                        bgcolor: "rgba(255, 255, 255, 0.3)",
                        borderRadius: "10px",
                      }}
                    />
                    <Skeleton
                      width="60%"
                      sx={{ bgcolor: "rgba(255, 255, 255, 0.3)" }}
                    />
                    <Skeleton
                      width="80%"
                      sx={{ bgcolor: "rgba(255, 255, 255, 0.3)" }}
                    />
                  </Grid>
                ))
              : postsList?.map((post, index) => (
                  <Grid key={index} item xs={12} sm={6} lg={4}>
                    <Link to={post?.postLink} target="_blank">
                      <TransparentBlogCard
                        image={post?.postImages[0]}
                        title={post?.postTitle}
                        description={post?.content}
                        action={{
                          type: "external",
                          route: post?.postLink,
                          color: "info",
                          label: "more info",
                        }}
                      />
                    </Link>
                  </Grid>
                ))}
          </Grid>
        </Container>
      </MKPageHeaderTemplate>
    </>
  );
}

export default NewsAndEvents;
