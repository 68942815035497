import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function HorizontalTeamCard({
  _id,
  image,
  productImages,
  name,
  supplier,
  position,
  premiumAmount,
  description,
  includedItems,
  addons,
  adminNotes,
}) {
  const [selectedAddons, setSelectedAddons] = useState([]); // To track selected addons and their states
  const [totalPremium, setTotalPremium] = useState(premiumAmount); // Initialize total premium with base amount

  const token = localStorage.getItem("token");

  useEffect(() => {
    // Load selected addons from local storage on component mount
    const storedAddons =
      JSON.parse(localStorage.getItem("selectedAddons")) || [];
    setSelectedAddons(storedAddons);
  }, []);

  useEffect(() => {
    // Save selected addons to local storage whenever they change
    localStorage.setItem("selectedAddons", JSON.stringify(selectedAddons));
  }, [selectedAddons]);

  const handleChange = (event, addonData) => {
    // const addonName = event.target.name;
    // const isChecked = event.target.checked;

    // setSelectedAddons((prevSelectedAddons) => ({
    //   ...prevSelectedAddons,
    //   [addonName]: isChecked,
    // }));
    const addonName = addonData.addonName;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedAddons((prevSelectedAddons) => [
        ...prevSelectedAddons,
        addonData,
      ]);
    } else {
      setSelectedAddons((prevSelectedAddons) =>
        prevSelectedAddons.filter((addon) => addon.addonName !== addonName)
      );
    }

    const addonAmount =
      addons.find((addon) => addon.addonName === addonName)?.addonAmount || 0;
    setTotalPremium((prevTotal) =>
      isChecked ? prevTotal + addonAmount : prevTotal - addonAmount
    );
  };

  return (
    <Card sx={{ mt: 3, width: "100%" }}>
      <Accordion>
        <AccordionSummary
          sx={{ pr: 10, width: "100%" }}
          expandIcon={<ExpandMoreIcon fontSize="large" />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Grid container>
            <Grid item xs={12} md={6} lg={2} sx={{ mt: -6 }}>
              <MKBox width="100%" pt={2} pb={1} px={2}>
                <MKBox
                  component="img"
                  src={image}
                  alt={name}
                  width={100}
                  height={100}
                  borderRadius="md"
                  shadow="lg"
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ my: "auto" }}>
              <MKBox
                pt={{ xs: 1, lg: 2.5 }}
                pb={2.5}
                pr={4}
                pl={4}
                lineHeight={1}
              >
                <MKTypography
                  variant="h5"
                  sx={{
                    color: ({ palette: { text } }) => text.darkAccent,
                  }}
                >
                  {name}
                </MKTypography>
                <MKTypography variant="h6" color={position.color} mb={1}>
                  {supplier}
                </MKTypography>
                <MKTypography
                  variant="body2"
                  sx={{
                    color: ({ palette: { text } }) => text.darkAccent,
                  }}
                >
                  {description}
                </MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MKBox p={3} textAlign="center">
                <MKTypography
                  variant="h6"
                  mt={{ xs: 0, sm: 3 }}
                  sx={{
                    color: ({ palette: { text } }) => text.darkAccent,
                  }}
                >
                  Amount
                </MKTypography>
                <MKTypography variant="h2">
                  <CountUp
                    start={totalPremium - 100}
                    end={totalPremium}
                    duration={1}
                    separator=","
                    prefix="Ksh. "
                    className="countup-animation"
                    sx={{
                      color: ({ palette: { text } }) => text.darkAccent,
                    }}
                  />
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3} px={3}>
            <Grid item xs={12} md={6} lg={6} sx={{ p: 1 }}>
              <MKTypography
                variant="h6"
                sx={{
                  color: ({ palette: { text } }) => text.black,
                }}
              >
                What&apos;s included
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ p: 1 }}>
              <MKTypography
                variant="h6"
                sx={{
                  color: ({ palette: { text } }) => text.black,
                }}
              >
                Addons
              </MKTypography>
            </Grid>
          </Grid>
          <Grid container spacing={3} px={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MKBox width="100%" pt={2} pb={1} px={2}>
                <MKBox
                  component="img"
                  src={productImages[0]}
                  alt={name}
                  width={250}
                  height={250}
                  borderRadius="md"
                  shadow="lg"
                />
              </MKBox>
              {includedItems?.map((item, index) => (
                <MKBox key={index} display="flex" pr={1} lineHeight={0}>
                  <MKTypography
                    variant="body1"
                    sx={{
                      color: ({ palette: { text } }) => text.darkAccent,
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                  </MKTypography>
                  <MKTypography
                    variant="body2"
                    sx={{
                      color: ({ palette: { text } }) => text.darkAccent,
                    }}
                    fontWeight="regular"
                    pl={1}
                  >
                    {item}
                  </MKTypography>
                </MKBox>
              ))}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  {addons?.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Switch
                          checked={selectedAddons.some(
                            (addon) => addon.addonName === item.addonName
                          )}
                          onChange={(event) => handleChange(event, item)}
                          name={item.addonName}
                        />
                      }
                      label={
                        <Tooltip
                          title={item?.description}
                          arrow
                          placement="right"
                          disableInteractive
                        >
                          <MKBox display="flex">
                            <MKTypography
                              variant="body2"
                              sx={{
                                color: ({ palette: { text } }) =>
                                  text.darkAccent,
                              }}
                              fontWeight={
                                selectedAddons.some(
                                  (addon) => addon.addonName === item.addonName
                                )
                                  ? "bold"
                                  : "normal"
                              }
                            >
                              {item?.addonName}
                            </MKTypography>
                            <MKTypography
                              variant="body2"
                              sx={{
                                color: ({ palette: { text } }) =>
                                  text.darkAccent,
                              }}
                              fontWeight="medium"
                              pl={2}
                            >
                              {"( + Ksh."}
                              {item?.addonAmount}
                              {")"}
                            </MKTypography>
                          </MKBox>
                        </Tooltip>
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} px={3} py={3}>
            <Grid item xs={12} md={6} lg={6} sx={{ p: 1 }}>
              <MKTypography
                variant="h6"
                sx={{
                  color: ({ palette: { text } }) => text.black,
                }}
              >
                Admin's Note:{" "}
              </MKTypography>
              <MKTypography
                variant="body2"
                sx={{
                  color: ({ palette: { text } }) => text.darkAccent,
                }}
                fontWeight="regular"
              >
                {adminNotes}
              </MKTypography>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={6}>
            <MKTypography
              variant="body2"
              color="text"
              fontWeight="regular"
              pl={1}
            >
              {adminNotes}
            </MKTypography>
          </Grid> */}
          {token ? (
            // <Link to={`/order/${_id}`}>
            <Link to={"#"}>
              <MKBox display="flex" justifyContent="flex-end" px={10}>
                <MKButton
                  variant="gradient"
                  sx={{
                    color: ({ palette: { text } }) => text.darkAccent,
                  }}
                  size="large"
                  sx={{ m: 2 }}
                >
                  Order Cover (Under Maintenance)
                </MKButton>
              </MKBox>
            </Link>
          ) : (
            <Link to={`/auth/login`}>
              <MKBox display="flex" justifyContent="flex-end" px={10}>
                <MKButton
                  variant="gradient"
                  sx={{
                    color: ({ palette: { text } }) => text.darkAccent,
                  }}
                  size="large"
                  sx={{ m: 2 }}
                >
                  Log In to Order
                </MKButton>
              </MKBox>
            </Link>
          )}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}

// Typechecking props for the HorizontalTeamCard
HorizontalTeamCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
};

export default HorizontalTeamCard;
