import axios from "axios";
import {
  MY_COMPLAINT_LIST_REQUEST,
  MY_COMPLAINT_LIST_SUCCESS,
  MY_COMPLAINT_LIST_FAIL,
  COMPLAINT_STATS_REQUEST,
  COMPLAINT_STATS_SUCCESS,
  COMPLAINT_STATS_FAIL,
  COMPLAINT_SINGLE_REQUEST,
  COMPLAINT_SINGLE_SUCCESS,
  COMPLAINT_SINGLE_FAIL,
  COMPLAINT_PENDING_REQUEST,
  COMPLAINT_PENDING_SUCCESS,
  COMPLAINT_PENDING_FAIL,
  COMPLAINT_POST_REQUEST,
  COMPLAINT_POST_SUCCESS,
  COMPLAINT_POST_FAIL,
  COMPLAINT_UPDATE_REQUEST,
  COMPLAINT_UPDATE_SUCCESS,
  COMPLAINT_UPDATE_FAIL,
  COMPLAINT_DELETE_REQUEST,
  COMPLAINT_DELETE_SUCCESS,
  COMPLAINT_DELETE_FAIL,
} from "constants/complaintConstants";
import { REACT_APP_API_URL } from "constants/apiConstants";
import ComplaintsService from "services/complaints-service";

export const getMyComplaintsList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: MY_COMPLAINT_LIST_REQUEST,
    });

    const res = await ComplaintsService.getMyComplaints();
    console.log(
      "🚀 ~ file: complaintActions.js:26 ~ getMyComplaintsList ~ res:",
      res
    );

    dispatch({
      type: MY_COMPLAINT_LIST_SUCCESS,
      payload: res.foundComplaints,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: complaintActions.js:33 ~ getMyComplaintsList ~ error:",
      error
    );
    dispatch({
      type: MY_COMPLAINT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getComplaintSingle =
  (complaintId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COMPLAINT_SINGLE_REQUEST,
      });

      const res = await ComplaintsService.getComplaintsSingle(complaintId);
      console.log(
        "🚀 ~ file: complaintActions.js:63 ~ getComplaintSingle ~ res:",
        res
      );

      dispatch({
        type: COMPLAINT_SINGLE_SUCCESS,
        payload: res.foundComplaints,
      });
    } catch (error) {
      dispatch({
        type: COMPLAINT_SINGLE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error,
      });
    }
  };

export const getComplaintStats = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMPLAINT_STATS_REQUEST,
    });

    const res = await ComplaintsService.getComplaintsStats();
    console.log(
      "🚀 ~ file: complaintActions.js:86 ~ getComplaintStats ~ res:",
      res
    );

    dispatch({
      type: COMPLAINT_STATS_SUCCESS,
      payload: res.result,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: complaintActions.js:93 ~ getComplaintStats ~ error:",
      error
    );
    dispatch({
      type: COMPLAINT_STATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getComplaintsPending = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMPLAINT_PENDING_REQUEST,
    });

    const res = await ComplaintsService.getIncompleteComplaints();
    console.log(
      "🚀 ~ file: complaintActions.js:57 ~ getComplaintsPending ~ res:",
      res
    );

    dispatch({
      type: COMPLAINT_PENDING_SUCCESS,
      payload: res.foundComplaints,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: complaintActions.js:64 ~ getComplaintsPending ~ error:",
      error
    );
    dispatch({
      type: COMPLAINT_PENDING_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const complaintPosting = (complaint) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMPLAINT_POST_REQUEST,
    });

    const res = await ComplaintsService.createComplaint(complaint);
    console.log(
      "🚀 ~ file: complaintActions.js:143 ~ complaintPosting ~ res:",
      res
    );
    // dispatch(getComplaintsList());
    // dispatch(getComplaintStats());
    // dispatch(getComplaintsPending());

    dispatch({
      type: COMPLAINT_POST_SUCCESS,
      payload: res,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: complaintActions.js:150 ~ complaintPosting ~ error:",
      error
    );
    dispatch({
      type: COMPLAINT_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const complaintUpdating =
  (complaint, complaintId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COMPLAINT_UPDATE_REQUEST,
      });

      const res = await ComplaintsService.editComplaint(complaintId, complaint);
      console.log(
        "🚀 ~ file: complaintActions.js:168 ~ complaintUpdating ~ res:",
        res
      );
      dispatch(getComplaintsList());
      dispatch(getComplaintSingle(complaintId));
      dispatch(getComplaintStats());
      dispatch(getComplaintsPending());

      dispatch({
        type: COMPLAINT_UPDATE_SUCCESS,
        payload: res,
      });
    } catch (error) {
      console.log(
        "🚀 ~ file: complaintActions.js:175 ~ complaintUpdating ~ error:",
        error
      );
      dispatch({
        type: COMPLAINT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error,
      });
    }
  };

export const complaintDeleting =
  (complaintId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COMPLAINT_DELETE_REQUEST,
      });

      // const { adminLogin: { adminInfo } } = getState();

      // const config = {
      //   headers: {
      //     Authorization: adminInfo.token
      //   }
      // }

      const res = await axios.delete(
        `${REACT_APP_API_URL}/admin/complaint/${complaintId}`
      );

      dispatch({
        type: COMPLAINT_DELETE_SUCCESS,
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: COMPLAINT_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error,
      });
    }
  };
