// @mui material components
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import MKPageHeaderVideoTemplate from "components/MKPageHeaderVideoTemplate";
import MKBox from "components/MKBox";
// import MKSectionHeader from "components/MKSectionHeader";
import MKTypography from "components/MKTypography";

// Technology page sections
import Information from "pages/LandingPages/Technology/sections/Information";

// Videos
import videoTech from "assets/Fish-Project-1.mp4";

function Technology() {
  const theme = useTheme();
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.between("xxs", "xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXXLargeScreen = useMediaQuery(theme.breakpoints.between("xl", "xxl"));
  const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <>
      <MKPageHeaderVideoTemplate
        bgVideo={videoTech}
        title="Our Innovative Technology"
        subTitle="Pioneering aquaculture technology - Our proprietary organic bio-filtration technology"
      >
        <Grid
          container
          item
          xs={12}
          lg={12}
          flexDirection="column"
          alignItems="center"
          sx={{
            textAlign: "justify",
            my: 6,
            mx: "auto",
            width:
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "70%"
                : "90%",
          }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            <MKTypography
              component="span"
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "h5"
                  : "h6"
              }
              sx={{
                color: ({ palette: { text } }) => text.lightAccent,
              }}
              // opacity={0.8}
              fontWeight="bold"
            >
              Vertical Lake
            </MKTypography>{" "}
            is revolutionising sustainable protein production with the world's
            first truly vertical freshwater aquaculture lake system leveraging
            our scalable proprietary, organic and circular bio-filtration
            technology to produce organic protein and biopesticide fertiliser
            <MKTypography
              component="span"
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "h5"
                  : "h6"
              }
              sx={{
                color: ({ palette: { text } }) => text.lightAccent,
              }}
              fontWeight="bold"
            >
              {" "}
              (think hydroponics for fish and using the waste)
            </MKTypography>
          </MKTypography>
        </Grid>

        <MKBox
          width="100%"
          sx={{
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              width:
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "70%"
                  : "100%",
              height:
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "50vh"
                  : "auto",
              objectFit: "cover",
            }}
          >
            <source src={videoTech} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </MKBox>

        <Grid
          container
          item
          xs={12}
          lg={12}
          flexDirection="column"
          alignItems="center"
          sx={{
            textAlign: "justify",
            mx: "auto",
            width:
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "70%"
                : "90%",
            pt: 5,
          }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            We use a gravity-fed{" "}
            <MKTypography
              component="span"
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "h5"
                  : "h6"
              }
              sx={{
                color: ({ palette: { text } }) => text.lightAccent,
              }}
              fontWeight="bold"
            >
              ‘modular’
            </MKTypography>{" "}
            system, where we isolate our tanks to just being vertically
            connected (vertically stacked but not connected laterally). This
            vertical stacking technology means that our
            production-per-land-space-used produces x15 density of protein
            produced by that surface area with conventional land-based farming.
            The gravity-fed system also allows us to maintain optimum conditions
            for aquatic health.
          </MKTypography>
        </Grid>

        <Information />
      </MKPageHeaderVideoTemplate>
    </>
  );
}

export default Technology;
