import {
  PAYMENT_INFO_REQUEST,
  PAYMENT_INFO_SUCCESS,
  PAYMENT_INFO_FAIL,
  PAYMENT_INFO_RESET,
} from "constants/infoConstants";

export const paymentInfoReducer = (state = { info: {} }, action) => {
  switch (action.type) {
    case PAYMENT_INFO_REQUEST:
      return { loading: true, info: {} };
    case PAYMENT_INFO_SUCCESS:
      return { loading: false, info: action.payload };
    case PAYMENT_INFO_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_INFO_RESET:
      return {};
    default:
      return state;
  }
};
