export const SUPPLIER_STATS_REQUEST = "SUPPLIER_STATS_REQUEST";
export const SUPPLIER_STATS_SUCCESS = "SUPPLIER_STATS_SUCCESS";
export const SUPPLIER_STATS_FAIL = "SUPPLIER_STATS_FAIL";
export const SUPPLIER_STATS_RESET = "SUPPLIER_STATS_RESET";

export const STATS_BY_AGE_REQUEST = "STATS_BY_AGE_REQUEST";
export const STATS_BY_AGE_SUCCESS = "STATS_BY_AGE_SUCCESS";
export const STATS_BY_AGE_FAIL = "STATS_BY_AGE_FAIL";
export const STATS_BY_AGE_RESET = "STATS_BY_AGE_RESET";

export const STATS_CLIENTS_JOINED_PER_MONTH_REQUEST =
  "STATS_CLIENTS_JOINED_PER_MONTH_REQUEST";
export const STATS_CLIENTS_JOINED_PER_MONTH_SUCCESS =
  "STATS_CLIENTS_JOINED_PER_MONTH_SUCCESS";
export const STATS_CLIENTS_JOINED_PER_MONTH_FAIL =
  "STATS_CLIENTS_JOINED_PER_MONTH_FAIL";
export const STATS_CLIENTS_JOINED_PER_MONTH_RESET =
  "STATS_CLIENTS_JOINED_PER_MONTH_RESET";

export const STATS_NEW_ORDERS_PER_MONTH_REQUEST =
  "STATS_NEW_ORDERS_PER_MONTH_REQUEST";
export const STATS_NEW_ORDERS_PER_MONTH_SUCCESS =
  "STATS_NEW_ORDERS_PER_MONTH_SUCCESS";
export const STATS_NEW_ORDERS_PER_MONTH_FAIL =
  "STATS_NEW_ORDERS_PER_MONTH_FAIL";
export const STATS_NEW_ORDERS_PER_MONTH_RESET =
  "STATS_NEW_ORDERS_PER_MONTH_RESET";

export const TOP_PERFORMING_PRO_SUP_SER_COM_REQUEST =
  "TOP_PERFORMING_PRO_SUP_SER_COM_REQUEST";
export const TOP_PERFORMING_PRO_SUP_SER_COM_SUCCESS =
  "TOP_PERFORMING_PRO_SUP_SER_COM_SUCCESS";
export const TOP_PERFORMING_PRO_SUP_SER_COM_FAIL =
  "TOP_PERFORMING_PRO_SUP_SER_COM_FAIL";
export const TOP_PERFORMING_PRO_SUP_SER_COM_RESET =
  "TOP_PERFORMING_PRO_SUP_SER_COM_RESET";

export const OVERALL_SUPPLIER_STATS_REQUEST = "OVERALL_SUPPLIER_STATS_REQUEST";
export const OVERALL_SUPPLIER_STATS_SUCCESS = "OVERALL_SUPPLIER_STATS_SUCCESS";
export const OVERALL_SUPPLIER_STATS_FAIL = "OVERALL_SUPPLIER_STATS_FAIL";
export const OVERALL_SUPPLIER_STATS_RESET = "OVERALL_SUPPLIER_STATS_RESET";
