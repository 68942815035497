import axios from "axios";
import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_SINGLE_REQUEST,
  USER_SINGLE_SUCCESS,
  USER_SINGLE_FAIL,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
} from "constants/userConstants";
import { REACT_APP_API_URL } from "constants/apiConstants";
import AuthService from "services/auth-service";
import UsersService from "services/users-service";

export const getUserList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const res = await AuthService.getDashUsers();
    console.log("🚀 ~ file: userActions.js:23 ~ getUserList ~ res:", res);

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: res.foundUsers,
    });
  } catch (error) {
    console.log("🚀 ~ file: userActions.js:29 ~ getUserList ~ error:", error);
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getSingleUser = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_SINGLE_REQUEST,
    });

    const res = await UsersService.getSingleUser();
    console.log("🚀 ~ file: userActions.js:55 ~ getSingleUser ~ res:", res);

    dispatch({
      type: USER_SINGLE_SUCCESS,
      payload: res?.data?.attributes,
    });
  } catch (error) {
    dispatch({
      type: USER_SINGLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const createNewUser = (myData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATE_USER_REQUEST,
    });

    const res = await AuthService.register(myData);
    dispatch(getUserList());

    dispatch({
      type: CREATE_USER_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: CREATE_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_USER_REQUEST,
    });

    const res = await AuthService.updateSingleUser(user);
    console.log("🚀 ~ file: userActions.js:76 ~ updateUser ~ res:", res);
    dispatch(getSingleUser());
    // dispatch(getUserList());

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const deleteUser = (userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_USER_REQUEST,
    });

    const res = await axios.put(
      `${REACT_APP_API_URL}/admin/users/user/${userId}`
    );

    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};
