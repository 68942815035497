import {
  SUPPLIER_STATS_REQUEST,
  SUPPLIER_STATS_SUCCESS,
  SUPPLIER_STATS_FAIL,
  SUPPLIER_STATS_RESET,
  STATS_BY_AGE_REQUEST,
  STATS_BY_AGE_SUCCESS,
  STATS_BY_AGE_FAIL,
  STATS_BY_AGE_RESET,
  STATS_CLIENTS_JOINED_PER_MONTH_REQUEST,
  STATS_CLIENTS_JOINED_PER_MONTH_SUCCESS,
  STATS_CLIENTS_JOINED_PER_MONTH_FAIL,
  STATS_CLIENTS_JOINED_PER_MONTH_RESET,
  STATS_NEW_ORDERS_PER_MONTH_REQUEST,
  STATS_NEW_ORDERS_PER_MONTH_SUCCESS,
  STATS_NEW_ORDERS_PER_MONTH_FAIL,
  STATS_NEW_ORDERS_PER_MONTH_RESET,
  TOP_PERFORMING_PRO_SUP_SER_COM_REQUEST,
  TOP_PERFORMING_PRO_SUP_SER_COM_SUCCESS,
  TOP_PERFORMING_PRO_SUP_SER_COM_FAIL,
  TOP_PERFORMING_PRO_SUP_SER_COM_RESET,
  OVERALL_SUPPLIER_STATS_REQUEST,
  OVERALL_SUPPLIER_STATS_SUCCESS,
  OVERALL_SUPPLIER_STATS_FAIL,
  OVERALL_SUPPLIER_STATS_RESET,
} from "constants/statsConstants";

export const supplierStatsReducer = (state = { stats: [] }, action) => {
  switch (action.type) {
    case SUPPLIER_STATS_REQUEST:
      return { loading: true };
    case SUPPLIER_STATS_SUCCESS:
      return { loading: false, stats: action.payload };
    case SUPPLIER_STATS_FAIL:
      return { loading: false, error: action.payload };
    case SUPPLIER_STATS_RESET:
      return [];
    default:
      return state;
  }
};

export const statsByAgeReducer = (state = { ageStats: [] }, action) => {
  switch (action.type) {
    case STATS_BY_AGE_REQUEST:
      return { loading: true };
    case STATS_BY_AGE_SUCCESS:
      return { loading: false, ageStats: action.payload };
    case STATS_BY_AGE_FAIL:
      return { loading: false, error: action.payload };
    case STATS_BY_AGE_RESET:
      return [];
    default:
      return state;
  }
};

export const statsClientsJoinedPerMonthReducer = (
  state = { clientsJoinedPerMonth: [] },
  action
) => {
  switch (action.type) {
    case STATS_CLIENTS_JOINED_PER_MONTH_REQUEST:
      return { loading: true };
    case STATS_CLIENTS_JOINED_PER_MONTH_SUCCESS:
      return { loading: false, clientsJoinedPerMonth: action.payload };
    case STATS_CLIENTS_JOINED_PER_MONTH_FAIL:
      return { loading: false, error: action.payload };
    case STATS_CLIENTS_JOINED_PER_MONTH_RESET:
      return [];
    default:
      return state;
  }
};

export const statsNewOrdersPerMonthReducer = (
  state = { newOrdersPerMonth: [] },
  action
) => {
  switch (action.type) {
    case STATS_NEW_ORDERS_PER_MONTH_REQUEST:
      return { loading: true };
    case STATS_NEW_ORDERS_PER_MONTH_SUCCESS:
      return { loading: false, newOrdersPerMonth: action.payload };
    case STATS_NEW_ORDERS_PER_MONTH_FAIL:
      return { loading: false, error: action.payload };
    case STATS_NEW_ORDERS_PER_MONTH_RESET:
      return [];
    default:
      return state;
  }
};

export const proSupSerComTopPerformingReducer = (
  state = { topPerformingProSupSerCom: [] },
  action
) => {
  switch (action.type) {
    case TOP_PERFORMING_PRO_SUP_SER_COM_REQUEST:
      return { loading: true };
    case TOP_PERFORMING_PRO_SUP_SER_COM_SUCCESS:
      return { loading: false, topPerformingProSupSerCom: action.payload };
    case TOP_PERFORMING_PRO_SUP_SER_COM_FAIL:
      return { loading: false, error: action.payload };
    case TOP_PERFORMING_PRO_SUP_SER_COM_RESET:
      return [];
    default:
      return state;
  }
};

export const overallSupplierStatsReducer = (
  state = { supplierStatsOverall: {} },
  action
) => {
  switch (action.type) {
    case OVERALL_SUPPLIER_STATS_REQUEST:
      return { loading: true };
    case OVERALL_SUPPLIER_STATS_SUCCESS:
      return { loading: false, supplierStatsOverall: action.payload };
    case OVERALL_SUPPLIER_STATS_FAIL:
      return { loading: false, error: action.payload };
    case OVERALL_SUPPLIER_STATS_RESET:
      return {};
    default:
      return state;
  }
};
