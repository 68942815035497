import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// @mui material components
import Grid from "@mui/material/Grid";

import MKPageHeaderVideoTemplate from "components/MKPageHeaderVideoTemplate";
import MKSectionHeader from "components/MKSectionHeader";
import MKTypography from "components/MKTypography";

// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
import TeamAdvisors from "pages/LandingPages/AboutUs/sections/TeamAdvisors";

// Images
import aboutBgVideo from "assets/FishAbout.mp4";

function AboutUs() {
  const theme = useTheme();
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.between("xxs", "xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXXLargeScreen = useMediaQuery(theme.breakpoints.between("xl", "xxl"));
  const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <>
      <MKPageHeaderVideoTemplate
        bgVideo={aboutBgVideo}
        title="About Us"
        subTitle=""
      >
        <MKSectionHeader
          title="Our Purpose"
          badgeTitle="Nourishing the Future"
          subTitle=""
        />

        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", mt: 2, mx: "auto", px: 0.75 }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "h5"
                : "h6"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            …we exist to nourish the planet in a manner that is clean, safe,
            healthy, and sustainable for future generations
            <MKTypography
              component="span"
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "h5"
                  : "h6"
              }
              sx={{
                color: ({ palette: { text } }) => text.lightAccent,
              }}
              // opacity={0.8}
              fontWeight="bold"
            >
              {" "}
              ~ it’s that simple
            </MKTypography>
          </MKTypography>
        </Grid>

        <Information />

        <MKSectionHeader
          title="Who are we?"
          badgeTitle="Management Team"
          subTitle="Our co-founders and management team come from a diverse background that with many years of taking products to market successfully. Our co-founder Jack has one the Royal Academy of Engineering Africa Prize for innovation for our innovative technology. Our co-founder Jez, has over 30 years building, scaling and leading companies."
        />

        <Team />
        {/* Separate teams */}
        <MKSectionHeader title="" badgeTitle="Advisors" subTitle="" />

        <TeamAdvisors />

        <MKSectionHeader
          title="Where are we?"
          badgeTitle="Our Locations"
          subTitle=""
        />
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", mb: 5, mx: "auto", px: 0.75 }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            Our{" "}
            <MKTypography
              component="span"
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "h5"
                  : "h6"
              }
              sx={{
                color: ({ palette: { text } }) => text.lightAccent,
              }}
              // opacity={0.8}
              fontWeight="bold"
            >
              HQ
            </MKTypography>{" "}
            is in the{" "}
            <MKTypography
              component="span"
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "h5"
                  : "h6"
              }
              sx={{
                color: ({ palette: { text } }) => text.lightAccent,
              }}
              // opacity={0.8}
              fontWeight="bold"
            >
              UK
            </MKTypography>{" "}
            although our production will initially be{" "}
            <MKTypography
              component="span"
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "h5"
                  : "h6"
              }
              sx={{
                color: ({ palette: { text } }) => text.lightAccent,
              }}
              // opacity={0.8}
              fontWeight="bold"
            >
              Kenyan
            </MKTypography>{" "}
            based
          </MKTypography>
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              pt: 5,
              color: ({ palette: { text } }) => text.light,
            }}
          >
            The{" "}
            <MKTypography
              component="span"
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "h5"
                  : "h6"
              }
              sx={{
                color: ({ palette: { text } }) => text.lightAccent,
              }}
              // opacity={0.8}
              fontWeight="bold"
            >
              UK
            </MKTypography>{" "}
            allows us to collaborate with high-class R&D aquaculture
            institutions; a base to service the UK and Euro-zone super-premium
            protein markets as well as being home to a thriving entrepreneurial
            ecosystem that values impact-based innovation companies.
          </MKTypography>
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              pt: 5,
              color: ({ palette: { text } }) => text.light,
            }}
          >
            {" "}
            <MKTypography
              component="span"
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "h5"
                  : "h6"
              }
              sx={{
                color: ({ palette: { text } }) => text.lightAccent,
              }}
              // opacity={0.8}
              fontWeight="bold"
            >
              Kenya
            </MKTypography>{" "}
            allows us to service local and pan-Africa-wide customers. We will
            produce sustainably sourced, organic edible human lake fish
            (Tilapia, Catfish, Nyanza barbs) for the local market. We also
            produce dry protein derivatives for the{" "}
            <MKTypography
              component="span"
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "h5"
                  : "h6"
              }
              sx={{
                color: ({ palette: { text } }) => text.lightAccent,
              }}
              // opacity={0.8}
              fontWeight="bold"
            >
              pan-Africa and International super-premium ‘organic’
            </MKTypography>{" "}
            protein markets.
          </MKTypography>
        </Grid>

        <MKSectionHeader
          title="How to find us?"
          badgeTitle="Contact Us"
          subTitle="Interested in learning more about vertical aquaculture Innovations or exploring collaboration opportunities? We’re Here to Connect"
        />
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", mb: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            Email:
          </MKTypography>
          <Link
            to="#"
            onClick={(e) => {
              window.location.href = "mailto:info@vertical-lake.com";
              e.preventDefault();
            }}
          >
            <MKTypography
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "h5"
                  : "h6"
              }
              fontWeight="bold"
              sx={{
                py: 3,
                color: ({ palette: { text } }) => text.lightAccent,
              }}
            >
              info@vertical-lake.com
            </MKTypography>
          </Link>
        </Grid>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", mb: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "h5"
                : "h6"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            - the sky's the limit for sustainable, organic protein production
          </MKTypography>
        </Grid>
      </MKPageHeaderVideoTemplate>
    </>
  );
}

export default AboutUs;
