// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FooterAnimation from "examples/Footers/DefaultFooter/FooterAnimation";

// Presentation page components
import ArrangeImages from "pages/Presentation/components/ArrangeImages";
import ScrollIcons from "pages/Presentation/components/ScrollIcons";

// Routes
import { routes } from "routes";
import footerRoutes from "footer.routes";

// Images
import videoHero from "assets/Fish-Project-1.mp4";
import image from "assets/images/svgs/grid.svg";

function Presentation() {
  const theme = useTheme();
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.between("xxs", "xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXXLargeScreen = useMediaQuery(theme.breakpoints.between("xl", "xxl"));
  const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/auth/login",
          label: "Login",
          color: "info",
        }}
        transparent
        // sticky
        light
      />

      <MKBox
        minHeight={
          isXXSmallScreen
            ? "110vh"
            : isXSmallScreen
            ? "75vh"
            : isSmallScreen
            ? "70vh"
            : isMediumScreen
            ? "75vh"
            : isLargeScreen
            ? "80vh"
            : isXLargeScreen
            ? "85vh"
            : isXXLargeScreen
            ? "70vh"
            : "75vh"
        }
        width="100%"
        sx={{
          position: "relative",
          backgroundImage: "none",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          opacity: 0.3,
        }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: "100%",
            height: isXXSmallScreen
              ? "110vh"
              : isXSmallScreen
              ? "85vh"
              : isSmallScreen
              ? "70vh"
              : isMediumScreen
              ? "75vh"
              : isLargeScreen
              ? "80vh"
              : isXLargeScreen
              ? "85vh"
              : isXXLargeScreen
              ? "75vh"
              : "75vh",
            objectFit: "cover",
          }}
        >
          <source src={videoHero} type="video/mp4" />
          {/* Add additional source elements for other video formats if needed */}
          Your browser does not support the video tag.
        </video>
      </MKBox>

      <Container
        sx={{
          position: "absolute",
          zIndex: 2,
          top: isXXSmallScreen
            ? "50%"
            : isXSmallScreen
            ? "40%"
            : isSmallScreen
            ? "30%"
            : isMediumScreen
            ? "40%"
            : isLargeScreen
            ? "35%"
            : isXLargeScreen
            ? "35%"
            : isXXLargeScreen
            ? "35%"
            : "35%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Grid
          container
          item
          xs={12}
          lg={10}
          sx={{ pt: 15 }}
          justifyContent="center"
          textAlign="center"
          mx="auto"
        >
          <MKTypography
            variant={
              isXXSmallScreen
                ? "h6"
                : isXSmallScreen
                ? "h6"
                : isSmallScreen
                ? "h4"
                : isMediumScreen
                ? "h4"
                : isLargeScreen
                ? "h4"
                : isXLargeScreen
                ? "h3"
                : isXXLargeScreen
                ? "h3"
                : "h3"
            }
            textAlign="center"
            mb={isXXSmallScreen ? 2 : 5}
            sx={{
              color: ({ palette: { text } }) => text.white,
            }}
          >
            Endless production of premium protein from world's first land based,
            truly circular & sustainable vertical freshwater aquaculture lake
            system
          </MKTypography>
          <MKTypography
            variant={
              isXXSmallScreen
                ? "body2"
                : isXSmallScreen
                ? "body2"
                : isSmallScreen
                ? "h5"
                : isMediumScreen
                ? "h5"
                : isLargeScreen
                ? "h5"
                : isXLargeScreen
                ? "h4"
                : isXXLargeScreen
                ? "h4"
                : "h4"
            }
            textAlign="center"
            mt={1}
            mb={isXXSmallScreen ? 2 : 5}
            sx={{
              color: ({ palette: { text } }) => text.white,
            }}
          >
            We are revolutionizing sustainable aquaculture production by
            leveraging our unique, sustainable & organic bio-filtration
            technology to produce premium protein and biopesticide fertiliser
            for a variety of markets globally.
          </MKTypography>
          <MKTypography
            variant={
              isXXSmallScreen
                ? "body2"
                : isXSmallScreen
                ? "body2"
                : isSmallScreen
                ? "h5"
                : isMediumScreen
                ? "h5"
                : isLargeScreen
                ? "h5"
                : isXLargeScreen
                ? "h4"
                : isXXLargeScreen
                ? "h4"
                : "h4"
            }
            textAlign="center"
            mt={1}
            mb={5}
            sx={{
              color: ({ palette: { text } }) => text.white,
            }}
          >
            The sky's the limit for sustainable, organic protein.
          </MKTypography>
        </Grid>
      </Container>

      <Card
        sx={{
          p: 2,
          pb: 20,
          mx: { md: 2, lg: 3, xl: 3, xxl: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({
            palette: { backgroundCard },
            functions: { rgba },
          }) => rgba(backgroundCard.dark, 0.9),
          backdropFilter: "saturate(200%) blur(130px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: ({
            palette: { black, text },
            functions: { linearGradient, rgba },
          }) =>
            `${linearGradient(
              rgba(black.main, 0.1),
              rgba(black.main, 0.1)
            )}, url(${image})`,
          backgroundSize: "contain",
        }}
      >
        <ArrangeImages />
        <ScrollIcons />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <MKBox
        sx={{
          color: ({ palette: { text } }) => text.light,
        }}
      >
        <FooterAnimation />
      </MKBox>
    </>
  );
}

export default Presentation;
