// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// import route from "assets/images/illustrations/route.gif";

function DefaultInfoCard({
  color,
  icon,
  title,
  description,
  description2,
  description3,
  direction,
  small,
}) {
  const theme = useTheme();
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.between("xxs", "xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXXLargeScreen = useMediaQuery(theme.breakpoints.between("xl", "xxl"));
  const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <MKBox
      // lineHeight={1.3}
      p={direction === "center" ? 2 : 0}
      textAlign={direction}
      mt={-12}
    >
      {typeof icon === "string" ? (
        <MKTypography
          display="block"
          variant={
            !isXXSmallScreen &&
            !isXSmallScreen &&
            !isSmallScreen &&
            !isMediumScreen
              ? "h2"
              : "h3"
          }
          fontWeight="bold"
          sx={{
            color: ({ palette: { text } }) => text.lightAccent,
          }}
          // textGradient
        >
          {" "}
          <Icon>{icon}</Icon>{" "}
        </MKTypography>
      ) : (
        icon
      )}
      {/* <MKBox position="relative">
        <MKBox component="gif" src={route} alt="macbook" width="100%" />
      </MKBox> */}
      <MKTypography
        display="block"
        variant={
          !isXXSmallScreen &&
          !isXSmallScreen &&
          !isSmallScreen &&
          !isMediumScreen
            ? "h4"
            : "h5"
        }
        fontWeight="bold"
        mt={direction === "center" ? 1 : 2}
        mb={1.5}
        sx={{
          color: ({ palette: { text } }) => text.lightAccent,
        }}
      >
        {title}
      </MKTypography>
      <MKTypography
        display="block"
        // variant={small ? "button" : "body"}
        variant={
          !isXXSmallScreen &&
          !isXSmallScreen &&
          !isSmallScreen &&
          !isMediumScreen
            ? "body1"
            : "body2"
        }
        textAlign="justify"
        sx={{
          color: ({ palette: { text } }) => text.light,
        }}
        pr={direction === "left" ? 0 : 0}
        pl={direction === "right" ? 0 : 0}
      >
        {description}
      </MKTypography>
      <MKTypography
        display="block"
        // variant={small ? "button" : "body"}
        variant={
          !isXXSmallScreen &&
          !isXSmallScreen &&
          !isSmallScreen &&
          !isMediumScreen
            ? "body1"
            : "body2"
        }
        textAlign="justify"
        sx={{
          pt: 2,
          color: ({ palette: { text } }) => text.light,
        }}
        pr={direction === "left" ? 0 : 0}
        pl={direction === "right" ? 0 : 0}
      >
        {description2}
      </MKTypography>
      <MKTypography
        display="block"
        // variant={small ? "button" : "body"}
        variant={
          !isXXSmallScreen &&
          !isXSmallScreen &&
          !isSmallScreen &&
          !isMediumScreen
            ? "body1"
            : "body2"
        }
        textAlign="justify"
        sx={{
          pt: 2,
          color: ({ palette: { text } }) => text.light,
        }}
        pr={direction === "left" ? 0 : 0}
        pl={direction === "right" ? 0 : 0}
      >
        {description3}
      </MKTypography>
    </MKBox>
  );
}

// Setting default props for the DefaultInfoCard
DefaultInfoCard.defaultProps = {
  color: "info",
  direction: "left",
  small: false,
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  description2: PropTypes.string.isRequired,
  description3: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(["left", "right", "center"]),
  small: PropTypes.bool,
};

export default DefaultInfoCard;
