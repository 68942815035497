import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredTechCard from "examples/Cards/BlogCards/CenteredTechCard";

import tech1Image from "assets/images/Tech-001.png";
import tech2Image from "assets/images/Tech-002.png";
import tech3Image from "assets/images/Tech-003.jpeg";
import premiumImage from "assets/images/premium.jpeg";

function Information() {
  const theme = useTheme();
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.between("xxs", "xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXXLargeScreen = useMediaQuery(theme.breakpoints.between("xl", "xxl"));
  const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <MKBox py={2}>
      {!isXXSmallScreen &&
      !isXSmallScreen &&
      !isSmallScreen &&
      !isMediumScreen ? (
        <Container sx={{ my: 10 }}>
          <Grid container spacing={10} justifyContent="flex-start">
            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <MKBox mb={5} pt={5}>
                <DefaultInfoCard
                  icon={
                    !isXXSmallScreen &&
                    !isXSmallScreen &&
                    !isSmallScreen &&
                    !isMediumScreen &&
                    "sanitizer"
                  }
                  title=""
                  direction="left"
                  description="We employ a biological water purification process, which utilizes natural microbial communities to remove contaminants and promote and sustain water quality."
                  description2="This system not only optimises water use but also uses bio sedimentation to recycle waste which is fermented and converted into valuable bio-fertiliser, embodying our commitment to circular and sustainable aquaculture. Our technology ensures that our operations are chemical-free and zero carbon emissions."
                />
              </MKBox>
            </Grid>

            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <CenteredTechCard
                image={tech1Image}
                title="tech Join the Movement"
                description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                action={{
                  type: "internal",
                  route: "#",
                  color: "info",
                  label: "find out more",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Container sx={{ my: 10 }}>
          <Grid container spacing={10} justifyContent="flex-start">
            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <CenteredTechCard
                image={tech1Image}
                title="tech Join the Movement"
                description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                action={{
                  type: "internal",
                  route: "#",
                  color: "info",
                  label: "find out more",
                }}
              />
            </Grid>

            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <MKBox mb={5} pt={5}>
                <DefaultInfoCard
                  icon={
                    !isXXSmallScreen &&
                    !isXSmallScreen &&
                    !isSmallScreen &&
                    !isMediumScreen &&
                    "sanitizer"
                  }
                  title=""
                  direction="left"
                  description="We employ a biological water purification process, which utilizes natural microbial communities to remove contaminants and promote and sustain water quality."
                  description2="This system not only optimises water use but also uses bio sedimentation to recycle waste which is fermented and converted into valuable bio-fertiliser, embodying our commitment to circular and sustainable aquaculture. Our technology ensures that our operations are chemical-free and zero carbon emissions."
                />
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      )}
      <Container sx={{ my: 10 }}>
        <Grid container spacing={10}>
          <Grid item xxs={12} xs={12} sm={12} md={6}>
            <CenteredTechCard
              image={tech2Image}
              title="tech Join the Movement"
              description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
              action={{
                type: "internal",
                route: "#",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>
          <Grid item xxs={12} xs={12} sm={12} md={6}>
            <MKBox mb={{ xs: 5, md: 0 }} mt={7}>
              <DefaultInfoCard
                icon={
                  !isXXSmallScreen &&
                  !isXSmallScreen &&
                  !isSmallScreen &&
                  !isMediumScreen &&
                  "important_devices"
                }
                title=""
                direction="right"
                description="Fish health and wellbeing and their environment are important to us and that is why we deploy 24/7 monitoring. We use leading-edge technology to maintain the highest standards of aquatic health using video-assisted farming (VAF) and AI-enhanced monitoring systems including tracking fish, and their feeding patterns and monitoring the environment of every tank in every vertical tank module."
                description2=""
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>

      {!isXXSmallScreen &&
      !isXSmallScreen &&
      !isSmallScreen &&
      !isMediumScreen ? (
        <Container sx={{ my: 10 }}>
          <Grid container spacing={10} justifyContent="flex-start">
            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <MKBox mb={5} pt={5}>
                <DefaultInfoCard
                  icon={
                    !isXXSmallScreen &&
                    !isXSmallScreen &&
                    !isSmallScreen &&
                    !isMediumScreen &&
                    "health_and_safety"
                  }
                  title=""
                  direction="left"
                  description="Premium conditions for healthcare"
                  description2="The very controlled nature and attention to health and cleanliness of our modular systems means we produce the highest quality organic premium products."
                />
              </MKBox>
            </Grid>

            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <CenteredTechCard
                image={premiumImage}
                title="tech Join the Movement"
                description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                action={{
                  type: "internal",
                  route: "#",
                  color: "info",
                  label: "find out more",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Container sx={{ my: 10 }}>
          <Grid container spacing={10} justifyContent="flex-start">
            <Grid item xxs={14} xs={12} sm={12} md={6}>
              <CenteredTechCard
                image={premiumImage}
                title="tech Join the Movement"
                description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                action={{
                  type: "internal",
                  route: "#",
                  color: "info",
                  label: "find out more",
                }}
              />
            </Grid>

            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <MKBox mb={5} pt={5}>
                <DefaultInfoCard
                  icon={
                    !isXXSmallScreen &&
                    !isXSmallScreen &&
                    !isSmallScreen &&
                    !isMediumScreen &&
                    "health_and_safety"
                  }
                  title=""
                  direction="left"
                  description="Premium conditions for healthcare"
                  description2="The very controlled nature and attention to health and cleanliness of our modular systems means we produce the highest quality organic premium products."
                />
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      )}
    </MKBox>
  );
}

export default Information;
