// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import TeamSectionCard from "examples/Cards/TeamCards/TeamSectionCard";

// Images
import jack from "assets/images/jack-portrait.png";
import lofts from "assets/images/lofts-portrait.png";
import nick from "assets/images/nick-portrait.png";
import ian from "assets/images/Ian.png";
import winnie from "assets/images/winnie-portrait.png";
import pantea from "assets/images/pantea-portrait.png";
import brenda from "assets/images/brenda-portrait.png";
import emmanuel from "assets/images/emmanuel-portrait.png";

function Team() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container p={6}>
      <Grid container spacing={3} py={10}>
        <Grid item xs={12} lg={6}>
          <MKBox mb={{ xs: 1, lg: 0 }}>
            <TeamSectionCard
              image={brenda}
              name="Brenda, Kenya"
              position={{
                color: "light",
                label: "Facilities Technician (intern)",
              }}
              description="Background in Fisheries and Aquaculture Management, supporting sustainable aquaculture and blue economy innovation on various previous projects in Kenya."
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKBox mb={{ xs: 1, lg: 0 }}>
            <TeamSectionCard
              image={pantea}
              name="Dr. Pantea Loftian, UK"
              position={{
                color: "light",
                label: "Advisory food & Agri strategy",
              }}
              description="Expert in business model design, growth. internationalization, Technology adoption and policy Cambridge, England."
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKBox mb={{ xs: 1, lg: 0 }}>
            <TeamSectionCard
              image={emmanuel}
              name="Emmanuel Enyimere, Nigeria"
              position={{
                color: "light",
                label:
                  "Imafidon Ayemere, Nigeria  Advisory Sustainability Strategies",
              }}
              description="Expert in sustainability entrepreneur and small business development, building start-ups for the past 13 years."
            />
          </MKBox>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Team;
