export const ADMIN_LIST_REQUEST = "ADMIN_LIST_REQUEST";
export const ADMIN_LIST_SUCCESS = "ADMIN_LIST_SUCCESS";
export const ADMIN_LIST_FAIL = "ADMIN_LIST_FAIL";
export const ADMIN_LIST_RESET = "ADMIN_LIST_RESET";

export const SINGLE_ADMIN_REQUEST = "SINGLE_ADMIN_REQUEST";
export const SINGLE_ADMIN_SUCCESS = "SINGLE_ADMIN_SUCCESS";
export const SINGLE_ADMIN_FAIL = "SINGLE_ADMIN_FAIL";
export const SINGLE_ADMIN_RESET = "SINGLE_ADMIN_RESET";

export const CREATE_ADMIN_REQUEST = "CREATE_ADMIN_REQUEST";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_FAIL = "CREATE_ADMIN_FAIL";
export const CREATE_ADMIN_RESET = "CREATE_ADMIN_RESET";

export const UPDATE_ADMIN_REQUEST = "UPDATE_ADMIN_REQUEST";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAIL = "UPDATE_ADMIN_FAIL";
export const UPDATE_ADMIN_RESET = "UPDATE_ADMIN_RESET";

export const DELETE_ADMIN_REQUEST = "DELETE_ADMIN_REQUEST";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAIL = "DELETE_ADMIN_FAIL";
export const DELETE_ADMIN_RESET = "DELETE_ADMIN_RESET";
