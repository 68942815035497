import { forwardRef } from "react";

import MKTypography from "components/MKTypography";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MKInput
import MKInputRoot from "components/MKInput/MKInputRoot";

const MKInput = forwardRef(
  ({ error, success, disabled, label, ...rest }, ref) => (
    <>
      <MKTypography variant="h6" fontWeight="medium">
        {label}
      </MKTypography>
      <MKInputRoot
        {...rest}
        ref={ref}
        ownerState={{ error, success, disabled }}
      />
    </>
  )
);

// Setting default values for the props of MKInput
MKInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the MKInput
MKInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MKInput;
