import {
  MY_ORDER_LIST_REQUEST,
  MY_ORDER_LIST_SUCCESS,
  MY_ORDER_LIST_FAIL,
  MY_ORDER_LIST_RESET,
  ORDER_STATS_REQUEST,
  ORDER_STATS_SUCCESS,
  ORDER_STATS_FAIL,
  ORDER_STATS_RESET,
  ORDER_SINGLE_REQUEST,
  ORDER_SINGLE_SUCCESS,
  ORDER_SINGLE_FAIL,
  ORDER_SINGLE_RESET,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  CREATE_ORDER_RESET,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_RESET,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_RESET,
  INCOMPLETE_ORDER_LIST_REQUEST,
  INCOMPLETE_ORDER_LIST_SUCCESS,
  INCOMPLETE_ORDER_LIST_FAIL,
  INCOMPLETE_ORDER_LIST_RESET,
  APPROVED_ORDER_LIST_REQUEST,
  APPROVED_ORDER_LIST_SUCCESS,
  APPROVED_ORDER_LIST_FAIL,
  APPROVED_ORDER_LIST_RESET,
  SHIPPING_ORDER_LIST_REQUEST,
  SHIPPING_ORDER_LIST_SUCCESS,
  SHIPPING_ORDER_LIST_FAIL,
  SHIPPING_ORDER_LIST_RESET,
  DELIVERED_ORDER_LIST_REQUEST,
  DELIVERED_ORDER_LIST_SUCCESS,
  DELIVERED_ORDER_LIST_FAIL,
  DELIVERED_ORDER_LIST_RESET,
} from "constants/orderConstants";

export const myListOrdersReducer = (state = { myOrdersList: [] }, action) => {
  switch (action.type) {
    case MY_ORDER_LIST_REQUEST:
      return { loading: true };
    case MY_ORDER_LIST_SUCCESS:
      return { loading: false, myOrdersList: action.payload };
    case MY_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case MY_ORDER_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const statsOrdersReducer = (state = { ordersStats: [] }, action) => {
  switch (action.type) {
    case ORDER_STATS_REQUEST:
      return { loading: true };
    case ORDER_STATS_SUCCESS:
      return { loading: false, ordersStats: action.payload };
    case ORDER_STATS_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_STATS_RESET:
      return [];
    default:
      return state;
  }
};

export const orderSingleReducer = (state = { singleOrder: {} }, action) => {
  switch (action.type) {
    case ORDER_SINGLE_REQUEST:
      return { loading: true };
    case ORDER_SINGLE_SUCCESS:
      return { loading: false, singleOrder: action.payload, success: true };
    case ORDER_SINGLE_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_SINGLE_RESET:
      return { singleOrder: {} };
    default:
      return state;
  }
};

export const createOrderReducer = (state = { orderCreate: {} }, action) => {
  switch (action.type) {
    case CREATE_ORDER_REQUEST:
      return { loading: true, success: false, error: false };
    case CREATE_ORDER_SUCCESS:
      return {
        loading: false,
        orderCreate: action.payload,
        success: true,
        error: false,
      };
    case CREATE_ORDER_FAIL:
      return { loading: false, error: action.payload, success: false };
    case CREATE_ORDER_RESET:
      return { orderCreate: {} };
    default:
      return state;
  }
};

export const updateOrderReducer = (state = { orderUpdate: {} }, action) => {
  switch (action.type) {
    case UPDATE_ORDER_REQUEST:
      return { loading: true };
    case UPDATE_ORDER_SUCCESS:
      return { loading: false, orderUpdate: action.payload, success: true };
    case UPDATE_ORDER_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_ORDER_RESET:
      return { orderUpdate: {} };
    default:
      return state;
  }
};

export const deleteOrderReducer = (state = { orderDelete: {} }, action) => {
  switch (action.type) {
    case DELETE_ORDER_REQUEST:
      return { loading: true };
    case DELETE_ORDER_SUCCESS:
      return { loading: false, orderDelete: action.payload, success: true };
    case DELETE_ORDER_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_ORDER_RESET:
      return { orderDelete: {} };
    default:
      return state;
  }
};

export const listIncompleteOrdersReducer = (
  state = { incompleteOrdersList: [] },
  action
) => {
  switch (action.type) {
    case INCOMPLETE_ORDER_LIST_REQUEST:
      return { loading: true, incompleteOrdersList: [] };
    case INCOMPLETE_ORDER_LIST_SUCCESS:
      return { loading: false, incompleteOrdersList: action.payload };
    case INCOMPLETE_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case INCOMPLETE_ORDER_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const listApprovedOrdersReducer = (
  state = { approvedOrdersList: [] },
  action
) => {
  switch (action.type) {
    case APPROVED_ORDER_LIST_REQUEST:
      return { loading: true, approvedOrdersList: [] };
    case APPROVED_ORDER_LIST_SUCCESS:
      return { loading: false, approvedOrdersList: action.payload };
    case APPROVED_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case APPROVED_ORDER_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const listShippingOrderReducer = (
  state = { shippingOrderList: [] },
  action
) => {
  switch (action.type) {
    case SHIPPING_ORDER_LIST_REQUEST:
      return { loading: true, shippingOrderList: [] };
    case SHIPPING_ORDER_LIST_SUCCESS:
      return { loading: false, shippingOrderList: action.payload };
    case SHIPPING_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case SHIPPING_ORDER_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const listDeliveriesOrdersReducer = (
  state = { deliveriesOrdersList: [] },
  action
) => {
  switch (action.type) {
    case DELIVERED_ORDER_LIST_REQUEST:
      return { loading: true, deliveriesOrdersList: [] };
    case DELIVERED_ORDER_LIST_SUCCESS:
      return { loading: false, deliveriesOrdersList: action.payload };
    case DELIVERED_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case DELIVERED_ORDER_LIST_RESET:
      return [];
    default:
      return state;
  }
};
