import HttpService from "./htttp.service";

class UsersService {
  // UsersEndpoint = process.env.API_URL;

  getUsers = async () => {
    const getUsers = "fe/me";
    return await HttpService.get(getUsers);
  };

  getSingleUser = async (id) => {
    const getSingleUser = "fe/me";
    return await HttpService.get(getSingleUser);
  };

  createUsers = async (payload) => {
    const createUsers = "fe/me/new";
    return await HttpService.post(createUsers, payload);
  };

  updateSingleUser = async (payload) => {
    const updateSingleUser = "fe/me";
    return await HttpService.put(updateSingleUser, payload);
  };
}

export default new UsersService();
