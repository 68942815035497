import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import App from "./App";
import { AuthContextProvider } from "context";
import { Provider } from "react-redux";
import store from "./store";

// Vertical Lake Context Provider
import { MaterialUIControllerProvider } from "context";

ReactDOM.render(
  <BrowserRouter>
    <AuthContextProvider>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MaterialUIControllerProvider>
            <App />
          </MaterialUIControllerProvider>
        </LocalizationProvider>
      </Provider>
    </AuthContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
