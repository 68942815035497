import HttpService from "./htttp.service";

class AuthService {
  // authEndpoint = process.env.API_URL;

  login = async (payload) => {
    console.log(
      "🚀 ~ file: auth-service.js:7 ~ AuthService ~ login= ~ payload:",
      payload
    );
    const loginEndpoint = "fe/login";
    return await HttpService.post(loginEndpoint, payload);
  };

  register = async (credentials) => {
    const registerEndpoint = "fe/register";
    return await HttpService.post(registerEndpoint, credentials);
  };

  logout = async () => {
    const logoutEndpoint = "fe/logout";
    return await HttpService.post(logoutEndpoint);
  };

  forgotPassword = async (payload) => {
    const forgotPassword = "fe/password-forgot";
    return await HttpService.post(forgotPassword, payload);
  };

  resetPassword = async (credentials) => {
    const resetPassword = "fe/password-reset";
    return await HttpService.post(resetPassword, credentials);
  };

  getProfile = async () => {
    console.log("Profile Called!!");
    const getProfile = "fe/me";
    return await HttpService.get(getProfile);
  };

  getDashUsers = async () => {
    const getDashUsers = "fe/dash_users";
    return await HttpService.get(getDashUsers);
  };

  getSingleDashUser = async (id) => {
    const getSingleDashUser = `fe/dash_users/${id}`;
    return await HttpService.get(getSingleDashUser);
  };

  updateSingleDashUser = async (id, user) => {
    const updateSingleDashUser = `fe/dash_users/${id}`;
    return await HttpService.put(updateSingleDashUser, user);
  };

  updateSingleUser = async (payload) => {
    const updateSingleUser = "fe/me";
    return await HttpService.put(updateSingleUser, payload);
  };

  updateProfile = async (newInfo) => {
    const updateProfile = "fe/me";
    return await HttpService.patch(updateProfile, newInfo);
  };
}

export default new AuthService();
