import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKPageHeaderVideoTemplate from "components/MKPageHeaderVideoTemplate";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredTechCard from "examples/Cards/BlogCards/CenteredTechCard";

import premiumPieImage from "assets/images/premium-pie.png";
import premiumFishImage from "assets/images/premium-fish.jpg";
import premiumPowderImage from "assets/images/premium-powder.jpeg";

// Videos
import videoTracking from "assets/Fish-tracking.mp4";

function ProteinPremiumMarkets() {
  const theme = useTheme();
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.between("xxs", "xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXXLargeScreen = useMediaQuery(theme.breakpoints.between("xl", "xxl"));
  const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <>
      <MKPageHeaderVideoTemplate
        bgVideo={videoTracking}
        title="Protein for premium markets"
        subTitle="How are our products are nourishing people / industries around the world"
      >
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", mt: 6, mx: "auto", px: 0.75 }}
        >
          <MKBox mb={5} pt={5}>
            <DefaultInfoCard
              icon=""
              title="Delivering Organic Excellence Worldwide"
              direction="center"
              description="We offer and provide both fresh edible freshwater fish and dried organic fish products for both local and international markets. They meet the rigorous standards of the super-premium organic protein markets. "
              description2="Today, our production is Kenya-based. Our target markets are 2-fold."
            />
          </MKBox>
        </Grid>

        {!isXXSmallScreen &&
        !isXSmallScreen &&
        !isSmallScreen &&
        !isMediumScreen ? (
          <Container sx={{ my: 10 }}>
            <Grid container spacing={10} justifyContent="flex-start">
              <Grid item xxs={12} xs={12} sm={12} md={6}>
                <MKBox mb={5} pt={5}>
                  <DefaultInfoCard
                    icon=""
                    title=""
                    direction="left"
                    description="We produce sustainably sourced, organic, edible human fish (Tilapia, Catfish, Nyanza barbs) for the local market in Kenya. We are working with hotels and nutrition outlets that value the sustainable and organic impact of our products."
                    description2=""
                  />
                </MKBox>
              </Grid>

              <Grid item xxs={12} xs={12} sm={12} md={6}>
                <CenteredTechCard
                  image={premiumFishImage}
                  title=""
                  description=""
                  action={{
                    type: "internal",
                    route: "#",
                    color: "info",
                    label: "find out more",
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Container sx={{ my: 10 }}>
            <Grid container spacing={10} justifyContent="flex-start">
              <Grid item xxs={12} xs={12} sm={12} md={6}>
                <CenteredTechCard
                  image={premiumFishImage}
                  title=""
                  description=""
                  action={{
                    type: "internal",
                    route: "#",
                    color: "info",
                    label: "find out more",
                  }}
                />
              </Grid>

              <Grid item xxs={12} xs={12} sm={12} md={6}>
                <MKBox mb={5} pt={5}>
                  <DefaultInfoCard
                    icon=""
                    title=""
                    direction="left"
                    description="We produce sustainably sourced, organic, edible human fish (Tilapia, Catfish, Nyanza barbs) for the local market in Kenya. We are working with hotels and nutrition outlets that value the sustainable and organic impact of our products."
                    description2=""
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Container>
        )}

        <Container
          sx={{
            my:
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? 20
                : 0,
          }}
        >
          <Grid container spacing={10} justifyContent="flex-start">
            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <CenteredTechCard
                image={premiumPowderImage}
                title=""
                description=""
                action={{
                  type: "internal",
                  route: "#",
                  color: "info",
                  label: "find out more",
                }}
              />
            </Grid>

            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <MKBox mb={5} pt={5}>
                <DefaultInfoCard
                  icon=""
                  title=""
                  direction="right"
                  description="We produce dry protein derivatives for the pan-Africa and international super-premium ‘organic’ protein markets (cosmetics, nutrition/supplement vitamins and pharma/surgical grade medical devices)."
                  description2=""
                />
              </MKBox>
            </Grid>
          </Grid>
        </Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{
            textAlign: "justify",
            mt: 6,
            mx: "auto",
            px: 0.75,
          }}
        >
          <MKBox pt={5}>
            <DefaultInfoCard
              icon=""
              title="Premium conditions for healthcare-pharmaceuticals, cosmetics, nutrition supplements"
              direction="center"
              description=""
              description2=""
            />
          </MKBox>
        </Grid>

        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", mx: "auto", px: 0.75 }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            The very controlled nature and cleanliness of our modular systems
            mean we produce the highest quality premium products suitable for
            premium uses. Our fish oils, hydrolates and isolates can all be used
            for specialist pharmaceuticals, cosmetics, and nutritional
            supplements.
          </MKTypography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", my: 2, mx: "auto", px: 0.75 }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            We are also exploring premium healthcare products such as collagen
            for treating cuts and burns, highly absorbable collagen fibres and
            cartilage/bones for applications such as medical sutures. Contact us
            for more information.
          </MKTypography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          lg={12}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", my: 10, mx: "auto", px: 0.75 }}
        >
          <Card>
            <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
              <MKBox
                component="img"
                src={premiumPieImage}
                alt="premiumPiechart"
                borderRadius="lg"
                width="100%"
                height={
                  !isXXSmallScreen &&
                  !isXSmallScreen &&
                  !isSmallScreen &&
                  !isMediumScreen
                    ? "600px"
                    : "170px"
                }
                sx={{
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                position="relative"
                zIndex={1}
              />
              <MKBox
                borderRadius="lg"
                shadow="md"
                width="100%"
                height="100%"
                position="absolute"
                left={0}
                top={0}
                sx={{
                  backgroundImage: `url(${premiumPieImage})`,
                  transform: "scale(0.94)",
                  filter: "blur(12px)",
                  backgroundSize: "cover",
                }}
              />
            </MKBox>
          </Card>
        </Grid>
      </MKPageHeaderVideoTemplate>
    </>
  );
}

export default ProteinPremiumMarkets;
