import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_STATS_REQUEST,
  PRODUCT_STATS_SUCCESS,
  PRODUCT_STATS_FAIL,
  PRODUCT_SEARCH_REQUEST,
  PRODUCT_SEARCH_SUCCESS,
  PRODUCT_SEARCH_FAIL,
  PRODUCT_SINGLE_REQUEST,
  PRODUCT_SINGLE_SUCCESS,
  PRODUCT_SINGLE_FAIL,
  PRODUCT_PER_SERVICE_REQUEST,
  PRODUCT_PER_SERVICE_SUCCESS,
  PRODUCT_PER_SERVICE_FAIL,
} from "constants/productConstants";
import ProductsService from "services/products-service";

export const getProductList = () => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_LIST_REQUEST,
    });

    const res = await ProductsService.getProducts();
    console.log("🚀 ~ file: productActions.js:23 ~ getProductList ~ res:", res);

    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: res.foundProducts,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: productActions.js:30 ~ getProductList ~ error:",
      error
    );
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getProductStats = () => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_STATS_REQUEST,
    });

    const res = await ProductsService.getProductsStats();
    console.log(
      "🚀 ~ file: productActions.js:23 ~ getProductStats ~ res:",
      res
    );

    dispatch({
      type: PRODUCT_STATS_SUCCESS,
      payload: res.result,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: productActions.js:30 ~ getProductStats ~ error:",
      error
    );
    dispatch({
      type: PRODUCT_STATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getSearchProduct = (productSearchTerms) => async (dispatch) => {
  console.log(
    "🚀 ~ file: productActions.js:65 ~ getSearchProduct ~ productSearchTerms:",
    productSearchTerms
  );

  try {
    dispatch({
      type: PRODUCT_SEARCH_REQUEST,
    });

    const res = await ProductsService.getProductSearch(productSearchTerms);
    console.log(
      "🚀 ~ file: productActions.js:81 ~ getSearchProduct ~ res:",
      res
    );

    dispatch({
      type: PRODUCT_SEARCH_SUCCESS,
      payload: res.foundSearchProduct,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: productActions.js:87 ~ getSearchProduct ~ error:",
      error
    );
    dispatch({
      type: PRODUCT_SEARCH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getSingleProduct = (productId) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_SINGLE_REQUEST,
    });

    const res = await ProductsService.getProductSingle(productId);
    console.log(
      "🚀 ~ file: productActions.js:98 ~ getSingleProduct ~ res:",
      res
    );

    dispatch({
      type: PRODUCT_SINGLE_SUCCESS,
      payload: res.foundProduct,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: productActions.js:98 ~ getSingleProduct ~ error:",
      error
    );
    dispatch({
      type: PRODUCT_SINGLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getProductPerServiceList = (serviceId) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_PER_SERVICE_REQUEST,
    });

    const res = await ProductsService.getProductsPerService(serviceId);
    console.log(
      "🚀 ~ file: productActions.js:122 ~ getProductPerServiceList ~ res:",
      res
    );

    dispatch({
      type: PRODUCT_PER_SERVICE_SUCCESS,
      payload: res.foundServiceProducts,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: productActions.js:128 ~ getProductPerServiceList ~ error:",
      error
    );
    dispatch({
      type: PRODUCT_PER_SERVICE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};
