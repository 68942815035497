// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/svgs/bg.png";

function TeamSectionCard({ image, name, position, description }) {
  const theme = useTheme();
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.between("xxs", "xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXXLargeScreen = useMediaQuery(theme.breakpoints.between("xl", "xxl"));
  const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <Card
      sx={{
        my: 3,
        mb: isSmallScreen && 40,
        height:
          !isXXSmallScreen &&
          !isXSmallScreen &&
          !isSmallScreen &&
          !isMediumScreen &&
          !isLargeScreen
            ? 180
            : 400,
        backgroundColor: ({
          palette: { backgroundCard },
          functions: { rgba },
        }) => rgba(backgroundCard.dark, 0.9),
        backdropFilter: "saturate(200%) blur(130px)",
        boxShadow: ({ boxShadows: { xxl } }) => xxl,
        backgroundImage: ({
          palette: { black, text },
          functions: { linearGradient, rgba },
        }) =>
          `${linearGradient(
            rgba(black.main, 0.1),
            rgba(black.main, 0.1)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container>
        <Grid item xs={12} md={6} lg={4} sx={{ mt: -10 }}>
          <MKBox width="100%" pt={2} pb={1} px={2}>
            <MKBox
              component="img"
              src={image}
              alt={name}
              width={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen &&
                !isLargeScreen
                  ? "100%"
                  : "80%"
              }
              height={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen &&
                !isLargeScreen
                  ? "100%"
                  : "80%"
              }
              borderRadius="md"
              // shadow="lg"
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} md={6} lg={8} sx={{ my: "auto" }}>
          <MKBox
            pt={{ xs: 1, lg: 2.5 }}
            pb={2.5}
            pr={isSmallScreen ? 0 : 4}
            pl={{ xs: 1, lg: 1 }}
            lineHeight={1}
          >
            <MKTypography
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen &&
                !isLargeScreen
                  ? "h4"
                  : "h5"
              }
              color="text"
            >
              {name}
            </MKTypography>
            <MKTypography
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen &&
                !isLargeScreen
                  ? "h5"
                  : "h6"
              }
              color={position.color}
              mb={1}
            >
              {position.label}
            </MKTypography>
            <MKTypography variant="body2" color="text">
              {description}
            </MKTypography>
          </MKBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the TeamSectionCard
TeamSectionCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
};

export default TeamSectionCard;
