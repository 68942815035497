import HttpService from "./htttp.service";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

export const setupAxiosInterceptors = (onUnauthenticated) => {
  const navigate = useNavigate();
  const onRequestSuccess = async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  };
  const onRequestFail = (error) => Promise.reject(error);

  HttpService.addRequestInterceptor(onRequestSuccess, onRequestFail);

  const onResponseSuccess = (response) => response;

  const onResponseFail = (error) => {
    console.log(
      "🚀 ~ file: interceptor.js:16 ~ onResponseFail ~ error:",
      error
    );
    const status = error.status || error.response.status;
    if (status === 401) {
      onUnauthenticated();
    }
    if (status === 403) {
      // navigate(0);
      console.log("Forbidden Access!");
    }

    return Promise.reject(error);
  };
  HttpService.addResponseInterceptor(onResponseSuccess, onResponseFail);
};
