export const items = [
  {
    id: "a",
    category: "Problem",
    title: "The Problem and our Solution",
    subTitle: "Challenges faced by traditional protein production methods",
    pointOfInterest: 80,
    backgroundColor: "#814A0E",
    linkButton: "problem-solution",
  },
  {
    id: "b",
    category: "How to",
    title: "Sustainability and Circularity",
    subTitle: "A blueprint for ",
    subTitle2: "sustainable aquaculture ",
    pointOfInterest: 120,
    backgroundColor: "#959684",
    linkButton: "technology",
    linkButton: "sustainability-circularity",
  },
  {
    id: "c",
    category: "Pedal Power",
    title: "Our Innovative Technology",
    subTitle: "Pioneering vertical ",
    subTitle2: "aquaculture technology",
    pointOfInterest: 260,
    backgroundColor: "#5DBCD2",
    linkButton: "technology",
  },
  {
    id: "d",
    category: "Holidays",
    title: "Protein for premium markets",
    subTitle:
      "How are our products are nourishing people / industries around the world",
    pointOfInterest: 200,
    backgroundColor: "#8F986D",
    linkButton: "protein-premium-markets",
  },
];

export const openSpring = { type: "spring", stiffness: 200, damping: 30 };
