import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import InfiniteMovingCards from "./InfiniteCards";

const testimonials = [
  {
    id: 1,
    title: "95% water efficient / recycle / reuse",
    icon: "/home-1.png",
  },
  {
    id: 2,
    title: "ORGANIC",
    icon: "/home-2.png",
  },
  {
    id: 3,
    title: "PROTEIN-RICH IN OMEGA-3",
    icon: "/home-3.png",
  },
  {
    id: 4,
    title: "ZERO CARBON EMMISSION",
    icon: "/home-4.png",
  },
  {
    id: 5,
    title: "SPACE EFFICIENT - 15X More yield",
    icon: "/home-5.png",
  },
];

const companies = [
  { id: 1, name: "cloudinary", img: "/cloud.svg", nameImg: "/cloudName.svg" },
  { id: 2, name: "appwrite", img: "/app.svg", nameImg: "/appName.svg" },
  { id: 3, name: "HOSTINGER", img: "/host.svg", nameImg: "/hostName.svg" },
  { id: 4, name: "stream", img: "/s.svg", nameImg: "/streamName.svg" },
  { id: 5, name: "docker.", img: "/dock.svg", nameImg: "/dockerName.svg" },
];

const ScrollIcons = () => {
  return (
    <Box id="testimonials">
      <Grid container direction="column" alignItems="center">
        <Box
          sx={{
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <InfiniteMovingCards
            items={testimonials}
            direction="right"
            speed="slow"
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default ScrollIcons;
