import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Material Dashboard 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { getMyOrderList } from "actions/orderActions";
import DataTable from "examples/Tables/DataTable";
import MKBadge from "components/MKBadge";
import MKAvatar from "components/MKAvatar";
import moment from "moment";
import MKButton from "components/MKButton";

function OrderDetails({ title }) {
  const dispatch = useDispatch();

  const myListOrders = useSelector((state) => state.myListOrders);
  const { myOrdersList } = myListOrders;

  useEffect(() => {
    dispatch(getMyOrderList());
  }, [dispatch]);

  const Supplier = ({ image, name, email }) => (
    <MKBox display="flex" alignItems="center" lineHeight={1}>
      <MKAvatar src={image} name={name} size="sm" />
      <MKBox>
        <MKTypography
          variant="button"
          fontWeight="medium"
          ml={1}
          lineHeight={1}
          sx={{
            color: ({ palette: { text } }) => text.darkAccent,
          }}
        >
          {name}
        </MKTypography>
        <MKBox mt={1.5}>
          <MKTypography
            variant="button"
            fontSize="small"
            ml={1}
            lineHeight={1}
            sx={{
              color: ({ palette: { text } }) => text.darkAccent,
            }}
          >
            {email}
          </MKTypography>
        </MKBox>
      </MKBox>
    </MKBox>
  );

  function renderRows(data) {
    const values = [];
    data?.forEach((item, index) => {
      const newElement = {
        supplier: (
          <Supplier
            key={index}
            image={item?.supplier?.supplierLogo}
            name={item?.supplier?.supplierName}
            email={item?.supplier?.supplierEmail}
          />
        ),
        product: (
          <MKBox
            display="flex"
            flexDirection="column"
            alignItems="left"
            lineHeight={1}
          >
            <MKTypography
              variant="caption"
              sx={{
                color: ({ palette: { text } }) => text.darkAccent,
              }}
              fontWeight="medium"
            >
              {item?.product?.productName} - {item?.product?.productNumber}
            </MKTypography>
            <MKTypography
              variant="caption"
              sx={{
                color: ({ palette: { text } }) => text.darkAccent,
              }}
              fontWeight="medium"
            >
              {item?.service?.mainService} -{item?.service?.service}
            </MKTypography>
          </MKBox>
        ),
        amount: (
          <MKTypography
            variant="caption"
            sx={{
              color: ({ palette: { text } }) => text.darkAccent,
            }}
            fontWeight="medium"
          >
            Ksh. {item?.computedAmount}
          </MKTypography>
        ),
        date: (
          <MKTypography
            variant="caption"
            sx={{
              color: ({ palette: { text } }) => text.darkAccent,
            }}
            fontWeight="medium"
          >
            {moment(item?.created_at).format("DD MMM YYYY, HH mm")}
          </MKTypography>
        ),
        status: (
          <MKBox width="8rem" textAlign="center">
            <MKBox ml={-1}>
              <MKBadge
                badgeContent={item?.status}
                color="primary"
                variant="gradient"
                size="lg"
              />
            </MKBox>
          </MKBox>
        ),
        action: (
          <Link to={`/complaint/${item?._id}`}>
            <MKButton
              component="a"
              href="#"
              variant="outlined"
              sx={{
                color: ({ palette: { text } }) => text.darkAccent,
              }}
              fontWeight="medium"
            >
              Make Complaint
            </MKButton>
          </Link>
        ),
      };
      values.push(newElement);
    });
    return values;
  }

  return (
    <MKBox>
      <DataTable
        table={{
          columns: [
            {
              Header: "supplier",
              accessor: "supplier",
              width: "20%",
              align: "left",
            },
            {
              Header: "product",
              accessor: "product",
              width: "20%",
              align: "left",
            },

            {
              Header: "amount",
              accessor: "amount",
              width: "20%",
              align: "center",
            },

            {
              Header: "date",
              accessor: "date",
              width: "20%",
              align: "left",
            },
            {
              Header: "status",
              accessor: "status",
              width: "20%",
              align: "center",
            },
            {
              Header: "action",
              accessor: "action",
              width: "20%",
              align: "center",
            },
          ],

          rows: renderRows(myOrdersList),
        }}
        showTotalEntries={false}
        isSorted={false}
        noEndBorder
        entriesPerPage={false}
      />
    </MKBox>
  );
}

export default OrderDetails;
