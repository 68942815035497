import {
  MY_TRANSACTION_LIST_REQUEST,
  MY_TRANSACTION_LIST_SUCCESS,
  MY_TRANSACTION_LIST_FAIL,
  MY_TRANSACTION_LIST_RESET,
  TRANSACTION_STATS_REQUEST,
  TRANSACTION_STATS_SUCCESS,
  TRANSACTION_STATS_FAIL,
  TRANSACTION_STATS_RESET,
  TRANSACTION_INCOMPLETE_REQUEST,
  TRANSACTION_INCOMPLETE_SUCCESS,
  TRANSACTION_INCOMPLETE_FAIL,
  TRANSACTION_INCOMPLETE_RESET,
  ORDER_TRANSACTION_REQUEST,
  ORDER_TRANSACTION_SUCCESS,
  ORDER_TRANSACTION_FAIL,
  ORDER_TRANSACTION_RESET,
  TRANSACTION_SINGLE_REQUEST,
  TRANSACTION_SINGLE_SUCCESS,
  TRANSACTION_SINGLE_FAIL,
  TRANSACTION_SINGLE_RESET,
  CREATE_TRANSACTION_REQUEST,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_FAIL,
  CREATE_TRANSACTION_RESET,
  MPESA_TRANSACTION_REQUEST,
  MPESA_TRANSACTION_SUCCESS,
  MPESA_TRANSACTION_FAIL,
  MPESA_TRANSACTION_RESET,
  CREDIT_TRANSACTION_REQUEST,
  CREDIT_TRANSACTION_SUCCESS,
  CREDIT_TRANSACTION_FAIL,
  CREDIT_TRANSACTION_RESET,
  BANK_TRANSFER_TRANSACTION_REQUEST,
  BANK_TRANSFER_TRANSACTION_SUCCESS,
  BANK_TRANSFER_TRANSACTION_FAIL,
  BANK_TRANSFER_TRANSACTION_RESET,
  CHEQUE_TRANSACTION_REQUEST,
  CHEQUE_TRANSACTION_SUCCESS,
  CHEQUE_TRANSACTION_FAIL,
  CHEQUE_TRANSACTION_RESET,
  UPDATE_TRANSACTION_REQUEST,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAIL,
  UPDATE_TRANSACTION_RESET,
  DELETE_TRANSACTION_REQUEST,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAIL,
  DELETE_TRANSACTION_RESET,
} from "constants/transactionConstants";

export const myListTransactionsReducer = (
  state = { myTransactionsList: [] },
  action
) => {
  switch (action.type) {
    case MY_TRANSACTION_LIST_REQUEST:
      return { loading: true, myTransactionsList: [] };
    case MY_TRANSACTION_LIST_SUCCESS:
      return { loading: false, myTransactionsList: action.payload };
    case MY_TRANSACTION_LIST_FAIL:
      return { loading: false, error: action.payload };
    case MY_TRANSACTION_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const statsTransactionsReducer = (
  state = { transactionsStats: [] },
  action
) => {
  switch (action.type) {
    case TRANSACTION_STATS_REQUEST:
      return { loading: true };
    case TRANSACTION_STATS_SUCCESS:
      return { loading: false, transactionsStats: action.payload };
    case TRANSACTION_STATS_FAIL:
      return { loading: false, error: action.payload };
    case TRANSACTION_STATS_RESET:
      return [];
    default:
      return state;
  }
};

export const incompleteTransactionsReducer = (
  state = { transactionsIncomplete: [] },
  action
) => {
  switch (action.type) {
    case TRANSACTION_INCOMPLETE_REQUEST:
      return { loading: true };
    case TRANSACTION_INCOMPLETE_SUCCESS:
      return { loading: false, transactionsIncomplete: action.payload };
    case TRANSACTION_INCOMPLETE_FAIL:
      return { loading: false, error: action.payload };
    case TRANSACTION_INCOMPLETE_RESET:
      return [];
    default:
      return state;
  }
};

export const orderTransactionReducer = (
  state = { transactionOrder: [] },
  action
) => {
  switch (action.type) {
    case ORDER_TRANSACTION_REQUEST:
      return { loading: true };
    case ORDER_TRANSACTION_SUCCESS:
      return {
        loading: false,
        transactionOrder: action.payload,
        success: true,
      };
    case ORDER_TRANSACTION_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_TRANSACTION_RESET:
      return { transactionOrder: [] };
    default:
      return state;
  }
};

export const singleTransactionReducer = (
  state = { transactionSingle: [] },
  action
) => {
  switch (action.type) {
    case TRANSACTION_SINGLE_REQUEST:
      return { loading: true, transactionSingle: [] };
    case TRANSACTION_SINGLE_SUCCESS:
      return { loading: false, transactionSingle: action.payload };
    case TRANSACTION_SINGLE_FAIL:
      return { loading: false, error: action.payload };
    case TRANSACTION_SINGLE_RESET:
      return [];
    default:
      return state;
  }
};

export const createTransactionReducer = (
  state = { transactionCreate: {} },
  action
) => {
  switch (action.type) {
    case CREATE_TRANSACTION_REQUEST:
      return { loading: true, transactionCreate: {} };
    case CREATE_TRANSACTION_SUCCESS:
      return { loading: false, transactionCreate: action.payload };
    case CREATE_TRANSACTION_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_TRANSACTION_RESET:
      return {};
    default:
      return state;
  }
};

export const mpesaTransactionReducer = (
  state = { transactionMpesa: {} },
  action
) => {
  switch (action.type) {
    case MPESA_TRANSACTION_REQUEST:
      return { loading: true, transactionMpesa: {} };
    case MPESA_TRANSACTION_SUCCESS:
      return { loading: false, transactionMpesa: action.payload };
    case MPESA_TRANSACTION_FAIL:
      return { loading: false, error: action.payload };
    case MPESA_TRANSACTION_RESET:
      return {};
    default:
      return state;
  }
};

export const creditTransactionReducer = (
  state = { transactionCredit: {} },
  action
) => {
  switch (action.type) {
    case CREDIT_TRANSACTION_REQUEST:
      return { loading: true, transactionCredit: {} };
    case CREDIT_TRANSACTION_SUCCESS:
      return {
        loading: false,
        transactionCredit: action.payload,
        success: true,
      };
    case CREDIT_TRANSACTION_FAIL:
      return { loading: false, error: action.payload };
    case CREDIT_TRANSACTION_RESET:
      return {};
    default:
      return state;
  }
};

export const bankTransferTransactionReducer = (
  state = { transactionBankTransfer: {} },
  action
) => {
  switch (action.type) {
    case BANK_TRANSFER_TRANSACTION_REQUEST:
      return { loading: true, transactionBankTransfer: {} };
    case BANK_TRANSFER_TRANSACTION_SUCCESS:
      return {
        loading: false,
        transactionBankTransfer: action.payload,
        success: true,
      };
    case BANK_TRANSFER_TRANSACTION_FAIL:
      return { loading: false, error: action.payload };
    case BANK_TRANSFER_TRANSACTION_RESET:
      return {};
    default:
      return state;
  }
};

export const chequeTransactionReducer = (
  state = { transactionCheque: {} },
  action
) => {
  switch (action.type) {
    case CHEQUE_TRANSACTION_REQUEST:
      return { loading: true, transactionCheque: {} };
    case CHEQUE_TRANSACTION_SUCCESS:
      return {
        loading: false,
        transactionCheque: action.payload,
        success: true,
      };
    case CHEQUE_TRANSACTION_FAIL:
      return { loading: false, error: action.payload };
    case CHEQUE_TRANSACTION_RESET:
      return {};
    default:
      return state;
  }
};

export const updateTransactionReducer = (
  state = { transactionUpdate: [] },
  action
) => {
  switch (action.type) {
    case UPDATE_TRANSACTION_REQUEST:
      return { loading: true, transactionUpdate: [] };
    case UPDATE_TRANSACTION_SUCCESS:
      return { loading: false, transactionUpdate: action.payload };
    case UPDATE_TRANSACTION_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_TRANSACTION_RESET:
      return [];
    default:
      return state;
  }
};
export const deleteTransactionReducer = (
  state = { transactionDelete: [] },
  action
) => {
  switch (action.type) {
    case DELETE_TRANSACTION_REQUEST:
      return { loading: true, transactionDelete: [] };
    case DELETE_TRANSACTION_SUCCESS:
      return { loading: false, transactionDelete: action.payload };
    case DELETE_TRANSACTION_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_TRANSACTION_RESET:
      return [];
    default:
      return state;
  }
};
