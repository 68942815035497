export const RESPONSE_REQUIREMENT_PER_CLIENT_LIST_REQUEST =
  "RESPONSE_REQUIREMENT_PER_CLIENT_LIST_REQUEST";
export const RESPONSE_REQUIREMENT_PER_CLIENT_LIST_SUCCESS =
  "RESPONSE_REQUIREMENT_PER_CLIENT_LIST_SUCCESS";
export const RESPONSE_REQUIREMENT_PER_CLIENT_LIST_FAIL =
  "RESPONSE_REQUIREMENT_PER_CLIENT_LIST_FAIL";
export const RESPONSE_REQUIREMENT_PER_CLIENT_LIST_RESET =
  "RESPONSE_REQUIREMENT_PER_CLIENT_LIST_RESET";

export const RESPONSE_REQUIREMENT_SINGLE_REQUEST =
  "RESPONSE_REQUIREMENT_SINGLE_REQUEST";
export const RESPONSE_REQUIREMENT_SINGLE_SUCCESS =
  "RESPONSE_REQUIREMENT_SINGLE_SUCCESS";
export const RESPONSE_REQUIREMENT_SINGLE_FAIL =
  "RESPONSE_REQUIREMENT_SINGLE_FAIL";
export const RESPONSE_REQUIREMENT_SINGLE_RESET =
  "RESPONSE_REQUIREMENT_SINGLE_RESET";

export const CREATE_RESPONSE_REQUIREMENT_REQUEST =
  "CREATE_RESPONSE_REQUIREMENT_REQUEST";
export const CREATE_RESPONSE_REQUIREMENT_SUCCESS =
  "CREATE_RESPONSE_REQUIREMENT_SUCCESS";
export const CREATE_RESPONSE_REQUIREMENT_FAIL =
  "CREATE_RESPONSE_REQUIREMENT_FAIL";
export const CREATE_RESPONSE_REQUIREMENT_RESET =
  "CREATE_RESPONSE_REQUIREMENT_RESET";

export const UPDATE_RESPONSE_REQUIREMENT_REQUEST =
  "UPDATE_RESPONSE_REQUIREMENT_REQUEST";
export const UPDATE_RESPONSE_REQUIREMENT_SUCCESS =
  "UPDATE_RESPONSE_REQUIREMENT_SUCCESS";
export const UPDATE_RESPONSE_REQUIREMENT_FAIL =
  "UPDATE_RESPONSE_REQUIREMENT_FAIL";
export const UPDATE_RESPONSE_REQUIREMENT_RESET =
  "UPDATE_RESPONSE_REQUIREMENT_RESET";

export const DELETE_RESPONSE_REQUIREMENT_REQUEST =
  "DELETE_RESPONSE_REQUIREMENT_REQUEST";
export const DELETE_RESPONSE_REQUIREMENT_SUCCESS =
  "DELETE_RESPONSE_REQUIREMENT_SUCCESS";
export const DELETE_RESPONSE_REQUIREMENT_FAIL =
  "DELETE_RESPONSE_REQUIREMENT_FAIL";
export const DELETE_RESPONSE_REQUIREMENT_RESET =
  "DELETE_RESPONSE_REQUIREMENT_RESET";
