import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import MDSnackbar from "components/MDSnackbar";

function Notifications() {
  const [successSB, setSuccessSB] = useState(false);
  const [successTitle, setSuccessTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [infoSB, setInfoSB] = useState(false);
  const [infoTitle, setInfoTitle] = useState("");
  const [infoMessage, setInfoMessage] = useState("");

  const [warningSB, setWarningSB] = useState(false);
  const [warningTitle, setWarningTitle] = useState("");
  const [warningMessage, setWarningMessage] = useState("");

  const [errorSB, setErrorSB] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const openSuccessSB = (res) => {
    setSuccessTitle("Success!");
    setSuccessMessage(res?.message);
    setSuccessSB(true);
  };
  const closeSuccessSB = () => {
    setSuccessTitle("");
    setSuccessMessage("");
    setSuccessSB(false);
  };
  const openInfoSB = (res) => {
    setInfoTitle("Info!");
    setInfoMessage(res?.message);
    setInfoSB(true);
  };
  const closeInfoSB = () => {
    setInfoTitle("");
    setInfoMessage("");
    setInfoSB(false);
  };
  const openWarningSB = (res) => {
    setWarningTitle("Warning!");
    setWarningMessage(res?.message);
    setWarningSB(true);
  };
  const closeWarningSB = () => {
    setWarningTitle("");
    setWarningMessage("");
    setWarningSB(false);
  };
  const openErrorSB = (res) => {
    setErrorTitle(res?.error);
    setErrorMessage(res?.message);
    setErrorSB(true);
  };
  const closeErrorSB = () => {
    setErrorTitle("");
    setErrorMessage("");
    setErrorSB(false);
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={successTitle}
      content={successMessage}
      dateTime="Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title={infoTitle}
      content={infoMessage}
      dateTime="Now"
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title={warningTitle}
      content={warningMessage}
      dateTime="Now"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorMessage}
      content={errorTitle}
      dateTime="Now"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  //addons
  const listAddons = useSelector((state) => state.listAddons);
  const { error: listAddonsError } = listAddons;

  useEffect(() => {
    if (listAddonsError) {
      openErrorSB(listAddonsError);
    }
  }, [listAddonsError]);

  const singleAddons = useSelector((state) => state.singleAddons);
  const { error: singleAddonsError } = singleAddons;

  useEffect(() => {
    if (singleAddonsError) {
      openErrorSB(singleAddonsError);
    }
  }, [singleAddonsError]);

  // const addonCreate = useSelector((state) => state.addonCreate);
  // const { error: addonCreateError, newAddon: addonCreateSuccess } = addonCreate;

  // useEffect(() => {
  //   if (addonCreateError) {
  //     openErrorSB(addonCreateError);
  //   }
  // }, [addonCreateError]);

  // useEffect(() => {
  //   if (addonCreateSuccess?.success) {
  //     openSuccessSB(addonCreateSuccess);
  //   }
  // }, [addonCreateSuccess]);

  // const addonUpdate = useSelector((state) => state.addonUpdate);
  // const { error: addonUpdateError, updateAddon: addonUpdateSuccess } =
  //   addonUpdate;

  // useEffect(() => {
  //   if (addonUpdateError) {
  //     openErrorSB(addonUpdateError);
  //   }
  // }, [addonUpdateError]);

  // useEffect(() => {
  //   if (addonUpdateSuccess?.success) {
  //     openSuccessSB(addonUpdateSuccess);
  //   }
  // }, [addonUpdateSuccess]);

  // const addonDelete = useSelector((state) => state.addonDelete);
  // const { error: addonDeleteError, deleteAddon: addonDeleteSuccess } =
  //   addonDelete;

  // useEffect(() => {
  //   if (addonDeleteError) {
  //     openErrorSB(addonDeleteError);
  //   }
  // }, [addonDeleteError]);

  // useEffect(() => {
  //   if (addonDeleteSuccess?.success) {
  //     openSuccessSB(addonDeleteSuccess);
  //   }
  // }, [addonDeleteSuccess]);

  // Services
  const listServices = useSelector((state) => state.listServices);
  const { error: listServicesError } = listServices;

  useEffect(() => {
    if (listServicesError) {
      openErrorSB(listServicesError);
    }
  }, [listServicesError]);

  // const servicePost = useSelector((state) => state.servicePost);
  // const { error: servicePostError, service: servicePostSuccess } =
  //   servicePost;

  // useEffect(() => {
  //   if (servicePostError) {
  //     openErrorSB(servicePostError);
  //   }
  // }, [servicePostError]);

  // useEffect(() => {
  //   if (servicePostSuccess?.success) {
  //     openSuccessSB(servicePostSuccess);
  //   }
  // }, [servicePostSuccess]);

  // const updateService = useSelector((state) => state.updateService);
  // const { error: updateServiceError, serviceUpdate: updateServiceSuccess } =
  //   updateService;

  // useEffect(() => {
  //   if (updateServiceError) {
  //     openErrorSB(updateServiceError);
  //   }
  // }, [updateServiceError]);

  // useEffect(() => {
  //   if (updateServiceSuccess?.success) {
  //     openSuccessSB(updateServiceSuccess);
  //   }
  // }, [updateServiceSuccess]);

  // const deleteService = useSelector((state) => state.deleteService);
  // const { error: deleteServiceError, deleteService: deleteServiceSuccess } =
  //   deleteService;

  // useEffect(() => {
  //   if (deleteServiceError) {
  //     openErrorSB(deleteServiceError);
  //   }
  // }, [deleteServiceError]);

  // useEffect(() => {
  //   if (deleteServiceSuccess?.success) {
  //     openSuccessSB(deleteServiceSuccess);
  //   }
  // }, [deleteServiceSuccess]);

  // Complaints
  const myListComplaint = useSelector((state) => state.myListComplaint);
  const { error: myListComplaintError } = myListComplaint;

  useEffect(() => {
    if (myListComplaintError) {
      openErrorSB(myListComplaintError);
    }
  }, [myListComplaintError]);

  const statsComplaint = useSelector((state) => state.statsComplaint);
  const { error: statsComplaintError } = statsComplaint;

  useEffect(() => {
    if (statsComplaintError) {
      openErrorSB(statsComplaintError);
    }
  }, [statsComplaintError]);

  const singleComplaint = useSelector((state) => state.singleComplaint);
  const { error: singleComplaintError } = singleComplaint;

  useEffect(() => {
    if (singleComplaintError) {
      openErrorSB(singleComplaintError);
    }
  }, [singleComplaintError]);

  const pendingComplaints = useSelector((state) => state.pendingComplaints);
  const { error: pendingComplaintsError } = pendingComplaints;

  useEffect(() => {
    if (pendingComplaintsError) {
      openErrorSB(pendingComplaintsError);
    }
  }, [pendingComplaintsError]);

  const postComplaint = useSelector((state) => state.postComplaint);
  const { error: postComplaintError, complaintsPost: postComplaintSuccess } =
    postComplaint;

  useEffect(() => {
    if (postComplaintError) {
      openErrorSB(postComplaintError);
    }
  }, [postComplaintError]);

  useEffect(() => {
    if (postComplaintSuccess?.success) {
      openSuccessSB(postComplaintSuccess);
    }
  }, [postComplaintSuccess]);

  const updateComplaints = useSelector((state) => state.updateComplaints);
  const {
    error: updateComplaintsError,
    complaintsUpdate: updateComplaintsSuccess,
  } = updateComplaints;

  useEffect(() => {
    if (updateComplaintsError) {
      openErrorSB(updateComplaintsError);
    }
  }, [updateComplaintsError]);

  useEffect(() => {
    if (updateComplaintsSuccess?.success) {
      openSuccessSB(updateComplaintsSuccess);
    }
  }, [updateComplaintsSuccess]);

  const deleteComplaints = useSelector((state) => state.deleteComplaints);
  const {
    error: deleteComplaintsError,
    complaintsDelete: deleteComplaintsSuccess,
  } = deleteComplaints;

  useEffect(() => {
    if (deleteComplaintsError) {
      openErrorSB(deleteComplaintsError);
    }
  }, [deleteComplaintsError]);

  useEffect(() => {
    if (deleteComplaintsSuccess?.success) {
      openSuccessSB(deleteComplaintsSuccess);
    }
  }, [deleteComplaintsSuccess]);

  // Complaints Requirements
  const listComplaintRequirements = useSelector(
    (state) => state.listComplaintRequirements
  );
  const { error: listComplaintRequirementsError } = listComplaintRequirements;

  useEffect(() => {
    if (listComplaintRequirementsError) {
      openErrorSB(listComplaintRequirementsError);
    }
  }, [listComplaintRequirementsError]);

  const singleComplaintRequirements = useSelector(
    (state) => state.singleComplaintRequirements
  );
  const { error: singleComplaintRequirementsError } =
    singleComplaintRequirements;

  useEffect(() => {
    if (singleComplaintRequirementsError) {
      openErrorSB(singleComplaintRequirementsError);
    }
  }, [singleComplaintRequirementsError]);

  // const complaintRequirementCreate = useSelector(
  //   (state) => state.complaintRequirementCreate
  // );
  // const {
  //   error: complaintRequirementCreateError,
  //   newComplaintRequirement: complaintRequirementCreateSuccess,
  // } = complaintRequirementCreate;

  // useEffect(() => {
  //   if (complaintRequirementCreateError) {
  //     openErrorSB(complaintRequirementCreateError);
  //   }
  // }, [complaintRequirementCreateError]);

  // useEffect(() => {
  //   if (complaintRequirementCreateSuccess?.success) {
  //     openSuccessSB(complaintRequirementCreateSuccess);
  //   }
  // }, [complaintRequirementCreateSuccess]);

  // const complaintRequirementUpdate = useSelector(
  //   (state) => state.complaintRequirementUpdate
  // );
  // const {
  //   error: complaintRequirementUpdateError,
  //   updateComplaintRequirement: complaintRequirementUpdateSuccess,
  // } = complaintRequirementUpdate;

  // useEffect(() => {
  //   if (complaintRequirementUpdateError) {
  //     openErrorSB(complaintRequirementUpdateError);
  //   }
  // }, [complaintRequirementUpdateError]);

  // useEffect(() => {
  //   if (complaintRequirementUpdateSuccess?.success) {
  //     openSuccessSB(complaintRequirementUpdateSuccess);
  //   }
  // }, [complaintRequirementUpdateSuccess]);

  // const complaintRequirementDelete = useSelector(
  //   (state) => state.complaintRequirementDelete
  // );
  // const {
  //   error: complaintRequirementDeleteError,
  //   deleteComplaintRequirement: complaintRequirementDeleteSuccess,
  // } = complaintRequirementDelete;

  // useEffect(() => {
  //   if (complaintRequirementDeleteError) {
  //     openErrorSB(complaintRequirementDeleteError);
  //   }
  // }, [complaintRequirementDeleteError]);

  // useEffect(() => {
  //   if (complaintRequirementDeleteSuccess?.success) {
  //     openSuccessSB(complaintRequirementDeleteSuccess);
  //   }
  // }, [complaintRequirementDeleteSuccess]);

  // Clients
  const listClients = useSelector((state) => state.listClients);
  const { error: listClientsError } = listClients;

  useEffect(() => {
    if (listClientsError) {
      openErrorSB(listClientsError);
    }
  }, [listClientsError]);

  const singleClient = useSelector((state) => state.singleClient);
  const { error: singleClientError } = singleClient;

  useEffect(() => {
    if (singleClientError) {
      openErrorSB(singleClientError);
    }
  }, [singleClientError]);

  const createClient = useSelector((state) => state.createClient);
  const { error: createClientError, clientCreate: createClientSuccess } =
    createClient;

  useEffect(() => {
    if (createClientError) {
      openErrorSB(createClientError);
    }
  }, [createClientError]);

  useEffect(() => {
    if (createClientSuccess?.success) {
      openSuccessSB(createClientSuccess);
    }
  }, [createClientSuccess]);

  const updateClient = useSelector((state) => state.updateClient);
  const { error: updateClientError, clientUpdate: updateClientSuccess } =
    updateClient;

  useEffect(() => {
    if (updateClientError) {
      openErrorSB(updateClientError);
    }
  }, [updateClientError]);

  useEffect(() => {
    if (updateClientSuccess?.success) {
      openSuccessSB(updateClientSuccess);
    }
  }, [updateClientSuccess]);

  const deleteClient = useSelector((state) => state.deleteClient);
  const { error: deleteClientError, clientDelete: deleteClientSuccess } =
    deleteClient;

  useEffect(() => {
    if (deleteClientError) {
      openErrorSB(deleteClientError);
    }
  }, [deleteClientError]);

  useEffect(() => {
    if (deleteClientSuccess?.success) {
      openSuccessSB(deleteClientSuccess);
    }
  }, [deleteClientSuccess]);

  // Supplier
  const listSuppliers = useSelector((state) => state.listSuppliers);
  const { error: listSuppliersError } = listSuppliers;

  useEffect(() => {
    if (listSuppliersError) {
      openErrorSB(listSuppliersError);
    }
  }, [listSuppliersError]);

  const singleSuppliers = useSelector((state) => state.singleSuppliers);
  const { error: singleSuppliersError } = singleSuppliers;

  useEffect(() => {
    if (singleSuppliersError) {
      openErrorSB(singleSuppliersError);
    }
  }, [singleSuppliersError]);

  // const suppliersPost = useSelector((state) => state.suppliersPost);
  // const { error: suppliersPostError, suppliers: suppliersPostSuccess } =
  //   suppliersPost;

  // useEffect(() => {
  //   if (suppliersPostError) {
  //     openErrorSB(suppliersPostError);
  //   }
  // }, [suppliersPostError]);

  // useEffect(() => {
  //   if (suppliersPostSuccess?.success) {
  //     openSuccessSB(suppliersPostSuccess);
  //   }
  // }, [suppliersPostSuccess]);

  // const updateSuppliers = useSelector((state) => state.updateSuppliers);
  // const {
  //   error: updateSuppliersError,
  //   suppliersUpdate: updateSuppliersSuccess,
  // } = updateSuppliers;

  // useEffect(() => {
  //   if (updateSuppliersError) {
  //     openErrorSB(updateSuppliersError);
  //   }
  // }, [updateSuppliersError]);

  // useEffect(() => {
  //   if (updateSuppliersSuccess?.success) {
  //     openSuccessSB(updateSuppliersSuccess);
  //   }
  // }, [updateSuppliersSuccess]);

  // const deleteSuppliers = useSelector((state) => state.deleteSuppliers);
  // const {
  //   error: deleteSuppliersError,
  //   deleteSuppliers: deleteSuppliersSuccess,
  // } = deleteSuppliers;

  // useEffect(() => {
  //   if (deleteSuppliersError) {
  //     openErrorSB(deleteSuppliersError);
  //   }
  // }, [deleteSuppliersError]);

  // useEffect(() => {
  //   if (deleteSuppliersSuccess?.success) {
  //     openSuccessSB(deleteSuppliersSuccess);
  //   }
  // }, [deleteSuppliersSuccess]);

  // Info
  const paymentInfo = useSelector((state) => state.paymentInfo);
  const { error: paymentInfoError } = paymentInfo;

  useEffect(() => {
    if (paymentInfoError) {
      openErrorSB(paymentInfoError);
    }
  }, [paymentInfoError]);

  // const updatePaymentInfo = useSelector((state) => state.updatePaymentInfo);
  // const {
  //   error: updatePaymentInfoError,
  //   infoUpdate: updatePaymentInfoSuccess,
  // } = updatePaymentInfo;

  // useEffect(() => {
  //   if (updatePaymentInfoError) {
  //     openErrorSB(updatePaymentInfoError);
  //   }
  // }, [updatePaymentInfoError]);

  // useEffect(() => {
  //   if (updatePaymentInfoSuccess?.success) {
  //     openSuccessSB(updatePaymentInfoSuccess);
  //   }
  // }, [updatePaymentInfoSuccess]);

  // Orders
  const myListOrders = useSelector((state) => state.myListOrders);
  const { error: myListOrdersError } = myListOrders;

  useEffect(() => {
    if (myListOrdersError) {
      openErrorSB(myListOrdersError);
    }
  }, [myListOrdersError]);

  const statsOrders = useSelector((state) => state.statsOrders);
  const { error: statsOrdersError } = statsOrders;

  useEffect(() => {
    if (statsOrdersError) {
      openErrorSB(statsOrdersError);
    }
  }, [statsOrdersError]);

  const orderSingle = useSelector((state) => state.orderSingle);
  const { error: orderSingleError } = orderSingle;

  useEffect(() => {
    if (orderSingleError) {
      openErrorSB(orderSingleError);
    }
  }, [orderSingleError]);

  const createOrder = useSelector((state) => state.createOrder);
  const { error: createOrderError, orderCreate: createOrderSuccess } =
    createOrder;

  useEffect(() => {
    if (createOrderError) {
      openErrorSB(createOrderError);
    }
  }, [createOrderError]);

  useEffect(() => {
    if (createOrderSuccess?.success) {
      openSuccessSB(createOrderSuccess);
    }
  }, [createOrderSuccess]);

  const updateOrder = useSelector((state) => state.updateOrder);
  const { error: updateOrderError, orderUpdate: updateOrderSuccess } =
    updateOrder;

  useEffect(() => {
    if (updateOrderError) {
      openErrorSB(updateOrderError);
    }
  }, [updateOrderError]);

  useEffect(() => {
    if (updateOrderSuccess?.success) {
      openSuccessSB(updateOrderSuccess);
    }
  }, [updateOrderSuccess]);

  const deleteOrder = useSelector((state) => state.deleteOrder);
  const { error: deleteOrderError, orderDelete: deleteOrderSuccess } =
    deleteOrder;

  useEffect(() => {
    if (deleteOrderError) {
      openErrorSB(deleteOrderError);
    }
  }, [deleteOrderError]);

  useEffect(() => {
    if (deleteOrderSuccess?.success) {
      openSuccessSB(deleteOrderSuccess);
    }
  }, [deleteOrderSuccess]);

  const listIncompleteOrders = useSelector(
    (state) => state.listIncompleteOrders
  );
  const { error: listIncompleteOrdersError } = listIncompleteOrders;

  useEffect(() => {
    if (listIncompleteOrdersError) {
      openErrorSB(listIncompleteOrdersError);
    }
  }, [listIncompleteOrdersError]);

  const listApprovedOrders = useSelector((state) => state.listApprovedOrders);
  const { error: listApprovedOrdersError } = listApprovedOrders;

  useEffect(() => {
    if (listApprovedOrdersError) {
      openErrorSB(listApprovedOrdersError);
    }
  }, [listApprovedOrdersError]);

  const listShippingOrder = useSelector((state) => state.listShippingOrder);
  const { error: listShippingOrderError } = listShippingOrder;

  useEffect(() => {
    if (listShippingOrderError) {
      openErrorSB(listShippingOrderError);
    }
  }, [listShippingOrderError]);

  const listDeliveriesOrders = useSelector(
    (state) => state.listDeliveriesOrders
  );
  const { error: listDeliveriesOrdersError } = listDeliveriesOrders;

  useEffect(() => {
    if (listDeliveriesOrdersError) {
      openErrorSB(listDeliveriesOrdersError);
    }
  }, [listDeliveriesOrdersError]);

  // Products
  const listProducts = useSelector((state) => state.listProducts);
  const { error: listProductsError } = listProducts;

  useEffect(() => {
    if (listProductsError) {
      openErrorSB(listProductsError);
    }
  }, [listProductsError]);

  const statsProducts = useSelector((state) => state.statsProducts);
  const { error: statsProductsError } = statsProducts;

  useEffect(() => {
    if (statsProductsError) {
      openErrorSB(statsProductsError);
    }
  }, [statsProductsError]);

  const searchProducts = useSelector((state) => state.searchProducts);
  const { error: searchProductsError } = searchProducts;

  useEffect(() => {
    if (searchProductsError) {
      openErrorSB(searchProductsError);
    }
  }, [searchProductsError]);

  const singleProduct = useSelector((state) => state.singleProduct);
  const { error: singleProductError } = singleProduct;

  useEffect(() => {
    if (singleProductError) {
      openErrorSB(singleProductError);
    }
  }, [singleProductError]);

  const listProductsPerService = useSelector(
    (state) => state.listProductsPerService
  );
  const { error: listProductsPerServiceError } = listProductsPerService;

  useEffect(() => {
    if (listProductsPerServiceError) {
      openErrorSB(listProductsPerServiceError);
    }
  }, [listProductsPerServiceError]);

  // const createProduct = useSelector((state) => state.createProduct);
  // const { error: createProductError, productCreate: createProductSuccess } =
  //   createProduct;

  // useEffect(() => {
  //   if (createProductError) {
  //     openErrorSB(createProductError);
  //   }
  // }, [createProductError]);

  // useEffect(() => {
  //   if (createProductSuccess?.success) {
  //     openSuccessSB(createProductSuccess);
  //   }
  // }, [createProductSuccess]);

  // const updateProduct = useSelector((state) => state.updateProduct);
  // const { error: updateProductError, productUpdate: updateProductSuccess } =
  //   updateProduct;

  // useEffect(() => {
  //   if (updateProductError) {
  //     openErrorSB(updateProductError);
  //   }
  // }, [updateProductError]);

  // useEffect(() => {
  //   if (updateProductSuccess?.success) {
  //     openSuccessSB(updateProductSuccess);
  //   }
  // }, [updateProductSuccess]);

  // const deleteProduct = useSelector((state) => state.deleteProduct);
  // const { error: deleteProductError, productDelete: deleteProductSuccess } =
  //   deleteProduct;

  // useEffect(() => {
  //   if (deleteProductError) {
  //     openErrorSB(deleteProductError);
  //   }
  // }, [deleteProductError]);

  // useEffect(() => {
  //   if (deleteProductSuccess?.success) {
  //     openSuccessSB(deleteProductSuccess);
  //   }
  // }, [deleteProductSuccess]);

  // Product Requirements
  const listProductRequirements = useSelector(
    (state) => state.listProductRequirements
  );
  const { error: listProductRequirementsError } = listProductRequirements;

  useEffect(() => {
    if (listProductRequirementsError) {
      openErrorSB(listProductRequirementsError);
    }
  }, [listProductRequirementsError]);

  const singleProductRequirements = useSelector(
    (state) => state.singleProductRequirements
  );
  const { error: singleProductRequirementsError } = singleProductRequirements;

  useEffect(() => {
    if (singleProductRequirementsError) {
      openErrorSB(singleProductRequirementsError);
    }
  }, [singleProductRequirementsError]);

  // const productRequirementCreate = useSelector(
  //   (state) => state.productRequirementCreate
  // );
  // const {
  //   error: productRequirementCreateError,
  //   newProductRequirement: productRequirementCreateSuccess,
  // } = productRequirementCreate;

  // useEffect(() => {
  //   if (productRequirementCreateError) {
  //     openErrorSB(productRequirementCreateError);
  //   }
  // }, [productRequirementCreateError]);

  // useEffect(() => {
  //   if (productRequirementCreateSuccess?.success) {
  //     openSuccessSB(productRequirementCreateSuccess);
  //   }
  // }, [productRequirementCreateSuccess]);

  // const productRequirementUpdate = useSelector(
  //   (state) => state.productRequirementUpdate
  // );
  // const {
  //   error: productRequirementUpdateError,
  //   updateProductRequirement: productRequirementUpdateSuccess,
  // } = productRequirementUpdate;

  // useEffect(() => {
  //   if (productRequirementUpdateError) {
  //     openErrorSB(productRequirementUpdateError);
  //   }
  // }, [productRequirementUpdateError]);

  // useEffect(() => {
  //   if (productRequirementUpdateSuccess?.success) {
  //     openSuccessSB(productRequirementUpdateSuccess);
  //   }
  // }, [productRequirementUpdateSuccess]);

  // const productRequirementDelete = useSelector(
  //   (state) => state.productRequirementDelete
  // );
  // const {
  //   error: productRequirementDeleteError,
  //   deleteProductRequirement: productRequirementDeleteSuccess,
  // } = productRequirementDelete;

  // useEffect(() => {
  //   if (productRequirementDeleteError) {
  //     openErrorSB(productRequirementDeleteError);
  //   }
  // }, [productRequirementDeleteError]);

  // useEffect(() => {
  //   if (productRequirementDeleteSuccess?.success) {
  //     openSuccessSB(productRequirementDeleteSuccess);
  //   }
  // }, [productRequirementDeleteSuccess]);

  // Response Requirements
  const listResponsePerClientRequirements = useSelector(
    (state) => state.listResponsePerClientRequirements
  );
  const { error: listResponsePerClientRequirementsError } =
    listResponsePerClientRequirements;

  useEffect(() => {
    if (listResponsePerClientRequirementsError) {
      openErrorSB(listResponsePerClientRequirementsError);
    }
  }, [listResponsePerClientRequirementsError]);

  const singleResponseRequirements = useSelector(
    (state) => state.singleResponseRequirements
  );
  const { error: singleResponseRequirementsError } = singleResponseRequirements;

  useEffect(() => {
    if (singleResponseRequirementsError) {
      openErrorSB(singleResponseRequirementsError);
    }
  }, [singleResponseRequirementsError]);

  const responseRequirementCreate = useSelector(
    (state) => state.responseRequirementCreate
  );
  const {
    error: responseRequirementCreateError,
    newResponseRequirement: responseRequirementCreateSuccess,
  } = responseRequirementCreate;

  useEffect(() => {
    if (responseRequirementCreateError) {
      openErrorSB(responseRequirementCreateError);
    }
  }, [responseRequirementCreateError]);

  useEffect(() => {
    if (responseRequirementCreateSuccess?.success) {
      openSuccessSB(responseRequirementCreateSuccess);
    }
  }, [responseRequirementCreateSuccess]);

  const responseRequirementUpdate = useSelector(
    (state) => state.responseRequirementUpdate
  );
  const {
    error: responseRequirementUpdateError,
    updateResponseRequirement: responseRequirementUpdateSuccess,
  } = responseRequirementUpdate;

  useEffect(() => {
    if (responseRequirementUpdateError) {
      openErrorSB(responseRequirementUpdateError);
    }
  }, [responseRequirementUpdateError]);

  useEffect(() => {
    if (responseRequirementUpdateSuccess?.success) {
      openSuccessSB(responseRequirementUpdateSuccess);
    }
  }, [responseRequirementUpdateSuccess]);

  const responseRequirementDelete = useSelector(
    (state) => state.responseRequirementDelete
  );
  const {
    error: responseRequirementDeleteError,
    deleteResponseRequirement: responseRequirementDeleteSuccess,
  } = responseRequirementDelete;

  useEffect(() => {
    if (responseRequirementDeleteError) {
      openErrorSB(responseRequirementDeleteError);
    }
  }, [responseRequirementDeleteError]);

  useEffect(() => {
    if (responseRequirementDeleteSuccess?.success) {
      openSuccessSB(responseRequirementDeleteSuccess);
    }
  }, [responseRequirementDeleteSuccess]);

  // Transactions
  const myListTransactions = useSelector((state) => state.myListTransactions);
  const { error: myListTransactionsError } = myListTransactions;

  useEffect(() => {
    if (myListTransactionsError) {
      openErrorSB(myListTransactionsError);
    }
  }, [myListTransactionsError]);

  const statsTransactions = useSelector((state) => state.statsTransactions);
  const { error: statsTransactionsError } = statsTransactions;

  useEffect(() => {
    if (statsTransactionsError) {
      openErrorSB(statsTransactionsError);
    }
  }, [statsTransactionsError]);

  const incompleteTransactions = useSelector(
    (state) => state.incompleteTransactions
  );
  const { error: incompleteTransactionsError } = incompleteTransactions;

  useEffect(() => {
    if (incompleteTransactionsError) {
      openErrorSB(incompleteTransactionsError);
    }
  }, [incompleteTransactionsError]);

  const orderTransaction = useSelector((state) => state.orderTransaction);
  const { error: orderTransactionError } = orderTransaction;

  useEffect(() => {
    if (orderTransactionError) {
      openErrorSB(orderTransactionError);
    }
  }, [orderTransactionError]);

  const singleTransaction = useSelector((state) => state.singleTransaction);
  const { error: singleTransactionError } = singleTransaction;

  useEffect(() => {
    if (singleTransactionError) {
      openErrorSB(singleTransactionError);
    }
  }, [singleTransactionError]);

  const createTransaction = useSelector((state) => state.createTransaction);
  const {
    error: createTransactionError,
    transactionCreate: createTransactionSuccess,
  } = createTransaction;

  useEffect(() => {
    if (createTransactionError) {
      openErrorSB(createTransactionError);
    }
  }, [createTransactionError]);

  useEffect(() => {
    if (createTransactionSuccess?.success) {
      openSuccessSB(createTransactionSuccess);
    }
  }, [createTransactionSuccess]);

  const updateTransaction = useSelector((state) => state.updateTransaction);
  const {
    error: updateTransactionError,
    transactionUpdate: updateTransactionSuccess,
  } = updateTransaction;

  useEffect(() => {
    if (updateTransactionError) {
      openErrorSB(updateTransactionError);
    }
  }, [updateTransactionError]);

  useEffect(() => {
    if (updateTransactionSuccess?.success) {
      openSuccessSB(updateTransactionSuccess);
    }
  }, [updateTransactionSuccess]);

  const deleteTransaction = useSelector((state) => state.deleteTransaction);
  const {
    error: deleteTransactionError,
    transactionDelete: deleteTransactionSuccess,
  } = deleteTransaction;

  useEffect(() => {
    if (deleteTransactionError) {
      openErrorSB(deleteTransactionError);
    }
  }, [deleteTransactionError]);

  useEffect(() => {
    if (deleteTransactionSuccess?.success) {
      openSuccessSB(deleteTransactionSuccess);
    }
  }, [deleteTransactionSuccess]);

  // User / Admin
  const listUsers = useSelector((state) => state.listUsers);
  const { error: listUsersError } = listUsers;

  useEffect(() => {
    if (listUsersError) {
      openErrorSB(listUsersError);
    }
  }, [listUsersError]);

  const singleUser = useSelector((state) => state.singleUser);
  const { error: singleUserError } = singleUser;

  useEffect(() => {
    if (singleUserError) {
      openErrorSB(singleUserError);
    }
  }, [singleUserError]);

  const createUser = useSelector((state) => state.createUser);
  const { error: createUserError, userCreate: createUserSuccess } = createUser;

  useEffect(() => {
    if (createUserError) {
      openErrorSB(createUserError);
    }
  }, [createUserError]);

  useEffect(() => {
    if (createUserSuccess?.success) {
      openSuccessSB(createUserSuccess);
    }
  }, [createUserSuccess]);

  const updateUser = useSelector((state) => state.updateUser);
  const { error: updateUserError, userUpdate: updateUserSuccess } = updateUser;

  useEffect(() => {
    if (updateUserError) {
      openErrorSB(updateUserError);
    }
  }, [updateUserError]);

  useEffect(() => {
    if (updateUserSuccess?.success) {
      openSuccessSB(updateUserSuccess);
    }
  }, [updateUserSuccess]);

  const deleteUser = useSelector((state) => state.deleteUser);
  const { error: deleteUserError, userDelete: deleteUserSuccess } = deleteUser;

  useEffect(() => {
    if (deleteUserError) {
      openErrorSB(deleteUserError);
    }
  }, [deleteUserError]);

  useEffect(() => {
    if (deleteUserSuccess?.success) {
      openSuccessSB(deleteUserSuccess);
    }
  }, [deleteUserSuccess]);

  // Supplier Stats
  const supplierStats = useSelector((state) => state.supplierStats);
  const { error: supplierStatsError } = supplierStats;

  useEffect(() => {
    if (supplierStatsError) {
      openErrorSB(supplierStatsError);
    }
  }, [supplierStatsError]);

  const statsByAge = useSelector((state) => state.statsByAge);
  const { error: ageStatsError } = statsByAge;

  useEffect(() => {
    if (ageStatsError) {
      openErrorSB(ageStatsError);
    }
  }, [ageStatsError]);

  const statsClientsJoinedPerMonth = useSelector(
    (state) => state.statsClientsJoinedPerMonth
  );
  const { error: clientsJoinedPerMonthError } = statsClientsJoinedPerMonth;

  useEffect(() => {
    if (clientsJoinedPerMonthError) {
      openErrorSB(clientsJoinedPerMonthError);
    }
  }, [clientsJoinedPerMonthError]);

  const statsNewOrdersPerMonth = useSelector(
    (state) => state.statsNewOrdersPerMonth
  );
  const { error: statsNewOrdersPerMonthError } = statsNewOrdersPerMonth;

  useEffect(() => {
    if (statsNewOrdersPerMonthError) {
      openErrorSB(statsNewOrdersPerMonthError);
    }
  }, [statsNewOrdersPerMonthError]);

  const proSupSerComTopPerforming = useSelector(
    (state) => state.proSupSerComTopPerforming
  );
  const { error: proSupSerComTopPerformingError } = proSupSerComTopPerforming;

  useEffect(() => {
    if (proSupSerComTopPerformingError) {
      openErrorSB(proSupSerComTopPerformingError);
    }
  }, [proSupSerComTopPerformingError]);

  const overallSupplierStats = useSelector(
    (state) => state.overallSupplierStats
  );
  const { error: supplierStatsOverallError } = overallSupplierStats;

  useEffect(() => {
    if (supplierStatsOverallError) {
      openErrorSB(supplierStatsOverallError);
    }
  }, [supplierStatsOverallError]);

  return (
    <>
      {successSB && renderSuccessSB}
      {infoSB && renderInfoSB}
      {warningSB && renderWarningSB}
      {errorSB && renderErrorSB}
    </>
  );
}

export default Notifications;
