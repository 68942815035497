export const MY_ORDER_LIST_REQUEST = "MY_ORDER_LIST_REQUEST";
export const MY_ORDER_LIST_SUCCESS = "MY_ORDER_LIST_SUCCESS";
export const MY_ORDER_LIST_FAIL = "MY_ORDER_LIST_FAIL";
export const MY_ORDER_LIST_RESET = "MY_ORDER_LIST_RESET";

export const ORDER_STATS_REQUEST = "ORDER_STATS_REQUEST";
export const ORDER_STATS_SUCCESS = "ORDER_STATS_SUCCESS";
export const ORDER_STATS_FAIL = "ORDER_STATS_FAIL";
export const ORDER_STATS_RESET = "ORDER_STATS_RESET";

export const ORDER_SINGLE_REQUEST = "ORDER_SINGLE_REQUEST";
export const ORDER_SINGLE_SUCCESS = "ORDER_SINGLE_SUCCESS";
export const ORDER_SINGLE_FAIL = "ORDER_SINGLE_FAIL";
export const ORDER_SINGLE_RESET = "ORDER_SINGLE_RESET";

export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL";
export const CREATE_ORDER_RESET = "CREATE_ORDER_RESET";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL";
export const UPDATE_ORDER_RESET = "UPDATE_ORDER_RESET";

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL";
export const DELETE_ORDER_RESET = "DELETE_ORDER_RESET";

export const INCOMPLETE_ORDER_LIST_REQUEST = "INCOMPLETE_ORDER_LIST_REQUEST";
export const INCOMPLETE_ORDER_LIST_SUCCESS = "INCOMPLETE_ORDER_LIST_SUCCESS";
export const INCOMPLETE_ORDER_LIST_FAIL = "INCOMPLETE_ORDER_LIST_FAIL";
export const INCOMPLETE_ORDER_LIST_RESET = "INCOMPLETE_ORDER_LIST_RESET";

export const APPROVED_ORDER_LIST_REQUEST = "APPROVED_ORDER_LIST_REQUEST";
export const APPROVED_ORDER_LIST_SUCCESS = "APPROVED_ORDER_LIST_SUCCESS";
export const APPROVED_ORDER_LIST_FAIL = "APPROVED_ORDER_LIST_FAIL";
export const APPROVED_ORDER_LIST_RESET = "APPROVED_ORDER_LIST_RESET";

export const SHIPPING_ORDER_LIST_REQUEST = "SHIPPING_ORDER_LIST_REQUEST";
export const SHIPPING_ORDER_LIST_SUCCESS = "SHIPPING_ORDER_LIST_SUCCESS";
export const SHIPPING_ORDER_LIST_FAIL = "SHIPPING_ORDER_LIST_FAIL";
export const SHIPPING_ORDER_LIST_RESET = "SHIPPING_ORDER_LIST_RESET";

export const DELIVERED_ORDER_LIST_REQUEST = "DELIVERED_ORDER_LIST_REQUEST";
export const DELIVERED_ORDER_LIST_SUCCESS = "DELIVERED_ORDER_LIST_SUCCESS";
export const DELIVERED_ORDER_LIST_FAIL = "DELIVERED_ORDER_LIST_FAIL";
export const DELIVERED_ORDER_LIST_RESET = "DELIVERED_ORDER_LIST_RESET";
