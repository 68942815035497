export const PRODUCT_REQUIREMENT_LIST_REQUEST =
  "PRODUCT_REQUIREMENT_LIST_REQUEST";
export const PRODUCT_REQUIREMENT_LIST_SUCCESS =
  "PRODUCT_REQUIREMENT_LIST_SUCCESS";
export const PRODUCT_REQUIREMENT_LIST_FAIL = "PRODUCT_REQUIREMENT_LIST_FAIL";
export const PRODUCT_REQUIREMENT_LIST_RESET = "PRODUCT_REQUIREMENT_LIST_RESET";

export const PRODUCT_REQUIREMENT_SINGLE_REQUEST =
  "PRODUCT_REQUIREMENT_SINGLE_REQUEST";
export const PRODUCT_REQUIREMENT_SINGLE_SUCCESS =
  "PRODUCT_REQUIREMENT_SINGLE_SUCCESS";
export const PRODUCT_REQUIREMENT_SINGLE_FAIL =
  "PRODUCT_REQUIREMENT_SINGLE_FAIL";
export const PRODUCT_REQUIREMENT_SINGLE_RESET =
  "PRODUCT_REQUIREMENT_SINGLE_RESET";
