import axios from "axios";
import {
  MY_TRANSACTION_LIST_REQUEST,
  MY_TRANSACTION_LIST_SUCCESS,
  MY_TRANSACTION_LIST_FAIL,
  TRANSACTION_STATS_REQUEST,
  TRANSACTION_STATS_SUCCESS,
  TRANSACTION_STATS_FAIL,
  TRANSACTION_INCOMPLETE_REQUEST,
  TRANSACTION_INCOMPLETE_SUCCESS,
  TRANSACTION_INCOMPLETE_FAIL,
  ORDER_TRANSACTION_REQUEST,
  ORDER_TRANSACTION_SUCCESS,
  ORDER_TRANSACTION_FAIL,
  TRANSACTION_SINGLE_REQUEST,
  TRANSACTION_SINGLE_SUCCESS,
  TRANSACTION_SINGLE_FAIL,
  CREATE_TRANSACTION_REQUEST,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_FAIL,
  MPESA_TRANSACTION_REQUEST,
  MPESA_TRANSACTION_SUCCESS,
  MPESA_TRANSACTION_FAIL,
  CREDIT_TRANSACTION_REQUEST,
  CREDIT_TRANSACTION_SUCCESS,
  CREDIT_TRANSACTION_FAIL,
  BANK_TRANSFER_TRANSACTION_REQUEST,
  BANK_TRANSFER_TRANSACTION_SUCCESS,
  BANK_TRANSFER_TRANSACTION_FAIL,
  CHEQUE_TRANSACTION_REQUEST,
  CHEQUE_TRANSACTION_SUCCESS,
  CHEQUE_TRANSACTION_FAIL,
  UPDATE_TRANSACTION_REQUEST,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAIL,
  DELETE_TRANSACTION_REQUEST,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAIL,
} from "constants/transactionConstants";
import { REACT_APP_API_URL } from "constants/apiConstants";
import TransactionsService from "services/transactions-service";

export const getMyTransactionList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: MY_TRANSACTION_LIST_REQUEST,
    });

    const res = await TransactionsService.getMyTransactions();
    console.log(
      "🚀 ~ file: transactionActions.js:24 ~ getTransactionList ~ res:",
      res
    );

    dispatch({
      type: MY_TRANSACTION_LIST_SUCCESS,
      payload: res.foundTransactions,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: transactionActions.js:27 ~ getTransactionList ~ error:",
      error
    );
    dispatch({
      type: MY_TRANSACTION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getStatsTransactions = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TRANSACTION_STATS_REQUEST,
    });

    const res = await TransactionsService.getTransactionsStats();
    console.log(
      "🚀 ~ file: transactionActions.js:67 ~ getTransactionStats ~ res:",
      res
    );

    dispatch({
      type: TRANSACTION_STATS_SUCCESS,
      payload: res.result,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: transactionActions.js:74 ~ getTransactionStats ~ error:",
      error
    );
    dispatch({
      type: TRANSACTION_STATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getIncompleteTransactions = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TRANSACTION_INCOMPLETE_REQUEST,
    });

    const res = await TransactionsService.getIncompleteTransactions();
    console.log(
      "🚀 ~ file: transactionActions.js:100 ~ getIncompleteTransactions ~ res:",
      res
    );

    dispatch({
      type: TRANSACTION_INCOMPLETE_SUCCESS,
      payload: res.foundTransactions,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: transactionActions.js:107 ~ getIncompleteTransactions ~ error:",
      error
    );
    dispatch({
      type: TRANSACTION_INCOMPLETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getOrderTransaction = (orderId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_TRANSACTION_REQUEST,
    });

    const res = await axios.get(
      `${REACT_APP_API_URL}/admin/transactions/order/${orderId}/`
    );

    var response = res.data;

    const changeDate = (a) => new Date(a).toLocaleDateString();

    const result = response.map((r) => ({
      ...r,
      created_at: changeDate(r.created_at),
    }));

    dispatch({
      type: ORDER_TRANSACTION_SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: ORDER_TRANSACTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getSingleTransaction =
  (transactionId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TRANSACTION_SINGLE_REQUEST,
      });

      const res = await TransactionsService.getSingleTransaction(transactionId);
      console.log("🚀 ~ file: transactionActions.js:98 ~ res:", res);

      dispatch({
        type: TRANSACTION_SINGLE_SUCCESS,
        payload: res.foundTransaction,
      });
    } catch (error) {
      console.log("🚀 ~ file: transactionActions.js:105 ~ error:", error);
      dispatch({
        type: TRANSACTION_SINGLE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error,
      });
    }
  };

export const newTransactionCreate =
  (transaction) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_TRANSACTION_REQUEST,
      });

      const res = await TransactionsService.createNewTransaction(transaction);
      console.log("🚀 ~ file: transactionActions.js:124 ~ res:", res);
      dispatch(getTransactionList());
      dispatch(getStatsTransactions());
      dispatch(getIncompleteTransactions());

      dispatch({
        type: CREATE_TRANSACTION_SUCCESS,
        payload: res,
      });
    } catch (error) {
      console.log("🚀 ~ file: transactionActions.js:131 ~ error:", error);
      dispatch({
        type: CREATE_TRANSACTION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error,
      });
    }
  };

export const mpesaTransactionCreate =
  (transaction) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MPESA_TRANSACTION_REQUEST,
      });

      const res = await TransactionsService.createMpesaTransaction(transaction);
      console.log("🚀 ~ file: transactionActions.js:233 ~ res:", res);

      dispatch({
        type: MPESA_TRANSACTION_SUCCESS,
        payload: res,
      });
    } catch (error) {
      console.log("🚀 ~ file: transactionActions.js:239 ~ error:", error);
      dispatch({
        type: MPESA_TRANSACTION_FAIL,
        payload: error,
      });
    }
  };

export const creditTransactionCreate =
  (transaction) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREDIT_TRANSACTION_REQUEST,
      });

      const res = await TransactionsService.createCreditTransaction(
        transaction
      );
      console.log("🚀 ~ file: transactionActions.js:259 ~ res:", res);

      dispatch({
        type: CREDIT_TRANSACTION_SUCCESS,
        payload: res,
      });
    } catch (error) {
      console.log("🚀 ~ file: transactionActions.js:266 ~ error:", error);
      dispatch({
        type: CREDIT_TRANSACTION_FAIL,
        payload: error,
      });
    }
  };

export const bankTransferTransactionCreate =
  (transaction) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BANK_TRANSFER_TRANSACTION_REQUEST,
      });

      const res = await TransactionsService.createBankTransferTransaction(
        transaction
      );
      console.log("🚀 ~ file: transactionActions.js:285 ~ res:", res);

      dispatch({
        type: BANK_TRANSFER_TRANSACTION_SUCCESS,
        payload: res,
      });
    } catch (error) {
      console.log("🚀 ~ file: transactionActions.js:292 ~ error:", error);
      dispatch({
        type: BANK_TRANSFER_TRANSACTION_FAIL,
        payload: error,
      });
    }
  };

export const chequeTransactionCreate =
  (transaction) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CHEQUE_TRANSACTION_REQUEST,
      });

      const res = await TransactionsService.createChequeTransaction(
        transaction
      );
      console.log("🚀 ~ file: transactionActions.js:311 ~ res:", res);

      dispatch({
        type: CHEQUE_TRANSACTION_SUCCESS,
        payload: res,
      });
    } catch (error) {
      console.log("🚀 ~ file: transactionActions.js:318 ~ error:", error);
      dispatch({
        type: CHEQUE_TRANSACTION_FAIL,
        payload: error,
      });
    }
  };

export const updatingTransaction =
  (transactionId, transaction) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_TRANSACTION_REQUEST,
      });

      const res = await TransactionsService.editTransaction(
        transactionId,
        transaction
      );
      console.log("🚀 ~ file: transactionActions.js:153 ~ res:", res);
      dispatch(getTransactionList());
      dispatch(getStatsTransactions());
      dispatch(getIncompleteTransactions());

      dispatch({
        type: UPDATE_TRANSACTION_SUCCESS,
        payload: res,
      });
    } catch (error) {
      console.log("🚀 ~ file: transactionActions.js:160 ~ error:", error);
      dispatch({
        type: UPDATE_TRANSACTION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error,
      });
    }
  };

export const deleteTransaction =
  (transactionId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DELETE_TRANSACTION_REQUEST,
      });

      const res = await TransactionsService.getTransactions(transactionId);

      dispatch({
        type: DELETE_TRANSACTION_SUCCESS,
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: DELETE_TRANSACTION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error,
      });
    }
  };
