import {
  POST_LIST_REQUEST,
  POST_LIST_SUCCESS,
  POST_LIST_FAIL,
  POST_SEARCH_REQUEST,
  POST_SEARCH_SUCCESS,
  POST_SEARCH_FAIL,
  POST_SINGLE_REQUEST,
  POST_SINGLE_SUCCESS,
  POST_SINGLE_FAIL,
} from "constants/postConstants";
import PostsService from "services/posts-service";

export const getPostList = () => async (dispatch) => {
  try {
    dispatch({
      type: POST_LIST_REQUEST,
    });

    const res = await PostsService.getPosts();
    console.log("🚀 ~ file: postActions.js:23 ~ getPostList ~ res:", res);

    dispatch({
      type: POST_LIST_SUCCESS,
      payload: res.foundPosts,
    });
  } catch (error) {
    console.log("🚀 ~ file: postActions.js:30 ~ getPostList ~ error:", error);
    dispatch({
      type: POST_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getSearchPost = (postSearchTerms) => async (dispatch) => {
  console.log(
    "🚀 ~ file: postActions.js:65 ~ getSearchPost ~ postSearchTerms:",
    postSearchTerms
  );

  try {
    dispatch({
      type: POST_SEARCH_REQUEST,
    });

    const res = await PostsService.getPostSearch(postSearchTerms);
    console.log("🚀 ~ file: postActions.js:81 ~ getSearchPost ~ res:", res);

    dispatch({
      type: POST_SEARCH_SUCCESS,
      payload: res.foundSearchPost,
    });
  } catch (error) {
    console.log("🚀 ~ file: postActions.js:87 ~ getSearchPost ~ error:", error);
    dispatch({
      type: POST_SEARCH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getSinglePost = (postId) => async (dispatch) => {
  try {
    dispatch({
      type: POST_SINGLE_REQUEST,
    });

    const res = await PostsService.getPostSingle(postId);
    console.log("🚀 ~ file: postActions.js:98 ~ getSinglePost ~ res:", res);

    dispatch({
      type: POST_SINGLE_SUCCESS,
      payload: res.foundPost,
    });
  } catch (error) {
    console.log("🚀 ~ file: postActions.js:98 ~ getSinglePost ~ error:", error);
    dispatch({
      type: POST_SINGLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};
