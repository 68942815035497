export const CLIENT_LIST_REQUEST = "CLIENT_LIST_REQUEST";
export const CLIENT_LIST_SUCCESS = "CLIENT_LIST_SUCCESS";
export const CLIENT_LIST_FAIL = "CLIENT_LIST_FAIL";
export const CLIENT_LIST_RESET = "CLIENT_LIST_RESET";

export const CLIENT_SINGLE_REQUEST = "CLIENT_SINGLE_REQUEST";
export const CLIENT_SINGLE_SUCCESS = "CLIENT_SINGLE_SUCCESS";
export const CLIENT_SINGLE_FAIL = "CLIENT_SINGLE_FAIL";
export const CLIENT_SINGLE_RESET = "CLIENT_SINGLE_RESET";

export const CREATE_CLIENT_REQUEST = "CREATE_CLIENT_REQUEST";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAIL = "CREATE_CLIENT_FAIL";
export const CREATE_CLIENT_RESET = "CREATE_CLIENT_RESET";

export const UPDATE_CLIENT_REQUEST = "UPDATE_CLIENT_REQUEST";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL";
export const UPDATE_CLIENT_RESET = "UPDATE_CLIENT_RESET";

export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAIL = "DELETE_CLIENT_FAIL";
export const DELETE_CLIENT_RESET = "DELETE_CLIENT_RESET";
