import {
  RESPONSE_REQUIREMENT_PER_CLIENT_LIST_REQUEST,
  RESPONSE_REQUIREMENT_PER_CLIENT_LIST_SUCCESS,
  RESPONSE_REQUIREMENT_PER_CLIENT_LIST_FAIL,
  RESPONSE_REQUIREMENT_PER_CLIENT_LIST_RESET,
  RESPONSE_REQUIREMENT_SINGLE_REQUEST,
  RESPONSE_REQUIREMENT_SINGLE_SUCCESS,
  RESPONSE_REQUIREMENT_SINGLE_FAIL,
  RESPONSE_REQUIREMENT_SINGLE_RESET,
  CREATE_RESPONSE_REQUIREMENT_REQUEST,
  CREATE_RESPONSE_REQUIREMENT_SUCCESS,
  CREATE_RESPONSE_REQUIREMENT_FAIL,
  CREATE_RESPONSE_REQUIREMENT_RESET,
  UPDATE_RESPONSE_REQUIREMENT_REQUEST,
  UPDATE_RESPONSE_REQUIREMENT_SUCCESS,
  UPDATE_RESPONSE_REQUIREMENT_FAIL,
  UPDATE_RESPONSE_REQUIREMENT_RESET,
  DELETE_RESPONSE_REQUIREMENT_REQUEST,
  DELETE_RESPONSE_REQUIREMENT_SUCCESS,
  DELETE_RESPONSE_REQUIREMENT_FAIL,
  DELETE_RESPONSE_REQUIREMENT_RESET,
} from "constants/responseRequirementConstants";

export const listResponsePerClientRequirementsReducer = (
  state = { responsePerClientRequirementList: [] },
  action
) => {
  switch (action.type) {
    case RESPONSE_REQUIREMENT_PER_CLIENT_LIST_REQUEST:
      return { loading: true };
    case RESPONSE_REQUIREMENT_PER_CLIENT_LIST_SUCCESS:
      return {
        loading: false,
        responsePerClientRequirementList: action.payload,
      };
    case RESPONSE_REQUIREMENT_PER_CLIENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case RESPONSE_REQUIREMENT_PER_CLIENT_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const singleResponseRequirementsReducer = (
  state = { responseRequirementSingle: {} },
  action
) => {
  switch (action.type) {
    case RESPONSE_REQUIREMENT_SINGLE_REQUEST:
      return { loading: true };
    case RESPONSE_REQUIREMENT_SINGLE_SUCCESS:
      return { loading: false, responseRequirementSingle: action.payload };
    case RESPONSE_REQUIREMENT_SINGLE_FAIL:
      return { loading: false, error: action.payload };
    case RESPONSE_REQUIREMENT_SINGLE_RESET:
      return [];
    default:
      return state;
  }
};

export const responseRequirementCreateReducer = (
  state = { newResponseRequirement: [] },
  action
) => {
  switch (action.type) {
    case CREATE_RESPONSE_REQUIREMENT_REQUEST:
      return { loading: true, success: false, error: false };
    case CREATE_RESPONSE_REQUIREMENT_SUCCESS:
      return {
        loading: false,
        newResponseRequirement: action.payload,
        success: true,
        error: false,
      };
    case CREATE_RESPONSE_REQUIREMENT_FAIL:
      return { loading: false, error: action.payload, success: false };
    case CREATE_RESPONSE_REQUIREMENT_RESET:
      return { newResponseRequirement: [] };
    default:
      return state;
  }
};

export const responseRequirementUpdateReducer = (
  state = { updateResponseRequirement: [] },
  action
) => {
  switch (action.type) {
    case UPDATE_RESPONSE_REQUIREMENT_REQUEST:
      return { loading: true };
    case UPDATE_RESPONSE_REQUIREMENT_SUCCESS:
      return {
        loading: false,
        updateResponseRequirement: action.payload,
        success: true,
      };
    case UPDATE_RESPONSE_REQUIREMENT_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_RESPONSE_REQUIREMENT_RESET:
      return { updateResponseRequirement: [] };
    default:
      return state;
  }
};

export const responseRequirementDeleteReducer = (
  state = { deleteResponseRequirement: {} },
  action
) => {
  switch (action.type) {
    case DELETE_RESPONSE_REQUIREMENT_REQUEST:
      return { loading: true };
    case DELETE_RESPONSE_REQUIREMENT_SUCCESS:
      return {
        loading: false,
        deleteResponseRequirement: action.payload,
        success: true,
      };
    case DELETE_RESPONSE_REQUIREMENT_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_RESPONSE_REQUIREMENT_RESET:
      return { deleteResponseRequirement: {} };
    default:
      return state;
  }
};
