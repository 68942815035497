// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

// Material Kit 2 React components
import MKInput from "components/MKInput";

function MKDatePicker({ input, label, ...rest }) {
  return (
    <Flatpickr
      {...rest}
      render={({ defaultValue }, ref) => (
        <MKInput
          {...input}
          defaultValue={defaultValue}
          inputRef={ref}
          label={label}
          variant="outlined"
          fullWidth
          focused
          margin="normal"
        />
      )}
    />
  );
}

// Setting default values for the props of MKDatePicker
MKDatePicker.defaultProps = {
  input: {},
};

// Typechecking props for the MKDatePicker
MKDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
};

export default MKDatePicker;
