// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function MenuItem({ title, icon }) {
  return (
    <MKBox
      variant="outlined"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={1}
      sx={{ cursor: "pointer" }}
    >
      <MKBox display="flex" alignItems="center">
        <Icon fontSize="large">{icon}</Icon>
        <MKTypography
          display="block"
          variant="button"
          fontWeight="medium"
          pl={1}
        >
          {title}
        </MKTypography>
      </MKBox>
      <MKBox display="flex" alignItems="center">
        <MKBox
          display="flex"
          alignItems="center"
          ml={3}
          sx={{ cursor: "pointer" }}
        >
          <Icon fontSize="large">chevron_right</Icon>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

// Typechecking props for the MenuItem
MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default MenuItem;
