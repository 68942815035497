import { useEffect, useContext } from "react";

// react-router components
import {
  Routes,
  Route,
  Navigate,
  // useLocation,
  useNavigate,
} from "react-router-dom";

// @mui material components components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

import { AuthContext } from "context";
import { routes, routesToExcludeFromNavbar } from "routes";

import { useLocation, useRouter } from "wouter";
import { motion, AnimatePresence } from "framer-motion";

import ForgotPassword from "auth/forgot-password";
import ResetPassword from "auth/reset-password";
import Login from "auth/login";
import Register from "auth/register";

import Details from "pages/Details";
import ProblemSolution from "pages/ProblemSolution";
import SustainabilityCircularity from "pages/SustainabilityCircularity";
import ProteinPremiumMarkets from "pages/ProteinPremiumMarkets";
import Profile from "pages/Profile";
import Dashboard from "pages/Dashboard";

import AboutUs from "pages/LandingPages/AboutUs";
import Technology from "pages/LandingPages/Technology";
import Products from "pages/LandingPages/Products";
import NewsAndEvents from "pages/LandingPages/NewsAndEvents";
import Ecommerce from "pages/LandingPages/Ecommerce";

import MKBox from "components/MKBox";
import MKCookieBanner from "components/MKCookieBanner";

import { setupAxiosInterceptors } from "./services/interceptor";
import ProtectedRoute from "examples/ProtectedRoute";
import Loading from "examples/Loading";
import Notifications from "examples/Notifications";

export default function App() {
  const authContext = useContext(AuthContext);
  // const { pathname } = useLocation();

  useRouter();
  const [location] = useLocation();
  const RouteAnimated = routes[location];

  const AnimationSettings = {
    transition: { duration: 0.5 },
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  // if the token expired or other errors it logs out and goes to the login page
  const navigate = useNavigate();
  setupAxiosInterceptors(() => {
    authContext.logout();
    navigate("/presentation");
  });

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  const getRoutes = (allRoutes) =>
    allRoutes
      .map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route && route.type !== "auth") {
          return (
            <Route
              exact
              path={route.route}
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  {route.component}
                </ProtectedRoute>
              }
              key={route.key}
            />
          );
        }

        return null;
      })
      .concat(
        routesToExcludeFromNavbar.map((route) => (
          <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        ))
      );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MKBox>
        <Loading />
      </MKBox>
      <MKBox>
        <Notifications />
      </MKBox>
      <AnimatePresence initial={false}>
        <motion.div
          className="route-container"
          key={location}
          {...AnimationSettings}
        >
          <Routes>
            <Route exact path="/presentation" element={<Presentation />} />

            <Route path="/auth/login" element={<Login />} key="login" />
            <Route
              path="/auth/register"
              element={<Register />}
              key="register"
            />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/reset-password" element={<ResetPassword />} />
            <Route exact path="/about-us" element={<AboutUs />} />
            <Route exact path="/technology" element={<Technology />} />
            <Route exact path="/products" element={<Products />} />
            <Route exact path="/news-and-events" element={<NewsAndEvents />} />
            <Route exact path="/e-commerce" element={<Ecommerce />} />
            <Route
              exact
              path="/details/:id"
              element={<Details />}
              key="details"
            />

            <Route
              exact
              path="/problem-solution"
              element={<ProblemSolution />}
              key="problem-solution"
            />

            <Route
              exact
              path="/sustainability-circularity"
              element={<SustainabilityCircularity />}
              key="sustainability-circularity"
            />

            <Route
              exact
              path="/protein-premium-markets"
              element={<ProteinPremiumMarkets />}
              key="protein-premium-markets"
            />

            <Route
              exact
              path="/dashboard"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <Dashboard />
                </ProtectedRoute>
              }
              key="dashboard"
            />
            <Route
              exact
              path="/profile"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <Profile />
                </ProtectedRoute>
              }
              key="profile"
            />

            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/presentation" />} />
          </Routes>
        </motion.div>
      </AnimatePresence>
      <MKCookieBanner />
    </ThemeProvider>
  );
}
