import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKPageHeaderTemplate from "components/MKPageHeaderTemplate";
import MKTypography from "components/MKTypography";

// Images
import ecommerceBg from "assets/images/coming-soon.jpg";

function Ecommerce() {
  const theme = useTheme();
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.between("xxs", "xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXXLargeScreen = useMediaQuery(theme.breakpoints.between("xl", "xxl"));
  const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <>
      <MKPageHeaderTemplate
        bgImage={ecommerceBg}
        title="E-Commerce"
        subTitle="Coming Soon"
      >
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{
            textAlign: "center",
            my:
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? 20
                : 10,
            mx: "auto",
            px: 0.75,
          }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "h3"
                : "h4"
            }
            fontWeight="bold"
            sx={{
              pb: 2,
              color: ({ palette: { text } }) => text.light,
            }}
          >
            Hang on its coming!
          </MKTypography>
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "h4"
                : "h5"
            }
            fontWeight="bold"
            sx={{
              pb: 2,
              color: ({ palette: { text } }) => text.lightAccent,
            }}
          >
            If in Kenya, you’ll be able to order Protein and Fertilizer here..
          </MKTypography>
        </Grid>
      </MKPageHeaderTemplate>
    </>
  );
}

export default Ecommerce;
