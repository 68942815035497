import MKPageHeaderVideoTemplate from "components/MKPageHeaderVideoTemplate";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredTechCard from "examples/Cards/BlogCards/CenteredTechCard";

// Video
import productsBgVideo from "assets/Product_page_invert.mp4";
import image1 from "assets/images/1.png";
import image2 from "assets/images/bucket-tilapia.png";
import image3 from "assets/images/3.png";
import image4 from "assets/images/4.png";
import image5 from "assets/images/5.png";

function Products() {
  const theme = useTheme();
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.between("xxs", "xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXXLargeScreen = useMediaQuery(theme.breakpoints.between("xl", "xxl"));
  const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <>
      <MKPageHeaderVideoTemplate
        bgVideo={productsBgVideo}
        title="Our Products"
        subTitle=""
      >
        <MKBox component="section" py={1}>
          <Container
            sx={{
              my:
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? 20
                  : 5,
            }}
          >
            <Grid container spacing={10}>
              <Grid item xxs={12} xs={12} sm={12} md={6}>
                <CenteredTechCard
                  image={image1}
                  title="tech Join the Movement"
                  description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                  action={{
                    type: "internal",
                    route: "#",
                    color: "info",
                    label: "find out more",
                  }}
                />
              </Grid>
              <Grid item xxs={12} xs={12} sm={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon=""
                    title="Delivering Organic protein for the world"
                    direction="right"
                    description="We offer fresh and dried fish protein products, along with our flagship organic Biopoop biopesticide fertiliser. Supplying both local and international markets, we provide sustainable solutions that meet the rigorous standards of the super-premium organic protein market."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Container>

          {!isXXSmallScreen &&
          !isXSmallScreen &&
          !isSmallScreen &&
          !isMediumScreen ? (
            <Container sx={{ my: 10 }}>
              <Grid container spacing={10} justifyContent="flex-start">
                <Grid item xxs={12} xs={12} sm={12} md={6}>
                  <MKBox mb={5} pt={5}>
                    <DefaultInfoCard
                      icon=""
                      title="Premium Protein"
                      direction="left"
                      description="We produce edible (freshwater) fish for human consumption for local markets and dried fishmeal (ground) for consumption or processed as dry protein derivatives including hydrolysates, isolates and fish oils (Omega 3 rich)."
                    />
                  </MKBox>
                </Grid>

                <Grid item xxs={12} xs={12} sm={12} md={6}>
                  <CenteredTechCard
                    image={image2}
                    title="tech Join the Movement"
                    description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                    action={{
                      type: "internal",
                      route: "#",
                      color: "info",
                      label: "find out more",
                    }}
                  />
                </Grid>
              </Grid>
            </Container>
          ) : (
            <Container sx={{ my: 10 }}>
              <Grid container spacing={10} justifyContent="flex-start">
                <Grid item xxs={12} xs={12} sm={12} md={6}>
                  <CenteredTechCard
                    image={image2}
                    title="tech Join the Movement"
                    description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                    action={{
                      type: "internal",
                      route: "#",
                      color: "info",
                      label: "find out more",
                    }}
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon=""
                      title="Premium Protein"
                      direction="right"
                      description="We produce edible (freshwater) fish for human consumption for local markets and dried fishmeal (ground) for consumption or processed as dry protein derivatives including hydrolysates, isolates and fish oils (Omega 3 rich)."
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Container>
          )}

          <Container
            sx={{
              my:
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? 20
                  : 0,
            }}
          >
            <Grid container spacing={10}>
              <Grid item xxs={12} xs={12} sm={12} md={6}>
                <CenteredTechCard
                  image={image3}
                  title="tech Join the Movement"
                  description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                  action={{
                    type: "internal",
                    route: "#",
                    color: "info",
                    label: "find out more",
                  }}
                />
              </Grid>
              <Grid item xxs={12} xs={12} sm={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon=""
                    title="Edible fish"
                    direction="right"
                    description="We produce sustainably sourced, organic edible human fish (Tilapia, Catfish, Nyanza barbs) for the local market."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Container>

          {!isXXSmallScreen &&
          !isXSmallScreen &&
          !isSmallScreen &&
          !isMediumScreen ? (
            <Container sx={{ my: 10 }}>
              <Grid container spacing={10} justifyContent="flex-start">
                <Grid item xxs={12} xs={12} sm={12} md={6}>
                  <MKBox mb={5} pt={5}>
                    <DefaultInfoCard
                      icon=""
                      title="Super-premium dry protein derivatives"
                      direction="left"
                      description="We also produce dry protein derivatives for the pan-Africa and International super-premium ‘organic’ protein markets (cosmetics, nutrition/supplement vitamins and pharma/healthcare)"
                    />
                  </MKBox>
                </Grid>

                <Grid item xxs={12} xs={12} sm={12} md={6}>
                  <CenteredTechCard
                    image={image4}
                    title="tech Join the Movement"
                    description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                    action={{
                      type: "internal",
                      route: "#",
                      color: "info",
                      label: "find out more",
                    }}
                  />
                </Grid>
              </Grid>
            </Container>
          ) : (
            <Container sx={{ my: 10 }}>
              <Grid container spacing={10} justifyContent="flex-start">
                <Grid item xxs={12} xs={12} sm={12} md={6}>
                  <CenteredTechCard
                    image={image4}
                    title="tech Join the Movement"
                    description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                    action={{
                      type: "internal",
                      route: "#",
                      color: "info",
                      label: "find out more",
                    }}
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon=""
                      title="Super-premium dry protein derivatives"
                      direction="right"
                      description="We also produce dry protein derivatives for the pan-Africa and International super-premium ‘organic’ protein markets (cosmetics, nutrition/supplement vitamins and pharma/healthcare)"
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Container>
          )}

          <Container
            sx={{
              my:
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? 20
                  : 0,
            }}
          >
            <Grid container spacing={10}>
              <Grid item xxs={12} xs={12} sm={12} md={6}>
                <CenteredTechCard
                  image={image5}
                  title="tech Join the Movement"
                  description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                  action={{
                    type: "internal",
                    route: "#",
                    color: "info",
                    label: "find out more",
                  }}
                />
              </Grid>
              <Grid item xxs={12} xs={12} sm={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon=""
                    title="Biopoop organic biopesticide fertiliser"
                    direction="right"
                    description="In addition to our protein products, we sell organic biopesticide fertiliser, Biopoop."
                    description2="All of our fish waste is extracted by gravity, fermented and produced into our premium biopesticide fertiliser through a unique and proprietary method.  Through extensive investigation and tests, we have found a friendly collaborator fungus to help control bacteria while offering an exceptional biopesticide and organic fertiliser naturally high in NPK and micro-nutrients."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </MKPageHeaderVideoTemplate>
    </>
  );
}

export default Products;
