export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";
export const PRODUCT_LIST_RESET = "PRODUCT_LIST_RESET";

export const PRODUCT_STATS_REQUEST = "PRODUCT_STATS_REQUEST";
export const PRODUCT_STATS_SUCCESS = "PRODUCT_STATS_SUCCESS";
export const PRODUCT_STATS_FAIL = "PRODUCT_STATS_FAIL";
export const PRODUCT_STATS_RESET = "PRODUCT_STATS_RESET";

export const PRODUCT_SEARCH_REQUEST = "PRODUCT_SEARCH_REQUEST";
export const PRODUCT_SEARCH_SUCCESS = "PRODUCT_SEARCH_SUCCESS";
export const PRODUCT_SEARCH_FAIL = "PRODUCT_SEARCH_FAIL";
export const PRODUCT_SEARCH_RESET = "PRODUCT_SEARCH_RESET";

export const PRODUCT_SINGLE_REQUEST = "PRODUCT_SINGLE_REQUEST";
export const PRODUCT_SINGLE_SUCCESS = "PRODUCT_SINGLE_SUCCESS";
export const PRODUCT_SINGLE_FAIL = "PRODUCT_SINGLE_FAIL";
export const PRODUCT_SINGLE_RESET = "PRODUCT_SINGLE_RESET";

export const PRODUCT_PER_SERVICE_REQUEST = "PRODUCT_PER_SERVICE_REQUEST";
export const PRODUCT_PER_SERVICE_SUCCESS = "PRODUCT_PER_SERVICE_SUCCESS";
export const PRODUCT_PER_SERVICE_FAIL = "PRODUCT_PER_SERVICE_FAIL";
export const PRODUCT_PER_SERVICE_RESET = "PRODUCT_PER_SERVICE_RESET";
