import HttpService from "./htttp.service";

class PostsService {
  // PostsEndpoint = process.env.API_URL;

  getPosts = async () => {
    const getPosts = "fe/posts";
    return await HttpService.get(getPosts);
  };

  getPostSearch = async (payload) => {
    const getPostSearch = `fe/posts/search?postTitle=${payload.postTitle}&content=${payload.content}`;
    return await HttpService.get(getPostSearch, payload);
  };

  getPostSingle = async (id) => {
    const getPostSingle = `fe/posts/${id}`;
    return await HttpService.get(getPostSingle);
  };
}

export default new PostsService();
