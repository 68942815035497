import * as React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 55,
    height: 10,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

function Loading() {
  //addons
  const listAddons = useSelector((state) => state.listAddons);
  const { loading: listAddonsLoading } = listAddons;

  const singleAddons = useSelector((state) => state.singleAddons);
  const { loading: singleAddonsLoading } = singleAddons;

  // const addonCreate = useSelector((state) => state.addonCreate);
  // const { loading: addonCreateLoading } = addonCreate;

  // const addonUpdate = useSelector((state) => state.addonUpdate);
  // const { loading: addonUpdateLoading } = addonUpdate;

  // const addonDelete = useSelector((state) => state.addonDelete);
  // const { loading: addonDeleteLoading } = addonDelete;

  // Services also all go here
  const listServices = useSelector((state) => state.listServices);
  const { loading: listServicesLoading } = listServices;

  // const servicePost = useSelector((state) => state.servicePost);
  // const { loading: servicePostLoading } = servicePost;

  // const updateService = useSelector((state) => state.updateService);
  // const { loading: updateServiceLoading } = updateService;

  // const deleteService = useSelector((state) => state.deleteService);
  // const { loading: deleteServiceLoading } = deleteService;

  // Complaints
  const myListComplaint = useSelector((state) => state.myListComplaint);
  const { loading: myListComplaintLoading } = myListComplaint;

  const singleComplaint = useSelector((state) => state.singleComplaint);
  const { loading: singleComplaintLoading } = singleComplaint;

  const pendingComplaints = useSelector((state) => state.pendingComplaints);
  const { loading: pendingComplaintsLoading } = pendingComplaints;

  const postComplaint = useSelector((state) => state.postComplaint);
  const { loading: postComplaintLoading } = postComplaint;

  const updateComplaints = useSelector((state) => state.updateComplaints);
  const { loading: updateComplaintsLoading } = updateComplaints;

  const deleteComplaints = useSelector((state) => state.deleteComplaints);
  const { loading: deleteComplaintsLoading } = deleteComplaints;

  // Complaints Requirements
  const listComplaintRequirements = useSelector(
    (state) => state.listComplaintRequirements
  );
  const { loading: listComplaintRequirementsLoading } =
    listComplaintRequirements;

  const singleComplaintRequirements = useSelector(
    (state) => state.singleComplaintRequirements
  );
  const { loading: singleComplaintRequirementsLoading } =
    singleComplaintRequirements;

  // const complaintRequirementCreate = useSelector(
  //   (state) => state.complaintRequirementCreate
  // );
  // const { loading: complaintRequirementCreateLoading } = complaintRequirementCreate;

  // const complaintRequirementUpdate = useSelector(
  //   (state) => state.complaintRequirementUpdate
  // );
  // const { loading: complaintRequirementUpdateLoading } = complaintRequirementUpdate;

  // const complaintRequirementDelete = useSelector(
  //   (state) => state.complaintRequirementDelete
  // );
  // const { loading: complaintRequirementDeleteLoading } = complaintRequirementDelete;

  // Clients
  const listClients = useSelector((state) => state.listClients);
  const { loading: listClientsLoading } = listClients;

  const singleClient = useSelector((state) => state.singleClient);
  const { loading: singleClientLoading } = singleClient;

  const createClient = useSelector((state) => state.createClient);
  const { loading: createClientLoading } = createClient;

  const updateClient = useSelector((state) => state.updateClient);
  const { loading: updateClientLoading } = updateClient;

  const deleteClient = useSelector((state) => state.deleteClient);
  const { loading: deleteClientLoading } = deleteClient;

  // Supplier
  const listSuppliers = useSelector((state) => state.listSuppliers);
  const { loading: listSuppliersLoading } = listSuppliers;

  const singleSuppliers = useSelector((state) => state.singleSuppliers);
  const { loading: singleSuppliersLoading } = singleSuppliers;

  // const suppliersPost = useSelector((state) => state.suppliersPost);
  // const { loading: suppliersPostLoading } = suppliersPost;

  // const updateSuppliers = useSelector((state) => state.updateSuppliers);
  // const { loading: updateSuppliersLoading } = updateSuppliers;

  // const deleteSuppliers = useSelector((state) => state.deleteSuppliers);
  // const { loading: deleteSuppliersLoading } = deleteSuppliers;

  // Info
  const paymentInfo = useSelector((state) => state.paymentInfo);
  const { loading: paymentInfoLoading } = paymentInfo;

  // Orders
  const myListOrders = useSelector((state) => state.myListOrders);
  const { loading: myListOrdersLoading } = myListOrders;

  const statsOrders = useSelector((state) => state.statsOrders);
  const { loading: statsOrdersLoading } = statsOrders;

  const orderSingle = useSelector((state) => state.orderSingle);
  const { loading: orderSingleLoading } = orderSingle;

  const createOrder = useSelector((state) => state.createOrder);
  const { loading: createOrderLoading } = createOrder;

  const updateOrder = useSelector((state) => state.updateOrder);
  const { loading: updateOrderLoading } = updateOrder;

  const deleteOrder = useSelector((state) => state.deleteOrder);
  const { loading: deleteOrderLoading } = deleteOrder;

  const listIncompleteOrders = useSelector(
    (state) => state.listIncompleteOrders
  );
  const { loading: listIncompleteOrdersLoading } = listIncompleteOrders;

  const listApprovedOrders = useSelector((state) => state.listApprovedOrders);
  const { loading: listApprovedOrdersLoading } = listApprovedOrders;

  const listShippingOrder = useSelector((state) => state.listShippingOrder);
  const { loading: listShippingOrderLoading } = listShippingOrder;

  const listDeliveriesOrders = useSelector(
    (state) => state.listDeliveriesOrders
  );
  const { loading: listDeliveriesOrdersLoading } = listDeliveriesOrders;

  // Products
  const listProducts = useSelector((state) => state.listProducts);
  const { loading: listProductsLoading } = listProducts;

  const statsProducts = useSelector((state) => state.statsProducts);
  const { loading: statsProductsLoading } = statsProducts;

  const searchProducts = useSelector((state) => state.searchProducts);
  const { loading: searchProductsLoading } = searchProducts;

  const singleProduct = useSelector((state) => state.singleProduct);
  const { loading: singleProductLoading } = singleProduct;

  const listProductsPerService = useSelector(
    (state) => state.listProductsPerService
  );
  const { loading: listProductsPerServiceLoading } = listProductsPerService;

  // Product Requirements
  const listProductRequirements = useSelector(
    (state) => state.listProductRequirements
  );
  const { loading: listProductRequirementsLoading } = listProductRequirements;

  const singleProductRequirements = useSelector(
    (state) => state.singleProductRequirements
  );
  const { loading: singleProductRequirementsLoading } =
    singleProductRequirements;

  // const productRequirementCreate = useSelector(
  //   (state) => state.productRequirementCreate
  // );
  // const { loading: productRequirementCreateLoading } = productRequirementCreate;

  // const productRequirementUpdate = useSelector(
  //   (state) => state.productRequirementUpdate
  // );
  // const { loading: productRequirementUpdateLoading } = productRequirementUpdate;

  // const productRequirementDelete = useSelector(
  //   (state) => state.productRequirementDelete
  // );
  // const { loading: productRequirementDeleteLoading } = productRequirementDelete;

  // Transactions
  const myListTransactions = useSelector((state) => state.myListTransactions);
  const { loading: myListTransactionsLoading } = myListTransactions;

  const statsTransactions = useSelector((state) => state.statsTransactions);
  const { loading: statsTransactionsLoading } = statsTransactions;

  const incompleteTransactions = useSelector(
    (state) => state.incompleteTransactions
  );
  const { loading: incompleteTransactionsLoading } = incompleteTransactions;

  const orderTransaction = useSelector((state) => state.orderTransaction);
  const { loading: orderTransactionLoading } = orderTransaction;

  const singleTransaction = useSelector((state) => state.singleTransaction);
  const { loading: singleTransactionLoading } = singleTransaction;

  const createTransaction = useSelector((state) => state.createTransaction);
  const { loading: createTransactionLoading } = createTransaction;

  const updateTransaction = useSelector((state) => state.updateTransaction);
  const { loading: updateTransactionLoading } = updateTransaction;

  const deleteTransaction = useSelector((state) => state.deleteTransaction);
  const { loading: deleteTransactionLoading } = deleteTransaction;

  // User / Admin
  const listUsers = useSelector((state) => state.listUsers);
  const { loading: listUsersLoading } = listUsers;

  const singleUser = useSelector((state) => state.singleUser);
  const { loading: singleUserLoading } = singleUser;

  const createUser = useSelector((state) => state.createUser);
  const { loading: createUserLoading } = createUser;

  const updateUser = useSelector((state) => state.updateUser);
  const { loading: updateUserLoading } = updateUser;

  const deleteUser = useSelector((state) => state.deleteUser);
  const { loading: deleteUserLoading } = deleteUser;

  // Supplier Stats
  const overallSupplierStats = useSelector(
    (state) => state.overallSupplierStats
  );
  const { loading: supplierStatsOverallLoading } = overallSupplierStats;

  const statsByAge = useSelector((state) => state.statsByAge);
  const { loading: ageStatsLoading } = statsByAge;

  const statsClientsJoinedPerMonth = useSelector(
    (state) => state.statsClientsJoinedPerMonth
  );
  const { loading: clientsJoinedPerMonthLoading } = statsClientsJoinedPerMonth;

  return (
    <Box sx={{ width: "100%" }}>
      {listAddonsLoading ||
      singleAddonsLoading ||
      listServicesLoading ||
      myListComplaintLoading ||
      singleComplaintLoading ||
      pendingComplaintsLoading ||
      postComplaintLoading ||
      updateComplaintsLoading ||
      deleteComplaintsLoading ||
      listComplaintRequirementsLoading ||
      singleComplaintRequirementsLoading ||
      listClientsLoading ||
      singleClientLoading ||
      createClientLoading ||
      updateClientLoading ||
      deleteClientLoading ||
      listSuppliersLoading ||
      singleSuppliersLoading ||
      paymentInfoLoading ||
      myListOrdersLoading ||
      statsOrdersLoading ||
      orderSingleLoading ||
      createOrderLoading ||
      updateOrderLoading ||
      deleteOrderLoading ||
      listIncompleteOrdersLoading ||
      listApprovedOrdersLoading ||
      listShippingOrderLoading ||
      listDeliveriesOrdersLoading ||
      listProductsLoading ||
      statsProductsLoading ||
      searchProductsLoading ||
      singleProductLoading ||
      listProductsPerServiceLoading ||
      listProductRequirementsLoading ||
      singleProductRequirementsLoading ||
      myListTransactionsLoading ||
      statsTransactionsLoading ||
      incompleteTransactionsLoading ||
      orderTransactionLoading ||
      singleTransactionLoading ||
      createTransactionLoading ||
      updateTransactionLoading ||
      deleteTransactionLoading ||
      listUsersLoading ||
      singleUserLoading ||
      createUserLoading ||
      updateUserLoading ||
      deleteUserLoading ||
      supplierStatsOverallLoading ||
      ageStatsLoading ||
      clientsJoinedPerMonthLoading ? (
        <BorderLinearProgress variant="indeterminate" />
      ) : null}
    </Box>
  );
}

export default Loading;
