export const MY_COMPLAINT_LIST_REQUEST = "MY_COMPLAINT_LIST_REQUEST";
export const MY_COMPLAINT_LIST_SUCCESS = "MY_COMPLAINT_LIST_SUCCESS";
export const MY_COMPLAINT_LIST_FAIL = "MY_COMPLAINT_LIST_FAIL";
export const MY_COMPLAINT_LIST_RESET = "MY_COMPLAINT_LIST_RESET";

export const COMPLAINT_STATS_REQUEST = "COMPLAINT_STATS_REQUEST";
export const COMPLAINT_STATS_SUCCESS = "COMPLAINT_STATS_SUCCESS";
export const COMPLAINT_STATS_FAIL = "COMPLAINT_STATS_FAIL";
export const COMPLAINT_STATS_RESET = "COMPLAINT_STATS_RESET";

export const COMPLAINT_SINGLE_REQUEST = "COMPLAINT_SINGLE_REQUEST";
export const COMPLAINT_SINGLE_SUCCESS = "COMPLAINT_SINGLE_SUCCESS";
export const COMPLAINT_SINGLE_FAIL = "COMPLAINT_SINGLE_FAIL";
export const COMPLAINT_SINGLE_RESET = "COMPLAINT_SINGLE_RESET";

export const COMPLAINT_PENDING_REQUEST = "COMPLAINT_PENDING_REQUEST";
export const COMPLAINT_PENDING_SUCCESS = "COMPLAINT_PENDING_SUCCESS";
export const COMPLAINT_PENDING_FAIL = "COMPLAINT_PENDING_FAIL";
export const COMPLAINT_PENDING_RESET = "COMPLAINT_PENDING_RESET";

export const COMPLAINT_POST_REQUEST = "COMPLAINT_POST_REQUEST";
export const COMPLAINT_POST_SUCCESS = "COMPLAINT_POST_SUCCESS";
export const COMPLAINT_POST_FAIL = "COMPLAINT_POST_FAIL";
export const COMPLAINT_POST_RESET = "COMPLAINT_POST_RESET";

export const COMPLAINT_UPDATE_REQUEST = "COMPLAINT_UPDATE_REQUEST";
export const COMPLAINT_UPDATE_SUCCESS = "COMPLAINT_UPDATE_SUCCESS";
export const COMPLAINT_UPDATE_FAIL = "COMPLAINT_UPDATE_FAIL";
export const COMPLAINT_UPDATE_RESET = "COMPLAINT_UPDATE_RESET";

export const COMPLAINT_DELETE_REQUEST = "COMPLAINT_DELETE_REQUEST";
export const COMPLAINT_DELETE_SUCCESS = "COMPLAINT_DELETE_SUCCESS";
export const COMPLAINT_DELETE_FAIL = "COMPLAINT_DELETE_FAIL";
export const COMPLAINT_DELETE_RESET = "COMPLAINT_DELETE_RESET";
