import {
  ADDON_LIST_REQUEST,
  ADDON_LIST_SUCCESS,
  ADDON_LIST_FAIL,
  ADDON_LIST_RESET,
  ADDON_SINGLE_REQUEST,
  ADDON_SINGLE_SUCCESS,
  ADDON_SINGLE_FAIL,
  ADDON_SINGLE_RESET,
} from "constants/addonConstants";

export const listAddonsReducer = (state = { addonsList: [] }, action) => {
  switch (action.type) {
    case ADDON_LIST_REQUEST:
      return { loading: true };
    case ADDON_LIST_SUCCESS:
      return { loading: false, addonsList: action.payload };
    case ADDON_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ADDON_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const singleAddonsReducer = (state = { addonSingle: {} }, action) => {
  switch (action.type) {
    case ADDON_SINGLE_REQUEST:
      return { loading: true };
    case ADDON_SINGLE_SUCCESS:
      return { loading: false, addonSingle: action.payload };
    case ADDON_SINGLE_FAIL:
      return { loading: false, error: action.payload };
    case ADDON_SINGLE_RESET:
      return [];
    default:
      return state;
  }
};
