import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_RESET,
  PRODUCT_STATS_REQUEST,
  PRODUCT_STATS_SUCCESS,
  PRODUCT_STATS_FAIL,
  PRODUCT_STATS_RESET,
  PRODUCT_SEARCH_REQUEST,
  PRODUCT_SEARCH_SUCCESS,
  PRODUCT_SEARCH_FAIL,
  PRODUCT_SEARCH_RESET,
  PRODUCT_SINGLE_REQUEST,
  PRODUCT_SINGLE_SUCCESS,
  PRODUCT_SINGLE_FAIL,
  PRODUCT_SINGLE_RESET,
  PRODUCT_PER_SERVICE_REQUEST,
  PRODUCT_PER_SERVICE_SUCCESS,
  PRODUCT_PER_SERVICE_FAIL,
  PRODUCT_PER_SERVICE_RESET,
} from "constants/productConstants";

export const listProductsReducer = (state = { productsList: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_LIST_SUCCESS:
      return { loading: false, productsList: action.payload, success: true };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const statsProductsReducer = (state = { productsStats: [] }, action) => {
  switch (action.type) {
    case PRODUCT_STATS_REQUEST:
      return { loading: true };
    case PRODUCT_STATS_SUCCESS:
      return { loading: false, productsStats: action.payload, success: true };
    case PRODUCT_STATS_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_STATS_RESET:
      return { productsStats: [] };
    default:
      return state;
  }
};

export const searchProductsReducer = (
  state = { productsSearch: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_SEARCH_REQUEST:
      return { loading: true };
    case PRODUCT_SEARCH_SUCCESS:
      return { loading: false, productsSearch: action.payload, success: true };
    case PRODUCT_SEARCH_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_SEARCH_RESET:
      return [];
    default:
      return state;
  }
};

export const singleProductReducer = (state = { productSingle: {} }, action) => {
  switch (action.type) {
    case PRODUCT_SINGLE_REQUEST:
      return { loading: true };
    case PRODUCT_SINGLE_SUCCESS:
      return { loading: false, productSingle: action.payload, success: true };
    case PRODUCT_SINGLE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_SINGLE_RESET:
      return { productSingle: {} };
    default:
      return state;
  }
};

export const listProductsPerServiceReducer = (
  state = { productsListPerService: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_PER_SERVICE_REQUEST:
      return { loading: true };
    case PRODUCT_PER_SERVICE_SUCCESS:
      return {
        loading: false,
        productsListPerService: action.payload,
        success: true,
      };
    case PRODUCT_PER_SERVICE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_PER_SERVICE_RESET:
      return [];
    default:
      return state;
  }
};
