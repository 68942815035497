import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Modal, Box, Button } from "@mui/material";
import CookieConsent, { Cookies } from "react-cookie-consent";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function MKCookieBanner({ content }) {
  // const { brand, socials, menus, copyright } = content;

  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <MKBox>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
    </MKBox>
  );
}

MKCookieBanner.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  ).isRequired,
};

export default MKCookieBanner;
