import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Material Dashboard 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { getMyTransactionList } from "actions/transactionActions";
import DataTable from "examples/Tables/DataTable";
import MKBadge from "components/MKBadge";
import moment from "moment";

import team3 from "assets/images/team-3.jpg";

function TransactionDetails({ title }) {
  const dispatch = useDispatch();

  const myListTransactions = useSelector((state) => state.myListTransactions);
  const { myTransactionsList } = myListTransactions;

  useEffect(() => {
    dispatch(getMyTransactionList());
  }, [dispatch]);

  const Supplier = ({ image, name, date }) => (
    <MKBox display="flex" alignItems="center" lineHeight={1}>
      <MKBox display="flex" flexDirection="column">
        <MKTypography
          variant="button"
          fontWeight="medium"
          ml={1}
          mb={1}
          lineHeight={1}
          sx={{
            color: ({ palette: { text } }) => text.darkAccent,
          }}
        >
          {name}
        </MKTypography>
        <MKTypography
          variant="button"
          fontWeight="normal"
          sx={{
            color: ({ palette: { text } }) => text.darkAccent,
          }}
          ml={1}
          lineHeight={1}
        >
          {moment(date).fromNow()}
        </MKTypography>
      </MKBox>
    </MKBox>
  );

  function renderRows(data) {
    const values = [];
    data?.forEach((item, index) => {
      const newElement = {
        transactionCode: (
          <Link to={`/transactions/${item?._id}`}>
            <Supplier
              key={index}
              image={team3}
              name={item?.transactionCode}
              date={item?.updated_at}
            />
          </Link>
        ),
        client: (
          <Link to={`/transactions/${item?._id}`}>
            <MKBox display="flex" flexDirection="column">
              <MKTypography
                variant="caption"
                sx={{
                  color: ({ palette: { text } }) => text.darkAccent,
                }}
                fontWeight="medium"
                mb={1}
              >
                {item?.client?.name}
              </MKTypography>
              <MKTypography
                variant="caption"
                sx={{
                  color: ({ palette: { text } }) => text.darkAccent,
                }}
                fontWeight="normal"
              >
                {item?.client?.email}
              </MKTypography>
            </MKBox>
          </Link>
        ),
        order: (
          <Link to={`/orders/${item?.order?._id}`}>
            <MKTypography
              variant="caption"
              sx={{
                color: ({ palette: { text } }) => text.darkAccent,
              }}
              fontWeight="medium"
            >
              {item?.order?._id}
            </MKTypography>
          </Link>
        ),
        amount: (
          <MKBox display="flex" flexDirection="column">
            <MKTypography
              variant="caption"
              sx={{
                color: ({ palette: { text } }) => text.darkAccent,
              }}
              fontWeight="medium"
              mb={1}
            >
              {item?.amount}
            </MKTypography>
            <MKTypography
              variant="caption"
              sx={{
                color: ({ palette: { text } }) => text.darkAccent,
              }}
              fontWeight="normal"
            >
              {item?.transactionMethod}
            </MKTypography>
          </MKBox>
        ),
        status: (
          <MKBox width="8rem" textAlign="center">
            <MKBox ml={-1}>
              <MKBadge
                badgeContent={item?.status}
                color="primary"
                variant="gradient"
                size="sm"
              />
            </MKBox>
          </MKBox>
        ),
      };

      values.push(newElement);
    });
    return values;
  }

  return (
    <MKBox>
      <DataTable
        table={{
          columns: [
            {
              Header: "Transaction Code",
              accessor: "transactionCode",
              width: "20%",
              align: "left",
            },
            {
              Header: "client",
              accessor: "client",
              width: "20%",
              align: "left",
            },
            {
              Header: "order",
              accessor: "order",
              width: "20%",
              align: "left",
            },
            {
              Header: "amount",
              accessor: "amount",
              width: "20%",
              align: "center",
            },

            {
              Header: "status",
              accessor: "status",
              width: "20%",
              align: "center",
            },
          ],

          rows: renderRows(myTransactionsList),
        }}
        showTotalEntries={false}
        isSorted={false}
        noEndBorder
        entriesPerPage={false}
      />
    </MKBox>
  );
}

export default TransactionDetails;
