import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredTechCard from "examples/Cards/BlogCards/CenteredTechCard";

import aboutImage from "assets/images/jack.jpeg";
import tilapiaImage from "assets/images/tilapia.jpeg";

function Information() {
  const theme = useTheme();
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.between("xxs", "xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXXLargeScreen = useMediaQuery(theme.breakpoints.between("xl", "xxl"));
  const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <MKBox component="section" py={1}>
      <Container sx={{ my: 10 }}>
        <Grid container spacing={10}>
          <Grid item xxs={12} xs={12} sm={12} md={6}>
            <CenteredTechCard
              image={tilapiaImage}
              title="tech Join the Movement"
              description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
              action={{
                type: "internal",
                route: "#",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>
          <Grid item xxs={12} xs={12} sm={12} md={6}>
            <MKBox mb={5} pt={5}>
              <DefaultInfoCard
                icon={
                  !isXXSmallScreen &&
                  !isXSmallScreen &&
                  !isSmallScreen &&
                  !isMediumScreen &&
                  "water_drop"
                }
                title="Nourishing"
                direction="right"
                description="We choose the word 'nourish' because we aim to provide both a source of food protein close to our operations, like here in Kenya, whilst also supplying internationally the highest quality protein components - that meet the demands of being sustainable and organic - for products like cosmetics that nourish the skin and vitamins that nourish the body. Our aquaculture fish species are specially chosen to provide the component proteins rich in these essential amino acids."
                description2="The rest of our purpose speaks to the need to find new, sustainable ways to nourish our planet that are clean, safe, and healthy. It is crucial for us that these efforts benefit not just the present but also future generations."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>

      {!isXXSmallScreen &&
      !isXSmallScreen &&
      !isSmallScreen &&
      !isMediumScreen ? (
        <Container sx={{ my: 10 }}>
          <Grid container spacing={10} justifyContent="flex-start">
            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <MKBox mb={5} pt={5}>
                <DefaultInfoCard
                  icon={
                    !isXXSmallScreen &&
                    !isXSmallScreen &&
                    !isSmallScreen &&
                    !isMediumScreen &&
                    "route"
                  }
                  title="Our Journey"
                  direction="left"
                  description="Our journey began as a need to solve another problem and then turned into a simple idea to turn aquatic systems into sustainable food production facilities. Today we are working towards a shared goal of global sustainability. Discover our story, meet our dedicated team, and learn about our strategic locations and how they contribute to our mission."
                  description2="We are currently commercializing our technology with a plan for the first full commercialization in 2025. We are already providing premium markets in Kenya."
                />
              </MKBox>
            </Grid>

            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <CenteredTechCard
                image={aboutImage}
                title="tech Join the Movement"
                description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                action={{
                  type: "internal",
                  route: "#",
                  color: "info",
                  label: "find out more",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Container sx={{ mt: 10 }}>
          <Grid container spacing={10} justifyContent="flex-start">
            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <CenteredTechCard
                image={aboutImage}
                title="tech Join the Movement"
                description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                action={{
                  type: "internal",
                  route: "#",
                  color: "info",
                  label: "find out more",
                }}
              />
            </Grid>
            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <MKBox mb={5} pt={5}>
                <DefaultInfoCard
                  icon={
                    !isXXSmallScreen &&
                    !isXSmallScreen &&
                    !isSmallScreen &&
                    !isMediumScreen &&
                    "route"
                  }
                  title="Our Journey"
                  direction="right"
                  description="Our journey began as a need to solve another problem and then turned into a simple idea to turn aquatic systems into sustainable food production facilities. Today we are working towards a shared goal of global sustainability. Discover our story, meet our dedicated team, and learn about our strategic locations and how they contribute to our mission."
                  description2="We are currently commercializing our technology with a plan for the first full commercialization in 2025. We are already providing premium markets in Kenya."
                />
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      )}
    </MKBox>
  );
}

export default Information;
