// react-router components
import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import fallbackLogoImage from "assets/images/fallback-logo-image.jpg";

function trimTitleWords(phrase) {
  const words = phrase.split(" ");
  if (words.length > 15) {
    return words.slice(0, 15).join(" ") + "...";
  } else {
    return phrase;
  }
}

function trimContentsWords(phrase) {
  const words = phrase.split(" ");
  if (words.length > 50) {
    return words.slice(0, 50).join(" ") + "...";
  } else {
    return phrase;
  }
}

function TransparentBlogCard({ image, title, description, action }) {
  const theme = useTheme();
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.between("xxs", "xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXXLargeScreen = useMediaQuery(theme.breakpoints.between("xl", "xxl"));
  const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

  const cardActionStyles = {
    display: "flex",
    alignItems: "center",

    "& .material-icons, .material-icons-round,": {
      transform: `translateX(2px)`,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
      {
        transform: `translateX(6px)`,
      },
  };

  const imageTemplate = (
    <MKBox position="relative" borderRadius="lg">
      <MKBox
        component="img"
        src={image || fallbackLogoImage}
        alt={title}
        borderRadius="lg"
        shadow="md"
        width="100%"
        // maxWidth="25vw"
        maxHeight="40vh"
        minHeight="40vh"
        position="relative"
        zIndex={1}
        sx={{
          objectFit: "cover",
        }}
      />
      <MKBox
        borderRadius="lg"
        shadow="md"
        width="100%"
        height="100%"
        // maxWidth="25vw"
        maxHeight="40vh"
        minHeight="40vh"
        position="absolute"
        left={0}
        top={0}
        sx={{
          backgroundImage: `url(${image || fallbackLogoImage})`,
          transform: "scale(0.94)",
          filter: "blur(12px)",
          backgroundSize: "cover",
        }}
      />
    </MKBox>
  );

  return (
    <Card
      sx={{
        background: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      {action.type === "internal" ? (
        <Link to={action.route}>{imageTemplate}</Link>
      ) : (
        <MuiLink href={action.route} target="_blank" rel="noreferrer">
          {imageTemplate}
        </MuiLink>
      )}
      <MKBox pt={2} pb={3}>
        {action.type === "internal" ? (
          <Link to={action.route} sx={cardActionStyles}>
            <MKTypography
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "h5"
                  : "h6"
              }
              gutterBottom
              sx={{
                color: ({ palette: { text } }) => text.lightAccent,
              }}
            >
              {trimTitleWords(title)}
            </MKTypography>
          </Link>
        ) : (
          <MuiLink
            href={action.route}
            target="_blank"
            rel="noreferrer"
            sx={cardActionStyles}
          >
            <MKTypography
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "h5"
                  : "h6"
              }
              sx={{
                color: ({ palette: { text } }) => text.lightAccent,
              }}
              gutterBottom
            >
              {trimTitleWords(title)}
            </MKTypography>
          </MuiLink>
        )}
        <MKTypography
          variant={
            !isXXSmallScreen &&
            !isXSmallScreen &&
            !isSmallScreen &&
            !isMediumScreen
              ? "body1"
              : "body2"
          }
          component="p"
          sx={{
            color: ({ palette: { text } }) => text.light,
          }}
          my={3}
        >
          {trimContentsWords(description)}
        </MKTypography>
        {action.type === "internal" ? (
          <MKTypography
            component={Link}
            to={action.route}
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="regular"
            textTransform="capitalize"
            sx={
              (cardActionStyles,
              { color: ({ palette: { text } }) => text.light })
            }
          >
            {action.label}
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        ) : (
          <MKTypography
            component={MuiLink}
            href={action.route}
            target="_blank"
            rel="noreferrer"
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="regular"
            textTransform="capitalize"
            sx={
              (cardActionStyles,
              { color: ({ palette: { text } }) => text.light })
            }
          >
            {action.label}
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        )}
      </MKBox>
    </Card>
  );
}

// Typechecking props for the TransparentBlogCard
TransparentBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "inherit",
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "text",
    ]).isRequired,
  }).isRequired,
};

export default TransparentBlogCard;
