import HttpService from "./htttp.service";

class TransactionsService {
  // TransactionsEndpoint = process.env.API_URL;

  getMyTransactions = async () => {
    const getMyTransactions = "fe/transactions";
    return await HttpService.get(getMyTransactions);
  };

  getTransactionsStats = async () => {
    const getTransactionsStats = "fe/transactions/stats";
    return await HttpService.get(getTransactionsStats);
  };

  getIncompleteTransactions = async () => {
    const getIncompleteTransactions = "fe/transactions/incomplete";
    return await HttpService.get(getIncompleteTransactions);
  };

  getSingleTransaction = async (id) => {
    const getSingleTransaction = `fe/transactions/${id}`;
    return await HttpService.get(getSingleTransaction);
  };

  createNewTransaction = async (transaction) => {
    const createNewTransaction = "fe/transactions/new";
    return await HttpService.post(createNewTransaction, transaction);
  };

  createMpesaTransaction = async (transaction) => {
    const createMpesaTransaction = "fe/transactions/mpesa";
    return await HttpService.post(createMpesaTransaction, transaction);
  };

  createCreditTransaction = async (transaction) => {
    const createCreditTransaction = "fe/transactions/credit";
    return await HttpService.put(createCreditTransaction, transaction);
  };

  createBankTransferTransaction = async (transaction) => {
    const createBankTransferTransaction = "fe/transactions/bank_transfer";
    return await HttpService.put(createBankTransferTransaction, transaction);
  };

  createChequeTransaction = async (transaction) => {
    const createChequeTransaction = "fe/transactions/cheque";
    return await HttpService.put(createChequeTransaction, transaction);
  };

  editTransaction = async (id, transaction) => {
    const editTransaction = `fe/transactions/${id}`;
    return await HttpService.put(editTransaction, transaction);
  };
}

export default new TransactionsService();
