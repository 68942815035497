export const COMPLAINT_REQUIREMENT_LIST_REQUEST =
  "COMPLAINT_REQUIREMENT_LIST_REQUEST";
export const COMPLAINT_REQUIREMENT_LIST_SUCCESS =
  "COMPLAINT_REQUIREMENT_LIST_SUCCESS";
export const COMPLAINT_REQUIREMENT_LIST_FAIL =
  "COMPLAINT_REQUIREMENT_LIST_FAIL";
export const COMPLAINT_REQUIREMENT_LIST_RESET =
  "COMPLAINT_REQUIREMENT_LIST_RESET";

export const COMPLAINT_REQUIREMENT_SINGLE_REQUEST =
  "COMPLAINT_REQUIREMENT_SINGLE_REQUEST";
export const COMPLAINT_REQUIREMENT_SINGLE_SUCCESS =
  "COMPLAINT_REQUIREMENT_SINGLE_SUCCESS";
export const COMPLAINT_REQUIREMENT_SINGLE_FAIL =
  "COMPLAINT_REQUIREMENT_SINGLE_FAIL";
export const COMPLAINT_REQUIREMENT_SINGLE_RESET =
  "COMPLAINT_REQUIREMENT_SINGLE_RESET";
