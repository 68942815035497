import {
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_LIST_FAIL,
  SUPPLIER_LIST_RESET,
  SUPPLIER_SINGLE_REQUEST,
  SUPPLIER_SINGLE_SUCCESS,
  SUPPLIER_SINGLE_FAIL,
  SUPPLIER_SINGLE_RESET,
} from "constants/supplierConstants";

export const listSuppliersReducer = (state = { suppliersList: [] }, action) => {
  switch (action.type) {
    case SUPPLIER_LIST_REQUEST:
      return { loading: true };
    case SUPPLIER_LIST_SUCCESS:
      return { loading: false, suppliersList: action.payload };
    case SUPPLIER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case SUPPLIER_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const singleSuppliersReducer = (
  state = { suppliersSingle: [] },
  action
) => {
  switch (action.type) {
    case SUPPLIER_SINGLE_REQUEST:
      return { loading: true };
    case SUPPLIER_SINGLE_SUCCESS:
      return { loading: false, suppliersSingle: action.payload };
    case SUPPLIER_SINGLE_FAIL:
      return { loading: false, error: action.payload };
    case SUPPLIER_SINGLE_RESET:
      return [];
    default:
      return state;
  }
};
