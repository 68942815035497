// @mui material components
import Icon from "@mui/material/Icon";

import Details from "layouts/pages/details";
import ProblemSolution from "layouts/pages/problem-solution";
import SustainabilityCircularity from "layouts/pages/sustainability-circularity";
import ProteinPremiumMarkets from "layouts/pages/protein-premium-markets";
import Profile from "layouts/pages/profile";
import Dashboard from "layouts/pages/dashboard";

import AboutUs from "layouts/pages/landing-pages/about-us";
import Technology from "layouts/pages/landing-pages/technology";
import Products from "layouts/pages/landing-pages/products";
import NewsAndEvents from "layouts/pages/landing-pages/news-and-events";
import Ecommerce from "layouts/pages/landing-pages/e-commerce";

// Auth
import Login from "auth/login";
import Register from "auth/register";
import ForgotPassword from "auth/forgot-password";
import ResetPassword from "auth/reset-password";

const routes = [
  {
    name: "Technology",
    icon: <Icon>biotech</Icon>,
    route: "/technology",
    component: <Technology />,
  },
  {
    name: "Products",
    icon: <Icon>category</Icon>,
    route: "/products",
    component: <Products />,
  },
  {
    name: "About Us",
    icon: <Icon>info</Icon>,
    route: "/about-us",
    component: <AboutUs />,
  },
  {
    name: "News",
    icon: <Icon>newspaper</Icon>,
    route: "/news-and-events",
    component: <NewsAndEvents />,
  },
  {
    name: "Ecommerce",
    icon: <Icon>shopping_cart</Icon>,
    route: "/e-commerce",
    component: <Ecommerce />,
  },
];

const routesToExcludeFromNavbar = [
  {
    name: "The Problem and our Solution",
    description: "All about The Problem and our Solution",
    route: "/problem-solution",
    component: <ProblemSolution />,
  },
  {
    name: "Sustainability and Circularity",
    description: "All about Sustainability and Circularity",
    route: "/sustainability-circularity",
    component: <SustainabilityCircularity />,
  },
  {
    name: "Protein for Premium Markets",
    description: "All about Protein for premium markets",
    route: "/protein-premium-markets",
    component: <ProteinPremiumMarkets />,
  },
  {
    name: "Plan Details",
    description: "All about Plan Details",
    route: "/details/:id",
    component: <Details />,
  },
  {
    name: "Profile",
    description: "All about Profile",
    route: "/profile",
    component: <Profile />,
  },
  {
    name: "Dashboard",
    description: "Your Dashboard",
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "auth",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <Login />,
  },
  {
    type: "auth",
    name: "Register",
    key: "register",
    icon: <Icon fontSize="small">register</Icon>,
    route: "/auth/register",
    component: <Register />,
  },
  {
    type: "auth",
    name: "Forgot Password",
    key: "forgot-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/auth/forgot-password",
    component: <ForgotPassword />,
  },
  {
    type: "auth",
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/auth/reset-password",
    component: <ResetPassword />,
  },
];

export { routes, routesToExcludeFromNavbar };
