/* eslint-disable react/prop-types */
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

function ProductCard({ items }) {
  return (
    <>
      <Container>
        <Grid container spacing={3} width="100%">
          {items?.length === 0 ? (
            <>
              <MKBox
                minHeight="35vh"
                width="100%"
                sx={{
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Container>
                  <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    sx={{ mx: 5, textAlign: "center" }}
                  >
                    <MKTypography variant="h3" sx={{ color: "#adb5bd" }}>
                      No Products at the moment,
                    </MKTypography>
                    <MKTypography variant="h2" sx={{ color: "#adb5bd" }}>
                      Keep an Eye!
                    </MKTypography>
                  </Grid>
                </Container>
              </MKBox>
            </>
          ) : (
            <>
              {items?.map((item, index) => (
                <Grid key={index} item xs={12} md={12} lg={12}>
                  <MKBox mb={1} sx={{ width: "100%" }}>
                    <HorizontalTeamCard
                      _id={item?._id}
                      image={item?.supplier?.supplierLogo}
                      productImages={item?.productImages}
                      name={item?.productName}
                      supplier={item?.supplier?.supplierName}
                      position={{ color: "info", label: item?.productNumber }}
                      premiumAmount={item?.amount}
                      description={item?.description}
                      includedItems={item?.includedItems}
                      addons={item?.addons}
                      adminNotes={item?.adminNotes}
                    />
                  </MKBox>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default ProductCard;
