export const MY_TRANSACTION_LIST_REQUEST = "MY_TRANSACTION_LIST_REQUEST";
export const MY_TRANSACTION_LIST_SUCCESS = "MY_TRANSACTION_LIST_SUCCESS";
export const MY_TRANSACTION_LIST_FAIL = "MY_TRANSACTION_LIST_FAIL";
export const MY_TRANSACTION_LIST_RESET = "MY_TRANSACTION_LIST_RESET";

export const TRANSACTION_STATS_REQUEST = "TRANSACTION_STATS_REQUEST";
export const TRANSACTION_STATS_SUCCESS = "TRANSACTION_STATS_SUCCESS";
export const TRANSACTION_STATS_FAIL = "TRANSACTION_STATS_FAIL";
export const TRANSACTION_STATS_RESET = "TRANSACTION_STATS_RESET";

export const TRANSACTION_INCOMPLETE_REQUEST = "TRANSACTION_INCOMPLETE_REQUEST";
export const TRANSACTION_INCOMPLETE_SUCCESS = "TRANSACTION_INCOMPLETE_SUCCESS";
export const TRANSACTION_INCOMPLETE_FAIL = "TRANSACTION_INCOMPLETE_FAIL";
export const TRANSACTION_INCOMPLETE_RESET = "TRANSACTION_INCOMPLETE_RESET";

export const ORDER_TRANSACTION_REQUEST = "ORDER_TRANSACTION_REQUEST";
export const ORDER_TRANSACTION_SUCCESS = "ORDER_TRANSACTION_SUCCESS";
export const ORDER_TRANSACTION_FAIL = "ORDER_TRANSACTION_FAIL";
export const ORDER_TRANSACTION_RESET = "ORDER_TRANSACTION_RESET";

export const TRANSACTION_SINGLE_REQUEST = "TRANSACTION_SINGLE_REQUEST";
export const TRANSACTION_SINGLE_SUCCESS = "TRANSACTION_SINGLE_SUCCESS";
export const TRANSACTION_SINGLE_FAIL = "TRANSACTION_SINGLE_FAIL";
export const TRANSACTION_SINGLE_RESET = "TRANSACTION_SINGLE_RESET";

export const CREATE_TRANSACTION_REQUEST = "CREATE_TRANSACTION_REQUEST";
export const CREATE_TRANSACTION_SUCCESS = "CREATE_TRANSACTION_SUCCESS";
export const CREATE_TRANSACTION_FAIL = "CREATE_TRANSACTION_FAIL";
export const CREATE_TRANSACTION_RESET = "CREATE_TRANSACTION_RESET";

export const MPESA_TRANSACTION_REQUEST = "MPESA_TRANSACTION_REQUEST";
export const MPESA_TRANSACTION_SUCCESS = "MPESA_TRANSACTION_SUCCESS";
export const MPESA_TRANSACTION_FAIL = "MPESA_TRANSACTION_FAIL";
export const MPESA_TRANSACTION_RESET = "MPESA_TRANSACTION_RESET";

export const CREDIT_TRANSACTION_REQUEST = "CREDIT_TRANSACTION_REQUEST";
export const CREDIT_TRANSACTION_SUCCESS = "CREDIT_TRANSACTION_SUCCESS";
export const CREDIT_TRANSACTION_FAIL = "CREDIT_TRANSACTION_FAIL";
export const CREDIT_TRANSACTION_RESET = "CREDIT_TRANSACTION_RESET";

export const BANK_TRANSFER_TRANSACTION_REQUEST =
  "BANK_TRANSFER_TRANSACTION_REQUEST";
export const BANK_TRANSFER_TRANSACTION_SUCCESS =
  "BANK_TRANSFER_TRANSACTION_SUCCESS";
export const BANK_TRANSFER_TRANSACTION_FAIL = "BANK_TRANSFER_TRANSACTION_FAIL";
export const BANK_TRANSFER_TRANSACTION_RESET =
  "BANK_TRANSFER_TRANSACTION_RESET";

export const CHEQUE_TRANSACTION_REQUEST = "CHEQUE_TRANSACTION_REQUEST";
export const CHEQUE_TRANSACTION_SUCCESS = "CHEQUE_TRANSACTION_SUCCESS";
export const CHEQUE_TRANSACTION_FAIL = "CHEQUE_TRANSACTION_FAIL";
export const CHEQUE_TRANSACTION_RESET = "CHEQUE_TRANSACTION_RESET";

export const UPDATE_TRANSACTION_REQUEST = "UPDATE_TRANSACTION_REQUEST";
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS";
export const UPDATE_TRANSACTION_FAIL = "UPDATE_TRANSACTION_FAIL";
export const UPDATE_TRANSACTION_RESET = "UPDATE_TRANSACTION_RESET";

export const DELETE_TRANSACTION_REQUEST = "DELETE_TRANSACTION_REQUEST";
export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS";
export const DELETE_TRANSACTION_FAIL = "DELETE_TRANSACTION_FAIL";
export const DELETE_TRANSACTION_RESET = "DELETE_TRANSACTION_RESET";
