import { forwardRef, useRef } from "react";
import { useInView } from "framer-motion";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";

// Custom styles for MKSectionHeader
import MKSectionHeaderRoot from "components/MKSectionHeader/MKSectionHeaderRoot";

function SectionAnimation({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  return (
    <MKBox ref={ref}>
      <MKBox
        sx={{
          transform: isInView ? "none" : "translateY(200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.01s",
        }}
      >
        {children}
      </MKBox>
    </MKBox>
  );
}

const MKSectionHeader = forwardRef(
  ({ badgeTitle, title, subTitle, children, ...rest }, ref) => {
    const theme = useTheme();
    const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
    const isXSmallScreen = useMediaQuery(
      theme.breakpoints.between("xxs", "xs")
    );
    const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
    const isXXLargeScreen = useMediaQuery(
      theme.breakpoints.between("xl", "xxl")
    );
    const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

    return (
      <MKSectionHeaderRoot {...rest} ref={ref} ownerState={{}}>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", my: 2, mx: "auto", px: 0.75 }}
        >
          {/* <SectionAnimation> */}
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "h4"
                : "h5"
            }
            fontWeight="bold"
            sx={{
              pb: 2,
              color: ({ palette: { text } }) => text.lightAccent,
            }}
          >
            {title}
          </MKTypography>
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "h4"
                : "h5"
            }
            fontWeight="bold"
            sx={{
              pb: 2,
              color: ({ palette: { text } }) => text.light,
            }}
          >
            {badgeTitle}
          </MKTypography>
          {/* <MKBadge
              variant="contained"
              color="info"
              badgeContent={badgeTitle}
              container
              sx={{ mb: 2 }}
            /> */}
          {/* </SectionAnimation> */}
          {subTitle && (
            <MKTypography
              variant={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen
                  ? "body1"
                  : "body2"
              }
              fontWeight="normal"
              textAlign="justify"
              sx={{
                color: ({ palette: { text } }) => text.light,
              }}
            >
              {subTitle}
            </MKTypography>
          )}
        </Grid>
      </MKSectionHeaderRoot>
    );
  }
);

// Setting default values for the props of MKSectionHeader
MKSectionHeader.defaultProps = {
  badgeTitle: "",
  title: "",
  subTitle: "",
};

// Typechecking props for the MKSectionHeader
MKSectionHeader.propTypes = {
  badgeTitle: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default MKSectionHeader;
