import HttpService from "./htttp.service";

class OrdersService {
  // OrdersEndpoint = process.env.API_URL;

  getMyOrders = async () => {
    const getMyOrders = "fe/my_orders";
    return await HttpService.get(getMyOrders);
  };

  getOrdersStats = async () => {
    const getOrdersStats = "fe/my_orders/stats";
    return await HttpService.get(getOrdersStats);
  };

  getIncompleteOrders = async () => {
    const getIncompleteOrders = "fe/my_orders/incomplete";
    return await HttpService.get(getIncompleteOrders);
  };

  getSingleOrder = async (id) => {
    const getSingleOrder = `fe/my_orders/${id}`;
    return await HttpService.get(getSingleOrder);
  };

  createNewOrder = async (order) => {
    const createNewOrder = "fe/my_orders/new";
    return await HttpService.post(createNewOrder, order);
  };

  editOrder = async (id, order) => {
    const editOrder = `fe/my_orders/${id}`;
    return await HttpService.put(editOrder, order);
  };
}

export default new OrdersService();
