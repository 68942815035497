import {
  ADMIN_LIST_REQUEST,
  ADMIN_LIST_SUCCESS,
  ADMIN_LIST_FAIL,
  ADMIN_LIST_RESET,
  SINGLE_ADMIN_REQUEST,
  SINGLE_ADMIN_SUCCESS,
  SINGLE_ADMIN_FAIL,
  SINGLE_ADMIN_RESET,
  CREATE_ADMIN_REQUEST,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAIL,
  CREATE_ADMIN_RESET,
  UPDATE_ADMIN_REQUEST,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAIL,
  UPDATE_ADMIN_RESET,
  // DELETE_ADMIN_REQUEST,
  // DELETE_ADMIN_SUCCESS,
  // DELETE_ADMIN_FAIL,
  // DELETE_ADMIN_RESET,
} from "constants/adminConstants";

export const listAdminsReducer = (state = { adminsList: [] }, action) => {
  switch (action.type) {
    case ADMIN_LIST_REQUEST:
      return { loading: true, adminsList: [] };
    case ADMIN_LIST_SUCCESS:
      return { loading: false, adminsList: action.payload };
    case ADMIN_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const createAdminReducer = (state = { newAdmin: {} }, action) => {
  switch (action.type) {
    case CREATE_ADMIN_REQUEST:
      return { loading: true };
    case CREATE_ADMIN_SUCCESS:
      return { loading: false, newAdmin: action.payload, success: true };
    case CREATE_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_ADMIN_RESET:
      return { newAdmin: {} };
    default:
      return state;
  }
};

export const singleAdminReducer = (state = { admin: {} }, action) => {
  switch (action.type) {
    case SINGLE_ADMIN_REQUEST:
      return { loading: true };
    case SINGLE_ADMIN_SUCCESS:
      return { loading: false, admin: action.payload };
    case SINGLE_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case SINGLE_ADMIN_RESET:
      return { admin: {} };
    default:
      return state;
  }
};

export const updateAdminReducer = (state = { updateAdmin: {} }, action) => {
  switch (action.type) {
    case UPDATE_ADMIN_REQUEST:
      return { loading: true };
    case UPDATE_ADMIN_SUCCESS:
      return { loading: false, updateAdmin: action.payload, success: true };
    case UPDATE_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_ADMIN_RESET:
      return { updateAdmin: {} };
    default:
      return state;
  }
};
