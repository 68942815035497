import {
  COMPLAINT_REQUIREMENT_LIST_REQUEST,
  COMPLAINT_REQUIREMENT_LIST_SUCCESS,
  COMPLAINT_REQUIREMENT_LIST_FAIL,
  COMPLAINT_REQUIREMENT_LIST_RESET,
  COMPLAINT_REQUIREMENT_SINGLE_REQUEST,
  COMPLAINT_REQUIREMENT_SINGLE_SUCCESS,
  COMPLAINT_REQUIREMENT_SINGLE_FAIL,
  COMPLAINT_REQUIREMENT_SINGLE_RESET,
} from "constants/complaintRequirementConstants";

export const listComplaintRequirementsReducer = (
  state = { complaintRequirementList: [] },
  action
) => {
  switch (action.type) {
    case COMPLAINT_REQUIREMENT_LIST_REQUEST:
      return { loading: true };
    case COMPLAINT_REQUIREMENT_LIST_SUCCESS:
      return { loading: false, complaintRequirementList: action.payload };
    case COMPLAINT_REQUIREMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case COMPLAINT_REQUIREMENT_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const singleComplaintRequirementsReducer = (
  state = { complaintRequirementSingle: {} },
  action
) => {
  switch (action.type) {
    case COMPLAINT_REQUIREMENT_SINGLE_REQUEST:
      return { loading: true };
    case COMPLAINT_REQUIREMENT_SINGLE_SUCCESS:
      return { loading: false, complaintRequirementSingle: action.payload };
    case COMPLAINT_REQUIREMENT_SINGLE_FAIL:
      return { loading: false, error: action.payload };
    case COMPLAINT_REQUIREMENT_SINGLE_RESET:
      return [];
    default:
      return state;
  }
};
