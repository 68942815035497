// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MKBox from "components/MKBox";

function DataTableBodyCell({ noBorder, align, children }) {
  return (
    <MKBox
      component="td"
      textAlign={align}
      py={1.5}
      px={3}
      sx={({
        palette: { light },
        typography: { size },
        borders: { borderWidth },
      }) => ({
        fontSize: size.sm,
        borderBottom: noBorder
          ? "none"
          : `${borderWidth[1]} solid ${light.main}`,
        backgroundColor: ({ palette: { text }, functions: { rgba } }) =>
          rgba(text.light, 0.9),
        // backdropFilter: "saturate(200%) blur(130px)",
      })}
    >
      <MKBox
        display="inline-block"
        width="max-content"
        // color="text"
        sx={{ verticalAlign: "middle" }}
      >
        {children}
      </MKBox>
    </MKBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
