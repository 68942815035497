import { useEffect, useState, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { keyframes } from "@emotion/react";

// Define the keyframes for the scrolling animation
const scrollKeyframes = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const InfiniteMovingCards = ({
  items,
  direction = "left",
  speed = "fast",
  pauseOnHover = true,
}) => {
  const containerRef = useRef(null);
  const scrollerRef = useRef(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [start, setStart] = useState(false);

  useEffect(() => {
    addAnimation();
  }, []);

  function addAnimation() {
    if (containerRef.current && scrollerRef.current) {
      const scrollerContent = Array.from(scrollerRef.current.children);

      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        if (scrollerRef.current) {
          scrollerRef.current.appendChild(duplicatedItem);
        }
      });

      setStart(true);
    }
  }

  const animationDuration =
    speed === "fast" ? "10s" : speed === "normal" ? "20s" : "40s";

  return (
    <Box
      ref={containerRef}
      sx={{
        position: "relative",
        zIndex: 2,
        width: "90vw",
        overflow: "hidden",
        maskImage:
          "linear-gradient(to right, transparent, white 20%, white 80%, transparent)",
        "&:hover ul": pauseOnHover ? { animationPlayState: "paused" } : {},
      }}
    >
      <List
        ref={scrollerRef}
        sx={{
          display: "flex",
          minWidth: "200%",
          gap: isSmallScreen ? 6 : 4,
          flexWrap: "nowrap",
          animation: `${scrollKeyframes} ${animationDuration} linear infinite`,
        }}
      >
        {items.map((item) => (
          <ListItem
            key={item?.id}
            sx={{
              width: { xs: "15vw", md: "15vw" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "100%",
              p: { xs: 2, md: 4 },
              flexShrink: 0,
            }}
          >
            <Box mt={2} display="flex" alignItems="center">
              <img
                src={item?.icon}
                alt="profile"
                width={isSmallScreen ? 40 : 100}
                height={isSmallScreen ? 40 : 100}
              />
            </Box>
            <Box mt={2} display="flex" alignItems="center">
              <Typography
                variant={isSmallScreen ? "body2" : "h4"}
                color="grey.400"
                sx={{ textAlign: "center" }}
              >
                {item.title}
              </Typography>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default InfiniteMovingCards;
