import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import MKTypography from "components/MKTypography";

const MDImageUpload = ({ title, name = "", valueType = "", onImageChange }) => {
  const hiddenFileInput = React.useRef(null);
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState(valueType);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    setUrl(valueType);
  }, [valueType]);

  const uploadFile = async () => {
    setLoading(true);
    const data = new FormData();
    data.append("file", image);
    data.append(
      "upload_preset",
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
    );
    data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
    data.append("folder", "Cloudinary-React");

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
        {
          method: "POST",
          body: data,
        }
      );
      const res = await response.json();
      setUrl(res.url);
      onImageChange({
        name: name,
        value: res.url,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleImageChangeOld = (event) => {
    setPreview(null);
    setImage(null);
    setUrl("");
    hiddenFileInput.current.click();
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file.size > 8097152) {
      alert("File is too big! Use less than 8MB");
    }
    setImage(file);

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      setPreview(reader.result);
    };
  };

  const handleResetClick = () => {
    setPreview(null);
    setImage(null);
    setUrl("");
  };

  return (
    <div className="h-screen sm:px-8 md:px-16 sm:py-8">
      <div className="container mx-auto max-w-screen-lg h-full">
        <div></div>
        <MDTypography
          variant="h6"
          sx={{
            color: ({ palette: { text } }) => text.black,
          }}
        >
          {title}
        </MDTypography>
        <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
          <input
            id="hidden-input"
            type="file"
            className="hidden"
            onChange={handleImageChange}
            accept="image/*"
          />
        </header>
        <label htmlFor="hidden-input" className="cursor-pointer">
          <div className="mt-2 rounded-sm px-3 py-12 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none">
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={uploadFile}
              size="large"
              sx={{ justifyContent: "space-around" }}
              startIcon={
                <Icon fontSize="large" color="secondary">
                  photo
                </Icon>
              }
              endIcon={
                url ? (
                  <img
                    src={url}
                    srcSet={url}
                    height={70}
                    width={70}
                    alt="preview"
                    loading="lazy"
                    className="w-full"
                  />
                ) : (
                  <Icon fontSize="large" color="secondary">
                    upload_file
                  </Icon>
                )
              }
            >
              <MKTypography
                variant="h6"
                sx={{
                  color: ({ palette: { text } }) => text.black,
                }}
              >
                {url ? "Change Image" : "Upload Image"}
              </MKTypography>
            </Button>
          </div>
        </label>

        {loading ? (
          <div className="flex items-center justify-center gap-2">
            <div className="border-t-transparent border-solid animate-spin rounded-full border-blue-400 border-4 h-6 w-6"></div>
            <span
              sx={{
                color: ({ palette: { text } }) => text.darkAccent,
              }}
            >
              Processing...
            </span>
          </div>
        ) : (
          url && <></>
        )}
      </div>
    </div>
  );
};

export default MDImageUpload;
