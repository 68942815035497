import React from "react";
import { Button } from "pages/ProblemSolution/components/MovingBorder";
import "./Experience.css"; // Assuming CSS is in the same folder

const workExperience = [
  {
    id: 1,
    title: "Consume vast water",
    desc: "",
    className: "md:col-span-2",
    // thumbnail: "/exp1.svg",
  },
  {
    id: 2,
    title: "Produce vast waste",
    desc: "",
    className: "md:col-span-2",
    // thumbnail: "/exp2.svg",
  },
  {
    id: 3,
    title: "Emit vast greenhouse gases",
    desc: "",
    className: "md:col-span-2",
    // thumbnail: "/exp3.svg",
  },
  {
    id: 4,
    title: "Use a lot of space",
    desc: "",
    className: "md:col-span-2",
    // thumbnail: "/exp4.svg",
  },
];

const ProteinSources = () => {
  return (
    <div className="experience-container">
      <div className="experience-grid">
        {workExperience?.map((card) => (
          <Button
            key={card.id}
            duration={Math.floor(Math.random() * 10000) + 10000}
            borderRadius="1.75rem"
            style={{
              background: "rgb(25,25,25)",
              backgroundColor:
                "linear-gradient(90deg, rgba(25, 25, 25, 0.95) 100%, rgba(66, 66, 74, 0.95) 100%)",
              borderRadius: `calc(1.75rem * 0.96)`,
            }}
            className="experience-button"
          >
            <div className="button-content">
              <div className="text-content">
                <h1 className="title">{card.title}</h1>
              </div>
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
};

export default ProteinSources;
