import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKPageHeaderTemplate from "components/MKPageHeaderTemplate";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import ProfileInfoCard from "pages/Profile/ProfileInfoCard";
import ProfileDetails from "pages/Dashboard/components/ProfileDetails";

// Images
import profileBg from "assets/images/profile.jpg";

import { getSingleUser } from "actions/userActions";

function Profile() {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);

  const singleUser = useSelector((state) => state.singleUser);
  const { userSingle } = singleUser;

  useEffect(() => {
    dispatch(getSingleUser());
  }, [dispatch, userSingle?.length]);

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <>
      <MKPageHeaderTemplate bgImage={profileBg} title="Profile" subTitle="">
        <MKBox component="section" py={{ xs: 6, sm: 12 }}>
          <Grid container item xs={12} justifyContent="center" mx="auto">
            <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
              <MKAvatar
                src={userSingle?.profile_image}
                alt={userSingle?.name}
                size="xxl"
                shadow="xl"
              />
            </MKBox>
            <Grid container justifyContent="center" py={6}>
              <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
                <MKBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                >
                  <MKTypography variant="h3">{userSingle?.name} </MKTypography>
                  <MKButton
                    onClick={handleEditMode}
                    variant="outlined"
                    color="info"
                    size="small"
                  >
                    {editMode ? "Cancel" : "Edit Profile"}
                  </MKButton>
                </MKBox>
                <Grid container spacing={3} mb={3}>
                  <Grid item>
                    <MKTypography
                      component="span"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        color: ({ palette: { text } }) => text.darkAccent,
                      }}
                    >
                      {userSingle?.products?.length}&nbsp;
                    </MKTypography>
                    <MKTypography
                      component="span"
                      variant="body2"
                      sx={{
                        color: ({ palette: { text } }) => text.darkAccent,
                      }}
                    >
                      Product Orders
                    </MKTypography>
                  </Grid>
                  <Grid item>
                    <MKTypography
                      component="span"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        color: ({ palette: { text } }) => text.darkAccent,
                      }}
                    >
                      {userSingle?.orders?.length}&nbsp;
                    </MKTypography>
                    <MKTypography
                      component="span"
                      variant="body2"
                      sx={{
                        color: ({ palette: { text } }) => text.darkAccent,
                      }}
                    >
                      Orders
                    </MKTypography>
                  </Grid>
                </Grid>
                {editMode ? (
                  <>
                    <Divider />
                    <MKBox pt={1} pb={2} px={2}>
                      <ProfileDetails />
                    </MKBox>
                  </>
                ) : (
                  <MKBox width="100%">
                    <ProfileInfoCard
                      title="Client Account Information"
                      currentInfo={userSingle}
                      active={userSingle?.active}
                      info={{
                        fullName: userSingle?.name,
                        mobile: userSingle?.phone,
                        email: userSingle?.email,
                        idNumber: userSingle?.idNumber,
                        dob: moment(userSingle?.date_of_birth).format(
                          "DD/MM/YYYY"
                        ),
                        joined: moment(userSingle?.created_at).fromNow(),
                      }}
                      action={{ route: "", tooltip: "Edit Profile" }}
                      shadow={false}
                    />
                  </MKBox>
                )}
              </Grid>
            </Grid>
          </Grid>
        </MKBox>
      </MKPageHeaderTemplate>
    </>
  );
}

export default Profile;
