import HttpService from "./htttp.service";

class ComplaintsService {
  // ComplaintsEndpoint = process.env.API_URL;

  getMyComplaints = async () => {
    const getMyComplaints = "fe/my_complaints";
    return await HttpService.get(getMyComplaints);
  };

  getComplaintsSingle = async (id) => {
    const getComplaintsSingle = `fe/complaints/${id}`;
    return await HttpService.get(getComplaintsSingle);
  };

  getComplaintsStats = async () => {
    const getComplaintsStats = "fe/complaints/stats";
    return await HttpService.get(getComplaintsStats);
  };

  getIncompleteComplaints = async () => {
    const getIncompleteComplaints = "fe/complaints/incomplete";
    return await HttpService.get(getIncompleteComplaints);
  };

  createComplaint = async (payload) => {
    const createComplaint = "fe/my_complaints/new";
    return await HttpService.post(createComplaint, payload);
  };

  editComplaint = async (id, payload) => {
    const editComplaint = `fe/complaints/${id}`;
    return await HttpService.put(editComplaint, payload);
  };
}

export default new ComplaintsService();
