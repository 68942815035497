import React, { useEffect, useState, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { keyframes } from "@emotion/react";

// Define the keyframes for the scrolling animation
const scrollKeyframes = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const InfiniteMovingCards = ({
  items,
  direction = "left",
  speed = "fast",
  pauseOnHover = true,
}) => {
  const theme = useTheme();
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.between("xxs", "xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXXLargeScreen = useMediaQuery(theme.breakpoints.between("xl", "xxl"));
  const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

  const containerRef = useRef(null);
  const scrollerRef = useRef(null);

  const [start, setStart] = useState(false);

  useEffect(() => {
    addAnimation();
  }, []);

  function addAnimation() {
    if (containerRef.current && scrollerRef.current) {
      const scrollerContent = Array.from(scrollerRef.current.children);

      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        if (scrollerRef.current) {
          scrollerRef.current.appendChild(duplicatedItem);
        }
      });

      setStart(true);
    }
  }

  const animationDuration =
    speed === "fast" ? "10s" : speed === "normal" ? "20s" : "40s";

  return (
    <Box
      ref={containerRef}
      sx={{
        position: "relative",
        zIndex: 2,
        width: "70vw",
        overflow: "hidden",
        maskImage:
          "linear-gradient(to right, transparent, white 20%, white 80%, transparent)",
        "&:hover ul": pauseOnHover ? { animationPlayState: "paused" } : {},
      }}
    >
      <List
        ref={scrollerRef}
        sx={{
          display: "flex",
          minWidth: "200%",
          gap:
            !isXXSmallScreen &&
            !isXSmallScreen &&
            !isSmallScreen &&
            !isMediumScreen
              ? 4
              : 6,
          // py: 2,
          flexWrap: "nowrap",
          animation: `${scrollKeyframes} ${animationDuration} linear infinite`,
        }}
      >
        {items.map((item) => (
          <ListItem
            key={item?.id}
            sx={{
              width: { xs: "10vw", md: "10vw" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "50%",
              // borderRadius: 2,
              // border: 1,
              // borderColor: "grey.800",
              p: { xs: 2, md: 4 },
              // background:
              //   "linear-gradient(90deg, rgba(4,7,29,1) 0%, rgba(12,14,35,1) 100%)",
              flexShrink: 0,
            }}
          >
            {/* <Box> */}
            {/* <Typography variant="body1" color="white">
                {item.quote}
              </Typography> */}
            <Box mt={2} display="flex" alignItems="center">
              <img
                src={item?.icon}
                alt="profile"
                width={
                  !isXXSmallScreen &&
                  !isXSmallScreen &&
                  !isSmallScreen &&
                  !isMediumScreen
                    ? 150
                    : 70
                }
                height={
                  !isXXSmallScreen &&
                  !isXSmallScreen &&
                  !isSmallScreen &&
                  !isMediumScreen
                    ? 150
                    : 70
                }
              />
            </Box>
            <Box mt={2} display="flex" alignItems="center">
              {/* <Box ml={2}> */}
              {/* <Typography variant="h6" color="white">
                    {item.name}
                  </Typography> */}
              {/* <Typography
                variant="h4"
                color="grey.400"
                sx={{ textAlign: "center" }}
              >
                {item.title}
              </Typography> */}
              {/* </Box> */}
            </Box>
            {/* </Box> */}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default InfiniteMovingCards;
