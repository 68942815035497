export const POST_LIST_REQUEST = "POST_LIST_REQUEST";
export const POST_LIST_SUCCESS = "POST_LIST_SUCCESS";
export const POST_LIST_FAIL = "POST_LIST_FAIL";
export const POST_LIST_RESET = "POST_LIST_RESET";

export const POST_SEARCH_REQUEST = "POST_SEARCH_REQUEST";
export const POST_SEARCH_SUCCESS = "POST_SEARCH_SUCCESS";
export const POST_SEARCH_FAIL = "POST_SEARCH_FAIL";
export const POST_SEARCH_RESET = "POST_SEARCH_RESET";

export const POST_SINGLE_REQUEST = "POST_SINGLE_REQUEST";
export const POST_SINGLE_SUCCESS = "POST_SINGLE_SUCCESS";
export const POST_SINGLE_FAIL = "POST_SINGLE_FAIL";
export const POST_SINGLE_RESET = "POST_SINGLE_RESET";
