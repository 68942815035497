import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import { routes } from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/premium-fish-removebg.png";
import ProductCard from "examples/ProductCard";

import { getSearchProduct } from "actions/productActions";

function Details() {
  const dispatch = useDispatch();
  const location = useLocation();
  const service = location.state;
  const [inputs, setInputs] = useState({
    productNumber: "",
    productName: "",
    service: service,
    amount: "",
    insuredItems: "",
  });

  const searchProducts = useSelector((state) => state.searchProducts);
  const { productsSearch } = searchProducts;

  useEffect(() => {
    localStorage.setItem("selectedAddons", JSON.stringify([]));
    dispatch(getSearchProduct(inputs));
  }, [dispatch]);

  const handleFilter = (e) => {
    e.preventDefault();
    localStorage.setItem("selectedAddons", JSON.stringify([]));
    dispatch(getSearchProduct(inputs));
  };

  const handleClearFilters = (e) => {
    e.preventDefault();
    setInputs({
      productNumber: "",
      productName: "",
      service: service,
      amount: "",
      insuredItems: "",
    });
    dispatch(
      getSearchProduct({
        productNumber: "",
        productName: "",
        service: service,
        amount: "",
        insuredItems: "",
      })
    );
  };

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "#",
          label: "Call Us",
          color: "default",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="45vh"
        width="100%"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.2),
              rgba(gradients.dark.state, 0.2)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {service}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mt={1}
              mb={3}
            >
              Vertical Lake {service}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.05),
              rgba(gradients.dark.state, 0.2)
            )}, url("")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <MKBox component="section" pt={5}>
          <Container>
            <MKBox component="form" method="post" onSubmit={handleFilter}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12 / 5} lg={12 / 5}>
                  <MKInput
                    variant="standard"
                    label="Product Number"
                    placeholder="Filter By Product Number"
                    fullWidth
                    value={inputs.productNumber}
                    name="productNumber"
                    onChange={changeHandler}
                  />
                </Grid>
                <Grid item xs={12} md={12 / 5} lg={12 / 5}>
                  <MKInput
                    variant="standard"
                    label="Product Name"
                    placeholder="Filter By Product Name"
                    fullWidth
                    value={inputs.productName}
                    name="productName"
                    onChange={changeHandler}
                  />
                </Grid>
                <Grid item xs={12} md={12 / 5} lg={12 / 5}>
                  <MKInput
                    variant="standard"
                    type="text"
                    label="Insured Item"
                    placeholder="Filter By Insured Item"
                    fullWidth
                    value={inputs.insuredItems}
                    name="insuredItems"
                    onChange={changeHandler}
                  />
                </Grid>
                <Grid item xs={12} md={12 / 5} lg={12 / 5}>
                  <MKInput
                    variant="standard"
                    type="number"
                    label="Amount"
                    placeholder="Filter By Product Amount"
                    fullWidth
                    value={inputs.amount}
                    name="amount"
                    onChange={changeHandler}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12 / 5}
                  lg={12 / 5}
                  display="flex"
                  flexDirection="row"
                >
                  <MKButton
                    type="submit"
                    variant="gradient"
                    color="dark"
                    fullWidth
                  >
                    Get Plans
                  </MKButton>
                  <MKButton
                    onClick={handleClearFilters}
                    variant="text"
                    color="info"
                    fullWidth
                  >
                    Clear Filters
                  </MKButton>
                </Grid>
              </Grid>
            </MKBox>
          </Container>
        </MKBox>
        <MKBox>
          <ProductCard items={productsSearch} />
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Details;
