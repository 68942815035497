import {
  PRODUCT_REQUIREMENT_LIST_REQUEST,
  PRODUCT_REQUIREMENT_LIST_SUCCESS,
  PRODUCT_REQUIREMENT_LIST_FAIL,
  PRODUCT_REQUIREMENT_LIST_RESET,
  PRODUCT_REQUIREMENT_SINGLE_REQUEST,
  PRODUCT_REQUIREMENT_SINGLE_SUCCESS,
  PRODUCT_REQUIREMENT_SINGLE_FAIL,
  PRODUCT_REQUIREMENT_SINGLE_RESET,
} from "constants/productRequirementConstants";

export const listProductRequirementsReducer = (
  state = { productRequirementList: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_REQUIREMENT_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_REQUIREMENT_LIST_SUCCESS:
      return { loading: false, productRequirementList: action.payload };
    case PRODUCT_REQUIREMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_REQUIREMENT_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const singleProductRequirementsReducer = (
  state = { productRequirementSingle: {} },
  action
) => {
  switch (action.type) {
    case PRODUCT_REQUIREMENT_SINGLE_REQUEST:
      return { loading: true };
    case PRODUCT_REQUIREMENT_SINGLE_SUCCESS:
      return { loading: false, productRequirementSingle: action.payload };
    case PRODUCT_REQUIREMENT_SINGLE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_REQUIREMENT_SINGLE_RESET:
      return [];
    default:
      return state;
  }
};
