import {
  MY_COMPLAINT_LIST_REQUEST,
  MY_COMPLAINT_LIST_SUCCESS,
  MY_COMPLAINT_LIST_FAIL,
  MY_COMPLAINT_LIST_RESET,
  COMPLAINT_STATS_REQUEST,
  COMPLAINT_STATS_SUCCESS,
  COMPLAINT_STATS_FAIL,
  COMPLAINT_STATS_RESET,
  COMPLAINT_SINGLE_REQUEST,
  COMPLAINT_SINGLE_SUCCESS,
  COMPLAINT_SINGLE_FAIL,
  COMPLAINT_SINGLE_RESET,
  COMPLAINT_PENDING_REQUEST,
  COMPLAINT_PENDING_SUCCESS,
  COMPLAINT_PENDING_FAIL,
  COMPLAINT_PENDING_RESET,
  COMPLAINT_POST_REQUEST,
  COMPLAINT_POST_SUCCESS,
  COMPLAINT_POST_FAIL,
  COMPLAINT_POST_RESET,
  COMPLAINT_UPDATE_REQUEST,
  COMPLAINT_UPDATE_SUCCESS,
  COMPLAINT_UPDATE_FAIL,
  COMPLAINT_UPDATE_RESET,
  COMPLAINT_DELETE_REQUEST,
  COMPLAINT_DELETE_SUCCESS,
  COMPLAINT_DELETE_FAIL,
  COMPLAINT_DELETE_RESET,
} from "constants/complaintConstants";

export const myListComplaintReducer = (
  state = { myComplaintsList: [] },
  action
) => {
  switch (action.type) {
    case MY_COMPLAINT_LIST_REQUEST:
      return { loading: true };
    case MY_COMPLAINT_LIST_SUCCESS:
      return { loading: false, myComplaintsList: action.payload };
    case MY_COMPLAINT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case MY_COMPLAINT_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const statsComplaintReducer = (
  state = { complaintsStats: [] },
  action
) => {
  switch (action.type) {
    case COMPLAINT_STATS_REQUEST:
      return { loading: true };
    case COMPLAINT_STATS_SUCCESS:
      return { loading: false, complaintsStats: action.payload };
    case COMPLAINT_STATS_FAIL:
      return { loading: false, error: action.payload };
    case COMPLAINT_STATS_RESET:
      return [];
    default:
      return state;
  }
};

export const singleComplaintReducer = (
  state = { complaintSingle: {} },
  action
) => {
  switch (action.type) {
    case COMPLAINT_SINGLE_REQUEST:
      return { loading: true };
    case COMPLAINT_SINGLE_SUCCESS:
      return { loading: false, complaintSingle: action.payload };
    case COMPLAINT_SINGLE_FAIL:
      return { loading: false, error: action.payload };
    case COMPLAINT_SINGLE_RESET:
      return {};
    default:
      return state;
  }
};

export const pendingComplaintReducer = (
  state = { complaintsPending: [] },
  action
) => {
  switch (action.type) {
    case COMPLAINT_PENDING_REQUEST:
      return { loading: true };
    case COMPLAINT_PENDING_SUCCESS:
      return { loading: false, complaintsPending: action.payload };
    case COMPLAINT_PENDING_FAIL:
      return { loading: false, error: action.payload };
    case COMPLAINT_PENDING_RESET:
      return [];
    default:
      return state;
  }
};

export const postComplaintReducer = (
  state = { complaintsPost: {} },
  action
) => {
  switch (action.type) {
    case COMPLAINT_POST_REQUEST:
      return { loading: true, success: false, error: false };
    case COMPLAINT_POST_SUCCESS:
      return {
        loading: false,
        complaintsPost: action.payload,
        success: true,
        error: false,
      };
    case COMPLAINT_POST_FAIL:
      return { loading: false, error: action.payload, success: false };
    case COMPLAINT_POST_RESET:
      return {};
    default:
      return state;
  }
};

export const updateComplaintReducer = (
  state = { complaintsUpdate: {} },
  action
) => {
  switch (action.type) {
    case COMPLAINT_UPDATE_REQUEST:
      return { loading: true };
    case COMPLAINT_UPDATE_SUCCESS:
      return {
        loading: false,
        complaintsUpdate: action.payload,
        success: true,
      };
    case COMPLAINT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case COMPLAINT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteComplaintReducer = (
  state = { complaintsDelete: {} },
  action
) => {
  switch (action.type) {
    case COMPLAINT_DELETE_REQUEST:
      return { loading: true };
    case COMPLAINT_DELETE_SUCCESS:
      return {
        loading: false,
        complaintsDelete: action.payload,
        success: true,
      };
    case COMPLAINT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case COMPLAINT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
