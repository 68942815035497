// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Vertical Lake React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/vertical-lake-logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    // name: "Vertical Lake",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/vertilake",
    },
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/company/101963805/admin/feed/posts/",
    },
  ],
  menus: [
    {
      name: "Products",
      items: [
        { name: "Protein Production", href: "/protein-premium-markets" },
        { name: "Organic Fertilizer", href: "/products" },
        { name: "Climate Resilience", href: "/sustainability-circularity" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "About Us", href: "/about-us" },
        { name: "Contact Us", href: "/about-us" },
        { name: "Technology", href: "/technology" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "Terms & Conditions", href: "#" },
        { name: "Privacy Policy", href: "#" },
      ],
    },
  ],
  copyright: (
    <MKTypography
      variant="button"
      fontWeight="regular"
      sx={{
        fontSize: 18,
        color: ({ palette: { text } }) => text.white,
      }}
    >
      Copyright &copy; {date} Vertical Lake Ltd.
    </MKTypography>
  ),
};
