import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKPageHeaderVideoTemplate from "components/MKPageHeaderVideoTemplate";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredTechCard from "examples/Cards/BlogCards/CenteredTechCard";

// Images
import problemImage from "assets/images/2.png";
import LedLighting from "assets/images/led-lighting.jpg";

// Videos
import videoProduct from "assets/solution_page.mp4";

import ProteinSources from "pages/ProblemSolution/components/ProteinSources";

import jackBannerImage from "assets/images/jack-landscape.png";
import plainWhiteImage from "assets/images/plain-white.jpg";

function ProblemSolution() {
  const theme = useTheme();
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.between("xxs", "xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXXLargeScreen = useMediaQuery(theme.breakpoints.between("xl", "xxl"));
  const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <>
      <MKPageHeaderVideoTemplate
        bgVideo={videoProduct}
        title="The Problem & Our Solution"
        subTitle="Challenges faced by traditional protein production methods and our innovation"
      >
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", my: 2, mx: "auto", px: 0.75 }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            When we consider the growing global population and undeniable
            climate change, traditional protein food production methods pose a
            significant problem. They consume vast amounts of water, emit
            substantial greenhouse gases, and take up valuable space.
            Recognizing these challenges, more and more consumers are demanding
            organic, sustainable farming methods.
          </MKTypography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{
            textAlign: "center",
            my: 2,
            mx: "auto",
            px: 0.75,
            // width: "100vw",
          }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "h4"
                : "h5"
            }
            fontWeight="bold"
            textAlign="center"
            sx={{
              py: 3,
              color: ({ palette: { text } }) => text.lightAccent,
            }}
          >
            Traditional protein production sources:
          </MKTypography>
          <ProteinSources />
        </Grid>

        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            When we turn specifically to conventional lake/cage farming
            practices, these are taking a heavy toll on our water bodies and the
            health of natural fish species, leading to toxic buildup, invasive
            plant growth, pollution, and disease. This is not sustainable.
          </MKTypography>
        </Grid>
        {!isXXSmallScreen &&
        !isXSmallScreen &&
        !isSmallScreen &&
        !isMediumScreen ? (
          <Container sx={{ my: 10 }}>
            <Grid container spacing={10} justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5} pt={5}>
                  <DefaultInfoCard
                    icon=""
                    title="Premium Protein"
                    direction="left"
                    description="We are revolutionizing sustainable protein agriculture with the
            world's first truly vertical freshwater aquaculture lake system. Our
            system is highly circular in nature being water-efficient,
            space-efficient, net zero carbon and recycles its waste. This aligns
            perfectly with global demands for sustainable and circular food
            production."
                  />
                </MKBox>
              </Grid>

              <Grid item xxs={12} xs={12} sm={12} md={6}>
                <CenteredTechCard
                  image={problemImage}
                  title="tech Join the Movement"
                  description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                  action={{
                    type: "internal",
                    route: "#",
                    color: "info",
                    label: "find out more",
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Container sx={{ my: 10 }}>
            <Grid container spacing={10}>
              <Grid item xxs={12} xs={12} sm={12} md={6}>
                <CenteredTechCard
                  image={problemImage}
                  title="tech Join the Movement"
                  description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                  action={{
                    type: "internal",
                    route: "#",
                    color: "info",
                    label: "find out more",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon=""
                    title="Premium Protein"
                    direction="right"
                    description="We are revolutionizing sustainable protein agriculture with the
              world's first truly vertical freshwater aquaculture lake system. Our
              system is highly circular in nature being water-efficient,
              space-efficient, net zero carbon and recycles its waste. This aligns
              perfectly with global demands for sustainable and circular food
              production."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Container>
        )}

        <Container
          sx={{
            my:
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? 20
                : 0,
          }}
        >
          <Grid container spacing={10}>
            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <CenteredTechCard
                image={LedLighting}
                title="tech Join the Movement"
                description="Experience the future of sustainable aquaculture with Vertical Lake. Learn more about our innovative approach to protein production and how you can support our mission to create a healthier planet for future generations."
                action={{
                  type: "internal",
                  route: "#",
                  color: "info",
                  label: "find out more",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MKBox mb={{ xs: 5, md: 0 }}>
                <DefaultInfoCard
                  icon=""
                  title="Edible fish"
                  direction="right"
                  description="The modularity and unique biofiltration of our system brings health
            benefits that produces 3x the yield of conventional equivalent lake
            fish. This, coupled with our vertical stacking technology, means that
            our production-per-land-space-used is over x15 density of protein
            produced by that surface area today."
                />
              </MKBox>
            </Grid>
          </Grid>
        </Container>
        <MKBox
          minHeight={
            !isXXSmallScreen &&
            !isXSmallScreen &&
            !isSmallScreen &&
            !isMediumScreen &&
            !isLargeScreen
              ? "70vh"
              : "40vh"
          }
          width="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.1),
                rgba(gradients.dark.state, 0.4)
              )}, url(${
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen &&
                !isLargeScreen
                  ? jackBannerImage
                  : plainWhiteImage
              })`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",
            display: "grid",
            placeItems: "center",
            borderRadius: 5,
            padding:
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen &&
              !isLargeScreen
                ? 5
                : 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xxs={12} xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <MKBox
                width={
                  !isXXSmallScreen &&
                  !isXSmallScreen &&
                  !isSmallScreen &&
                  !isMediumScreen &&
                  !isLargeScreen
                    ? "90%"
                    : "100%"
                }
                sx={{
                  backgroundImage: ({
                    functions: { linearGradient, rgba },
                    palette: { gradients },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.95),
                      rgba(gradients.dark.state, 0.95)
                    )}`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "grid",
                  placeItems: "center",
                  borderColor: ({ palette: { text } }) => text.light,
                  borderWidth: 5,
                  borderStyle: "solid",
                  borderRadius: 5,
                  padding: 3,
                  paddingTop: 10,
                  marginLeft: 0,
                }}
              >
                <DefaultInfoCard
                  icon=""
                  title="How did it start? Innovating Against Invasives: Jack Oyugi's Journey from biotech graduate to Eco-Entrepreneur"
                  direction="left"
                  description="Lake Victoria, vital to East Africa, faces an environmental threat from water hyacinth, a rapidly multiplying invasive plant. Jack Oyugi co-founder, leveraging his biotechnology background from the University of Nairobi, embarked on an innovative path to turn this challenge into an opportunity. In 2018, Jack developed a groundbreaking method to convert water hyacinth into protein-rich animal feed.  As Jack developed his biofeed concept, he encountered a problem. The quality of the protein from water hyacinth wasn’t sufficiently high, and an alternative, supplementary protein source was required to balance the feed. Jack eventually turned to aquaculture for fish meal to increase the nutritional content of his feed."
                  description2="Having found a suitable method of generating protein, the next challenge was scaling up the process. Jack's pivotal innovation, 'Oviponics,' a vertical aquaculture system, maximizes space and resources, providing sustainable organic fish protein and organic bio-fertilizer in a truly circular fashion that is  highly scalable. "
                  description3="Jack's story is a testament to how innovative thinking and dedication can turn ecological challenges into sustainable opportunities."
                />
              </MKBox>
            </Grid>
          </Grid>
        </MKBox>

        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "justify", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography
            variant={
              !isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen
                ? "body1"
                : "body2"
            }
            fontWeight="normal"
            textAlign="justify"
            sx={{
              color: ({ palette: { text } }) => text.light,
            }}
          >
            Vertical Lake is now leveraging this science and its sustainable
            methods to produce premium organic and protein products in Kenyan
            and international markets.
          </MKTypography>
        </Grid>
        {!isXXSmallScreen &&
        !isXSmallScreen &&
        !isSmallScreen &&
        !isMediumScreen &&
        !isLargeScreen ? null : (
          <Card>
            <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
              <MKBox
                component="img"
                src={jackBannerImage}
                alt="jack banner"
                borderRadius="lg"
                width="100%"
                height="100%"
                sx={{
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                position="relative"
                zIndex={1}
              />
              <MKBox
                borderRadius="lg"
                shadow="md"
                width="100%"
                height="100%"
                position="absolute"
                left={0}
                top={0}
                sx={{
                  backgroundImage: `url(${jackBannerImage})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top right",
                  transform: "scale(0.94)",
                  filter: "blur(12px)",
                  backgroundSize: "cover",
                }}
              />
            </MKBox>
          </Card>
        )}
      </MKPageHeaderVideoTemplate>
    </>
  );
}

export default ProblemSolution;
