// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import TeamSectionCard from "examples/Cards/TeamCards/TeamSectionCard";

// Images
import jack from "assets/images/jack-portrait.png";
import lofts from "assets/images/lofts-portrait.png";
import nick from "assets/images/nick-portrait.png";
import ian from "assets/images/Ian.png";
import winnie from "assets/images/winnie-portrait.png";
import pantea from "assets/images/pantea-portrait.png";
import brenda from "assets/images/brenda-portrait.png";
import emmanuel from "assets/images/emmanuel-portrait.png";

function Team() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container p={6}>
      <Grid container spacing={3} py={10}>
        <Grid item xs={12} lg={6}>
          <MKBox mb={1}>
            <TeamSectionCard
              image={jack}
              name="Jack Oyugi, Kenya"
              position={{
                color: "light",
                label: "Co-Founder & CEO, & Technology Lead",
              }}
              description="10+ years experience in Research, Innovation & Entrepreneurship University of Nairobi & Biofit feeds."
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKBox mb={1}>
            <TeamSectionCard
              image={lofts}
              name="Dr. Jez Lofts, UK"
              position={{
                color: "light",
                label: "Co-founder, Exec Chair. & Commercial Lead",
              }}
              description="30 years building, scaling & leading large and small companies."
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKBox mb={{ xs: 1, lg: 0 }}>
            <TeamSectionCard
              image={winnie}
              name="Akoth Winnie, Kenya,"
              position={{ color: "light", label: "COO & marketing lead" }}
              description="Experienced Business dev, sales & marketing professional with African expertise."
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKBox mb={{ xs: 1, lg: 0 }}>
            <TeamSectionCard
              image={ian}
              name=" Dr. Ian McEwan CGMA, UK"
              position={{ color: "light", label: "COO" }}
              description="Various management roles at Unilever, led a $200m business, 20 years as CFO/COO of numerous startups, a founder, investor and charity trustee."
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKBox mb={{ xs: 1, lg: 0 }} mt={isSmallScreen ? 15 : 0}>
            <TeamSectionCard
              image={nick}
              name="Nick Vilelle, US & Kenya"
              position={{ color: "light", label: "Board Advisor" }}
              description="20+ years as global entrepreneur & Angel Investor with over 20 years in Africa."
            />
          </MKBox>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Team;
