import React, { useState } from "react";
import { Modal, Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// import FooterAnimation from "examples/Footers/DefaultFooter/FooterAnimation";

function DefaultFooter({ content }) {
  const { brand, socials, menus, copyright } = content;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = useTheme();
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.between("xxs", "xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXXLargeScreen = useMediaQuery(theme.breakpoints.between("xl", "xxl"));
  const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ ml: "auto", mx: "auto", px: "auto" }}>
            <MKBox
              display="flex"
              // flexDirection={isSmallScreen ? "row" : "column"}
              justifyContent="center"
              flexDirection={
                !isXXSmallScreen &&
                !isXSmallScreen &&
                !isSmallScreen &&
                !isMediumScreen &&
                !isLargeScreen
                  ? "column"
                  : "row"
              }
            >
              <Link to={brand.route}>
                <MKBox
                  component="img"
                  src={brand.image}
                  alt={brand.name}
                  width={
                    isXXSmallScreen
                      ? "7rem"
                      : isXSmallScreen
                      ? "10rem"
                      : isSmallScreen
                      ? "15rem"
                      : isMediumScreen
                      ? "15rem"
                      : isLargeScreen
                      ? "15rem"
                      : isXLargeScreen
                      ? "15rem"
                      : isXXLargeScreen
                      ? "20rem"
                      : "20rem"
                  }
                  maxWidth={
                    isXXSmallScreen
                      ? "7rem"
                      : isXSmallScreen
                      ? "10rem"
                      : isSmallScreen
                      ? "15rem"
                      : isMediumScreen
                      ? "15rem"
                      : isLargeScreen
                      ? "15rem"
                      : isXLargeScreen
                      ? "15rem"
                      : isXXLargeScreen
                      ? "20rem"
                      : "20rem"
                  }
                />
              </Link>
              <MKBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                mx={3}
                width={
                  !isXXSmallScreen &&
                  !isXSmallScreen &&
                  !isSmallScreen &&
                  !isMediumScreen &&
                  !isLargeScreen
                    ? "100%"
                    : "50%"
                }
                maxWidth={
                  !isXXSmallScreen &&
                  !isXSmallScreen &&
                  !isSmallScreen &&
                  !isMediumScreen &&
                  !isLargeScreen
                    ? "100%"
                    : "50%"
                }
              >
                {socials.map(({ icon, link }, key) => (
                  <MKTypography
                    key={link}
                    component="a"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    variant="h3"
                    color="light"
                    // opacity={0.8}
                    fontSize={
                      !isXXSmallScreen &&
                      !isXSmallScreen &&
                      !isSmallScreen &&
                      !isMediumScreen &&
                      !isLargeScreen
                        ? 40
                        : 25
                    }
                    mr={key === socials.length - 1 ? 0 : 2.5}
                  >
                    {icon}
                  </MKTypography>
                ))}
              </MKBox>
            </MKBox>
          </Grid>
          {menus.map(({ name: title, items }) => (
            <>
              {!isXXSmallScreen &&
              !isXSmallScreen &&
              !isSmallScreen &&
              !isMediumScreen &&
              !isLargeScreen ? (
                <Grid key={title} item xs={6} md={3} sx={{ mb: 3 }}>
                  <MKTypography
                    display="block"
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize"
                    mb={1}
                    sx={{
                      textAlign: "right",
                      fontSize: 18,
                      color: ({ palette: { text } }) => text.white,
                    }}
                  >
                    {title}
                  </MKTypography>

                  <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                    {items.map(({ name, route, href }) => (
                      <MKBox
                        key={name}
                        component="li"
                        p={0}
                        m={0}
                        lineHeight={1.25}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {name === "Privacy Policy" ? (
                          <MKTypography
                            component="span"
                            onClick={handleOpen}
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                            sx={{
                              fontSize: 18,
                              color: ({ palette: { text } }) => text.light,
                              cursor: "pointer",
                            }}
                          >
                            Privacy Policy
                          </MKTypography>
                        ) : name === "Terms & Conditions" ? (
                          <MKTypography
                            component="span"
                            onClick={handleOpen}
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                            sx={{
                              fontSize: 18,
                              color: ({ palette: { text } }) => text.light,
                              cursor: "pointer",
                            }}
                          >
                            Terms & Conditions
                          </MKTypography>
                        ) : href ? (
                          <MKTypography
                            component="a"
                            href={href}
                            target="_blank"
                            rel="noreferrer"
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                            sx={{
                              fontSize: 18,
                              color: ({ palette: { text } }) => text.light,
                            }}
                          >
                            {name}
                          </MKTypography>
                        ) : (
                          <MKTypography
                            component={Link}
                            to={route}
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                            sx={{
                              fontSize: 18,
                              color: ({ palette: { text } }) => text.light,
                            }}
                          >
                            {name}
                          </MKTypography>
                        )}
                      </MKBox>
                    ))}
                  </MKBox>
                </Grid>
              ) : null}
            </>
          ))}
          <Grid
            item
            xs={12}
            sx={{
              textAlign: "center",
              mb: 3,
              mx: "auto",
              color: ({ palette: { text } }) => text.light,
            }}
          >
            {copyright}
          </Grid>
        </Grid>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="privacy-policy-title"
        aria-describedby="privacy-policy-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="privacy-policy-title">Privacy Policy | Terms & Conditions</h2>
          <p id="privacy-policy-description">please contact our team</p>
          <Button onClick={handleClose}>Close</Button>
        </Box>
      </Modal>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  ).isRequired,
};

export default DefaultFooter;
