import { useContext, useState } from "react";
// react-router-dom components
import { Link } from "react-router-dom";
import moment from "moment";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MKInput from "components/MKInput";
import MDButton from "components/MDButton";
import MKDatePicker from "components/MKDatePicker";
import MDImageUpload from "components/MDImageUpload";

// Authentication layout components
// import CoverLayout from "layouts/authentication/components/CoverLayout";
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";

// Images
// import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import bgImage from "assets/images/premium-fish-removebg.png";
import loginBg from "assets/images/login.jpg";

import AuthService from "services/auth-service";
import { AuthContext } from "context";
import { InputLabel } from "@mui/material";

function Register() {
  const authContext = useContext(AuthContext);

  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    phone: "",
    idNumber: "",
    date_of_birth: "",
    profile_image: "",
    password: "",
    confirm_password: "",
    agree: false,
  });

  const [errors, setErrors] = useState({
    nameError: false,
    emailError: false,
    phoneError: false,
    idNumberError: false,
    dateOfBirthError: false,
    profileImageError: false,
    passwordError: false,
    confirmPasswordError: false,
    agreeError: false,
    error: false,
    errorText: "",
  });

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (inputs.name.trim().length === 0) {
      setErrors({ ...errors, nameError: true });
      return;
    }

    if (
      inputs.email.trim().length === 0 ||
      !inputs.email.trim().match(mailFormat)
    ) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    if (inputs.phone.trim().length < 10) {
      setErrors({ ...errors, phoneError: true });
      return;
    }

    if (inputs.idNumber.trim().length < 8) {
      setErrors({ ...errors, idNumberError: true });
      return;
    }

    if (inputs.password !== inputs.confirm_password) {
      setErrors({ ...errors, confirmPasswordError: true });
      return;
    }

    if (inputs.password.trim().length < 8) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    if (inputs.agree === false) {
      setErrors({ ...errors, agreeError: true });
      return;
    }

    // here will be the post action to add a user to the db
    const newUser = {
      name: inputs.name,
      email: inputs.email,
      phone: inputs.phone,
      idNumber: inputs.idNumber,
      password: inputs.password,
      confirm_password: inputs.confirm_password,
      date_of_birth: inputs.date_of_birth,
      profile_image: inputs.profile_image,
    };

    const myData = {
      data: {
        type: "users",
        attributes: { ...newUser, password_confirmation: newUser.password },
        relationships: {
          roles: {
            data: [
              {
                type: "roles",
                id: "1",
              },
            ],
          },
        },
      },
    };
    console.log("🚀 ~ file: index.js:137 ~ submitHandler ~ myData:", myData);

    try {
      const response = await AuthService.register(myData);
      authContext.login(response.access_token, response.refresh_token);

      setInputs({
        name: "",
        email: "",
        phone: "",
        idNumber: "",
        date_of_birth: "",
        profile_image: "",
        password: "",
        confirm_password: "",
        agree: false,
      });

      setErrors({
        nameError: false,
        emailError: false,
        phoneError: false,
        idNumberError: false,
        dateOfBirthError: false,
        profileImageError: false,
        passwordError: false,
        confirmPasswordError: false,
        agreeError: false,
        error: false,
        errorText: "",
      });
    } catch (err) {
      setErrors({ ...errors, error: true, errorText: err.message });
      console.error(err);
    }
  };

  return (
    <BasicLayoutLanding image={loginBg}>
      {/* <CoverLayout image={bgImage}> */}
      <Card
        sx={{
          p: 2,
          backgroundColor: ({
            palette: { backgroundCard },
            functions: { rgba },
          }) => rgba(backgroundCard.main, 0.1),
          backdropFilter: "saturate(200%) blur(130px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MDBox
          variant="gradient"
          bgColor="light"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Fill details below to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox
            component="form"
            role="form"
            method="POST"
            onSubmit={submitHandler}
          >
            <MDBox mb={2}>
              <MKInput
                type="text"
                label="Name"
                variant="standard"
                fullWidth
                name="name"
                value={inputs.name}
                onChange={changeHandler}
                error={errors.nameError}
                inputProps={{
                  autoComplete: "name",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.nameError && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="light"
                >
                  The name can not be empty
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MKInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                value={inputs.email}
                name="email"
                onChange={changeHandler}
                error={errors.emailError}
                inputProps={{
                  autoComplete: "email",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.emailError && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="light"
                >
                  The email must be valid
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MKInput
                type="phone"
                label="Phone"
                variant="standard"
                fullWidth
                value={inputs.phone}
                name="phone"
                onChange={changeHandler}
                error={errors.phoneError}
                inputProps={{
                  autoComplete: "phone",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.phoneError && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="light"
                >
                  The phone must be valid
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MKInput
                type="text"
                label="National ID Number"
                variant="standard"
                fullWidth
                value={inputs.idNumber}
                name="idNumber"
                onChange={changeHandler}
                error={errors.idNumberError}
                inputProps={{
                  autoComplete: "idNumber",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.idNumberError && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="light"
                >
                  The national id number must be valid
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MKInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                name="password"
                value={inputs.password}
                onChange={changeHandler}
                error={errors.passwordError}
              />
              {errors.passwordError && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="light"
                >
                  The password must be of at least 8 characters
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MKInput
                type="password"
                label="Confirm Password"
                variant="standard"
                fullWidth
                name="confirm_password"
                value={inputs.confirm_password}
                onChange={changeHandler}
                error={errors.confirmPasswordError}
              />
              {errors.confirmPasswordError && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="light"
                >
                  The password and confirm password must be same
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MKDatePicker
                options={{ maxDate: Date.now(), dateFormat: "d-M-Y" }}
                label="Date of Birth"
                value={inputs.date_of_birth}
                name="date_of_birth"
                error={errors.dateOfBirthError}
                onChange={(item) =>
                  setInputs({
                    ...inputs,
                    date_of_birth: moment(item[0]).format(
                      "YYYY-MM-DDTHH:mm:ss.SSSZ"
                    ),
                  })
                }
              />
              {errors.dateOfBirthError && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="light"
                >
                  The date of birth must be valid
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDImageUpload
                title="Profile Image"
                uploadType="image"
                valueType={inputs?.profile_image}
                value={inputs?.profile_image}
                name="profile_image"
                error={errors.profileImageError}
                onImageChange={(item) =>
                  setInputs({
                    ...inputs,
                    profile_image: item?.value,
                  })
                }
              />
              {errors.profileImageError && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="light"
                >
                  The image must be valid
                </MDTypography>
              )}
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox name="agree" id="agree" onChange={changeHandler} />
              <InputLabel
                variant="standard"
                fontWeight="regular"
                color="text"
                sx={{ lineHeight: "1.5", cursor: "pointer" }}
                htmlFor="agree"
              >
                &nbsp;&nbsp;I agree to the&nbsp;
              </InputLabel>
              <MDTypography
                component={Link}
                to="/auth/login"
                variant="button"
                fontWeight="bold"
                color="light"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            {errors.agreeError && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                You must agree to the Terms and Conditions
              </MDTypography>
            )}
            {errors.error && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                {errors.errorText}
              </MDTypography>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="light"
                fullWidth
                type="submit"
              >
                sign up
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/auth/login"
                  variant="button"
                  color="light"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      {/* </CoverLayout> */}
    </BasicLayoutLanding>
  );
}

export default Register;
