import {
  CLIENT_LIST_REQUEST,
  CLIENT_LIST_SUCCESS,
  CLIENT_LIST_FAIL,
  CLIENT_LIST_RESET,
  CLIENT_SINGLE_REQUEST,
  CLIENT_SINGLE_SUCCESS,
  CLIENT_SINGLE_FAIL,
  CLIENT_SINGLE_RESET,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAIL,
  CREATE_CLIENT_RESET,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  UPDATE_CLIENT_RESET,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  DELETE_CLIENT_RESET,
} from "constants/clientConstants";

export const listClientsReducer = (state = { clientsList: [] }, action) => {
  switch (action.type) {
    case CLIENT_LIST_REQUEST:
      return { loading: true };
    case CLIENT_LIST_SUCCESS:
      return { loading: false, clientsList: action.payload };
    case CLIENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case CLIENT_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const singleClientReducer = (state = { clientSingle: {} }, action) => {
  switch (action.type) {
    case CLIENT_SINGLE_REQUEST:
      return { loading: true };
    case CLIENT_SINGLE_SUCCESS:
      return { loading: false, clientSingle: action.payload };
    case CLIENT_SINGLE_FAIL:
      return { loading: false, error: action.payload };
    case CLIENT_SINGLE_RESET:
      return {};
    default:
      return state;
  }
};

export const createClientReducer = (state = { clientCreate: {} }, action) => {
  switch (action.type) {
    case CREATE_CLIENT_REQUEST:
      return { loading: true };
    case CREATE_CLIENT_SUCCESS:
      return { loading: false, clientCreate: action.payload, success: true };
    case CREATE_CLIENT_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_CLIENT_RESET:
      return { clientCreate: {} };
    default:
      return state;
  }
};

export const updateClientReducer = (state = { clientUpdate: {} }, action) => {
  switch (action.type) {
    case UPDATE_CLIENT_REQUEST:
      return { loading: true };
    case UPDATE_CLIENT_SUCCESS:
      return { loading: false, clientUpdate: action.payload, success: true };
    case UPDATE_CLIENT_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_CLIENT_RESET:
      return { clientUpdate: {} };
    default:
      return state;
  }
};

export const deleteClientReducer = (state = { clientDelete: {} }, action) => {
  switch (action.type) {
    case DELETE_CLIENT_REQUEST:
      return { loading: true };
    case DELETE_CLIENT_SUCCESS:
      return { loading: false, clientDelete: action.payload, success: true };
    case DELETE_CLIENT_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_CLIENT_RESET:
      return { clientDelete: {} };
    default:
      return state;
  }
};
