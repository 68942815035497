import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material Dashboard 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Divider from "@mui/material/Divider";

import { getSingleUser, updateUser } from "actions/userActions";
import MKBadge from "components/MKBadge";
import MKAvatar from "components/MKAvatar";
import MDImageUpload from "components/MDImageUpload";
import MKInput from "components/MKInput";
import { Grid } from "@mui/material";
import MKButton from "components/MKButton";

function ProfileDetails() {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    phone: "",
    idNumber: "",
    profile_image: "",
    current_password: "",
    password: "",
    confirm_password: "",
  });

  const singleUser = useSelector((state) => state.singleUser);
  const { userSingle } = singleUser;

  useEffect(() => {
    dispatch(getSingleUser());
  }, [dispatch, userSingle?.length]);

  useEffect(() => {
    if (userSingle?.email) {
      setInputs({
        ...inputs,
        name: userSingle?.name,
        email: userSingle?.email,
        phone: userSingle?.phone,
        idNumber: userSingle?.idNumber,
        profile_image: userSingle?.profile_image,
      });
    }
  }, [userSingle?.email]);

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleArrayChange = (item) => {
    setInputs({ ...inputs, profile_image: item?.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // validation
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (inputs.name.trim().length === 0) {
      alert("Username Error");
      return;
    }

    if (
      inputs.email.trim().length === 0 ||
      !inputs.email.trim().match(mailFormat)
    ) {
      alert("Email Error");
      return;
    }

    let userData = {
      data: {
        type: "profile",
        attributes: inputs,
      },
    };

    // call api for update
    dispatch(updateUser(userData));
  };

  return (
    <>
      <MKBox display="flex" justifyContent="space-between" px={1}>
        <MKBox display="flex" alignItems="center" lineHeight={1}>
          <MKAvatar
            src={userSingle?.profile_image}
            name={userSingle?.name}
            size="lg"
          />
          <MKBox pl={3}>
            <MKTypography
              variant="button"
              fontWeight="bold"
              ml={1}
              lineHeight={1}
            >
              {userSingle?.name}
            </MKTypography>
            <MKBox mt={1.5}>
              <MKTypography
                variant="button"
                fontSize="small"
                ml={1}
                lineHeight={1}
              >
                {userSingle?.email}
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
        <MKBox textAlign="center" alignContent="center" justifyContent="center">
          <MKBadge
            badgeContent={userSingle?.active ? "active" : "deactivated"}
            color={userSingle?.active ? "primary" : "error"}
            variant="gradient"
            size="lg"
          />
        </MKBox>
      </MKBox>
      <Divider variant="middle" />
      <MKBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        px={1}
      >
        <MKBox display="flex" alignItems="center">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MKInput
                type="text"
                label="Name"
                name="name"
                fullWidth
                value={inputs?.name}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MKInput
                InputProps={{
                  readOnly: true,
                }}
                type="email"
                label="Email Address"
                fullWidth
                value={inputs?.email}
                name="email"
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MKInput
                type="phone"
                label="Phone Number"
                fullWidth
                value={inputs?.phone}
                name="phone"
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MKInput
                type="text"
                label="National ID Number"
                fullWidth
                value={inputs?.idNumber}
                name="idNumber"
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </MKBox>

        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          justify="center"
          width="100%"
          style={{ paddingTop: 10 }}
        >
          <Grid item xs={6} md={6} lg={6}>
            <MDImageUpload
              title="Change Profile Image"
              uploadType="image"
              valueType={inputs?.profile_image}
              value={inputs?.profile_image}
              name="profile_image"
              onImageChange={(item) => handleArrayChange(item, item)}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <MKBox display="flex" justifyContent="flex-end" px={15}>
              <MKButton
                color="primary"
                disabled={
                  !inputs?.name ||
                  !inputs?.email ||
                  !inputs?.phone ||
                  !inputs?.idNumber
                }
                variant={
                  !inputs?.name ||
                  !inputs?.email ||
                  !inputs?.phone ||
                  !inputs?.idNumber
                    ? "outlined"
                    : "contained"
                }
                onClick={submitHandler}
              >
                Update Info
              </MKButton>
            </MKBox>
          </Grid>
        </Grid>
      </MKBox>

      <Divider variant="middle" />
      <MKBox p={2}>
        <MKTypography variant="h6" fontWeight="medium">
          Change Password
        </MKTypography>
      </MKBox>
      <MKBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        px={1}
      >
        <MKBox display="flex" alignItems="center" lineHeight={1}>
          <Grid container spacing={3} px={5}>
            <Grid item xs={12} md={12} lg={12}>
              <MKInput
                type="password"
                label="Current Password"
                fullWidth
                name="current_password"
                value={inputs?.current_password}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <MKInput
                type="password"
                label="Password"
                fullWidth
                name="password"
                value={inputs?.password}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <MKInput
                type="password"
                label="Confirm Password"
                fullWidth
                name="confirm_password"
                value={inputs?.confirm_password}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </MKBox>
      </MKBox>

      <Grid container spacing={3} p={5}>
        <Grid item xs={12} md={9} lg={9}>
          <MKBox>
            <MKTypography variant="h6" fontWeight="medium">
              Password requirements
            </MKTypography>
            <MKTypography
              variant="text"
              fontSize="small"
              sx={{
                color: ({ palette: { text } }) => text.darkAccent,
              }}
            >
              Please follow this guide for a strong password
            </MKTypography>
          </MKBox>
          <ul>
            <li>
              <MKTypography
                variant="text"
                fontSize="small"
                sx={{
                  color: ({ palette: { text } }) => text.darkAccent,
                }}
              >
                One special characters
              </MKTypography>
            </li>
            <li>
              <MKTypography
                variant="text"
                fontSize="small"
                sx={{
                  color: ({ palette: { text } }) => text.darkAccent,
                }}
              >
                Min 6 characters
              </MKTypography>
            </li>
            <li>
              <MKTypography
                variant="text"
                fontSize="small"
                sx={{
                  color: ({ palette: { text } }) => text.darkAccent,
                }}
              >
                One number (2 are recommended)
              </MKTypography>
            </li>
            <li>
              <MKTypography
                variant="text"
                fontSize="small"
                sx={{
                  color: ({ palette: { text } }) => text.darkAccent,
                }}
              >
                Change it often
              </MKTypography>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <MKButton
            color="primary"
            disabled={
              !inputs?.password ||
              !inputs?.current_password ||
              !inputs?.confirm_password ||
              inputs?.password !== inputs?.confirm_password
            }
            variant={
              !inputs?.password ||
              !inputs?.current_password ||
              !inputs?.confirm_password ||
              inputs?.password !== inputs?.confirm_password
                ? "outlined"
                : "contained"
            }
            onClick={submitHandler}
          >
            Change Password
          </MKButton>
        </Grid>
      </Grid>
    </>
  );
}

export default ProfileDetails;
