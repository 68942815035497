import HttpService from "./htttp.service";

class ProductsService {
  // ProductsEndpoint = process.env.API_URL;

  getProducts = async () => {
    const getProducts = "fe/products";
    return await HttpService.get(getProducts);
  };

  getProductsStats = async () => {
    const getProductsStats = "fe/products/stats";
    return await HttpService.get(getProductsStats);
  };

  getProductSearch = async (payload) => {
    const getProductSearch = `fe/products/search?productName=${payload.productName}&productNumber=${payload.productNumber}&amount=${payload.amount}&insuredItems=${payload.insuredItems}&service=${payload.service}`;
    return await HttpService.get(getProductSearch, payload);
  };

  getProductSingle = async (id) => {
    const getProductSingle = `fe/products/${id}`;
    return await HttpService.get(getProductSingle);
  };

  getProductsPerService = async (id) => {
    const getProductsPerService = `fe/products/service/${id}`;
    return await HttpService.get(getProductsPerService);
  };

  createProducts = async (payload) => {
    const createProducts = "fe/products/new";
    return await HttpService.post(createProducts, payload);
  };

  editProducts = async (id, payload) => {
    const editProducts = `fe/products/${id}`;
    return await HttpService.put(editProducts, payload);
  };
}

export default new ProductsService();
