import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";

import InfiniteMovingCards from "./InfiniteCards";

// import sustain1 from "assets/images/sustain-1.png";
import sustain2 from "assets/images/sustain-2.png";
import sustain3 from "assets/images/sustain-3.png";
import sustain4 from "assets/images/sustain-4.png";

const testimonials = [
  // {
  //   id: 1,
  //   title: "",
  //   icon: "/sustain-1.png",
  // },
  {
    id: 2,
    title: "",
    icon: "/sustain-2.png",
  },
  {
    id: 3,
    title: "",
    icon: "/sustain-3.png",
  },
  {
    id: 4,
    title: "",
    icon: "/sustain-4.png",
  },
];

const companies = [
  { id: 1, name: "cloudinary", img: "/cloud.svg", nameImg: "/cloudName.svg" },
  { id: 2, name: "appwrite", img: "/app.svg", nameImg: "/appName.svg" },
  { id: 3, name: "HOSTINGER", img: "/host.svg", nameImg: "/hostName.svg" },
  { id: 4, name: "stream", img: "/s.svg", nameImg: "/streamName.svg" },
  { id: 5, name: "docker.", img: "/dock.svg", nameImg: "/dockerName.svg" },
];

const ScrollIcons = () => {
  return (
    <Box id="testimonials">
      <Grid container direction="column" alignItems="center">
        <Box
          sx={{
            // height: { xs: "30vh", md: "30rem" },
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <InfiniteMovingCards
            items={testimonials}
            direction="right"
            speed="slow"
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default ScrollIcons;
