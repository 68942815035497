import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKPageHeaderTemplate from "components/MKPageHeaderTemplate";
import MKTypography from "components/MKTypography";

// Dashboard page components
import MenuItem from "pages/Dashboard/components/MenuItem";
import { Button, Divider } from "@mui/material";
import OrderDetails from "pages/Dashboard/components/OrderDetails";
import ComplaintDetails from "pages/Dashboard/components/ComplaintDetails";
import ProfileDetails from "pages/Dashboard/components/ProfileDetails";
import TransactionDetails from "pages/Dashboard/components/TransactionDetails";

// Images
import dashboardBg from "assets/images/dashboard-bg.jpg";

function Dashboard() {
  const [currentDetails, setCurrentDetails] = useState("products");

  const handleChangeDetails = (item) => {
    setCurrentDetails(item);
  };

  return (
    <>
      <MKPageHeaderTemplate bgImage={dashboardBg} title="Dashboard" subTitle="">
        <Grid container spacing={3} p={3}>
          <Grid item xs={12} md={3} lg={3}>
            <MKBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={2}
              m={0}
            >
              <Button
                variant="text"
                sx={{ justifyContent: "stretch" }}
                size="small"
                onClick={() => handleChangeDetails("products")}
              >
                <MenuItem
                  variant="button"
                  title="Orders"
                  icon="local_shipping"
                />
              </Button>
              <Button
                variant="text"
                sx={{ justifyContent: "stretch" }}
                size="small"
                onClick={() => handleChangeDetails("transactions")}
              >
                <MenuItem
                  variant="button"
                  title="Transactions"
                  icon="receipt_long"
                />
              </Button>
              <Button
                variant="text"
                sx={{ justifyContent: "stretch" }}
                size="small"
                onClick={() => handleChangeDetails("complaints")}
              >
                <MenuItem title="Complaints" icon="approval" />
              </Button>
              <Button
                variant="text"
                sx={{ justifyContent: "stretch" }}
                size="small"
                onClick={() => handleChangeDetails("profile")}
              >
                <MenuItem title="Profile" icon="person" />
              </Button>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            {currentDetails === "products" ? (
              <>
                <MKBox pt={3} px={2}>
                  <MKTypography variant="h6" fontWeight="medium" px={3}>
                    Orders
                  </MKTypography>
                </MKBox>
                <Divider />
                <MKBox pt={1} pb={2} px={2}>
                  <OrderDetails />
                </MKBox>
              </>
            ) : currentDetails === "transactions" ? (
              <>
                <MKBox pt={3} px={2}>
                  <MKTypography variant="h6" fontWeight="medium" px={3}>
                    Transactions
                  </MKTypography>
                </MKBox>
                <Divider />
                <MKBox pt={1} pb={2} px={2}>
                  <TransactionDetails />
                </MKBox>
              </>
            ) : currentDetails === "complaints" ? (
              <>
                <MKBox pt={3} px={2}>
                  <MKTypography variant="h6" fontWeight="medium" px={3}>
                    Complaints
                  </MKTypography>
                </MKBox>
                <Divider />
                <MKBox pt={1} pb={2} px={2}>
                  <ComplaintDetails />
                </MKBox>
              </>
            ) : currentDetails === "profile" ? (
              <>
                <MKBox pt={3} px={2}>
                  <MKTypography variant="h6" fontWeight="medium" px={3}>
                    Profile
                  </MKTypography>
                </MKBox>
                <Divider />
                <MKBox pt={1} pb={2} px={2}>
                  <ProfileDetails />
                </MKBox>
              </>
            ) : currentDetails === "settings" ? (
              <>
                <MKBox pt={3} px={2}>
                  <MKTypography variant="h6" fontWeight="medium" px={3}>
                    Settings
                  </MKTypography>
                </MKBox>
                <Divider />
                <MKBox pt={1} pb={2} px={2}></MKBox>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </MKPageHeaderTemplate>
    </>
  );
}

export default Dashboard;
