import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_SINGLE_REQUEST,
  USER_SINGLE_SUCCESS,
  USER_SINGLE_FAIL,
  USER_SINGLE_RESET,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  CREATE_USER_RESET,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_RESET,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_RESET,
} from "constants/userConstants";

export const listUsersReducer = (state = { usersList: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };
    case USER_LIST_SUCCESS:
      return { loading: false, usersList: action.payload };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const singleUserReducer = (state = { userSingle: {} }, action) => {
  switch (action.type) {
    case USER_SINGLE_REQUEST:
      return { loading: true };
    case USER_SINGLE_SUCCESS:
      return { loading: false, userSingle: action.payload, success: true };
    case USER_SINGLE_FAIL:
      return { loading: false, error: action.payload };
    case USER_SINGLE_RESET:
      return { userSingle: {} };
    default:
      return state;
  }
};

export const createUserReducer = (state = { userCreate: {} }, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
      return { loading: true };
    case CREATE_USER_SUCCESS:
      return { loading: false, userCreate: action.payload, success: true };
    case CREATE_USER_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_USER_RESET:
      return { userCreate: {} };
    default:
      return state;
  }
};

export const updateUserReducer = (state = { userUpdate: {} }, action) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return { loading: true };
    case UPDATE_USER_SUCCESS:
      return { loading: false, userUpdate: action.payload, success: true };
    case UPDATE_USER_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_USER_RESET:
      return { userUpdate: {} };
    default:
      return state;
  }
};

export const deleteUserReducer = (state = { userDelete: {} }, action) => {
  switch (action.type) {
    case DELETE_USER_REQUEST:
      return { loading: true };
    case DELETE_USER_SUCCESS:
      return { loading: false, userDelete: action.payload, success: true };
    case DELETE_USER_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_USER_RESET:
      return { userDelete: {} };
    default:
      return state;
  }
};
