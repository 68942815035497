import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKBadge from "components/MKBadge";

// Custom styles for MKPageHeaderVideoTemplate
import MKPageHeaderVideoTemplateRoot from "components/MKPageHeaderVideoTemplate/MKPageHeaderVideoTemplateRoot";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import FooterAnimation from "examples/Footers/DefaultFooter/FooterAnimation";
import Footer from "examples/Footers/DefaultFooter/template/Footer";

// Routes
import { routes } from "routes";
import footerRoutes from "footer.routes";

// Images
import image from "assets/images/svgs/grid.svg";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const MKPageHeaderVideoTemplate = forwardRef(
  (
    {
      variant,
      bgColor,
      bgVideo,
      title,
      subTitle,
      socials,
      color,
      opacity,
      borderRadius,
      shadow,
      coloredShadow,
      children,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme();
    const isXXSmallScreen = useMediaQuery(theme.breakpoints.down("xxs"));
    const isXSmallScreen = useMediaQuery(
      theme.breakpoints.between("xxs", "xs")
    );
    const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isXLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
    const isXXLargeScreen = useMediaQuery(
      theme.breakpoints.between("xl", "xxl")
    );
    const isXXXLargeScreen = useMediaQuery(theme.breakpoints.up("xxl"));

    return (
      <MKPageHeaderVideoTemplateRoot
        {...rest}
        ref={ref}
        ownerState={{
          variant,
          bgColor,
          color,
          opacity,
          borderRadius,
          shadow,
          coloredShadow,
        }}
      >
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://www.vertical-lake.com",
            label: "Call Us",
            color: "default",
          }}
          transparent
          light
          // sticky
        />
        <MKBox
          minHeight={
            isXXSmallScreen
              ? "55vh"
              : isXSmallScreen
              ? "45vh"
              : isSmallScreen
              ? "40vh"
              : isMediumScreen
              ? "45vh"
              : isLargeScreen
              ? "40vh"
              : isXLargeScreen
              ? "45vh"
              : isXXLargeScreen
              ? "45vh"
              : "45vh"
          }
          width="100%"
          sx={{
            position: "relative",
            backgroundImage: "none", // Remove background image
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
            opacity: 0.3,
          }}
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: "100%",
              height: isXXSmallScreen
                ? "55vh"
                : isXSmallScreen
                ? "45vh"
                : isSmallScreen
                ? "40vh"
                : isMediumScreen
                ? "45vh"
                : isLargeScreen
                ? "40vh"
                : isXLargeScreen
                ? "45vh"
                : isXXLargeScreen
                ? "45vh"
                : "45vh",
              objectFit: "fill",
            }}
          >
            <source src={bgVideo} type="video/mp4" />
            {/* Add additional source elements for other video formats if needed */}
            Your browser does not support the video tag.
          </video>
        </MKBox>
        <Container
          sx={{
            position: "absolute",
            zIndex: 2,
            top: isXXSmallScreen
              ? "30%"
              : isXSmallScreen
              ? "25%"
              : isSmallScreen
              ? "20%"
              : isMediumScreen
              ? "20%"
              : isLargeScreen
              ? "20%"
              : isXLargeScreen
              ? "25%"
              : isXXLargeScreen
              ? "25%"
              : "25%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant={
                isXXSmallScreen
                  ? "h6"
                  : isXSmallScreen
                  ? "h6"
                  : isSmallScreen
                  ? "h4"
                  : isMediumScreen
                  ? "h4"
                  : isLargeScreen
                  ? "h4"
                  : isXLargeScreen
                  ? "h3"
                  : isXXLargeScreen
                  ? "h3"
                  : "h3"
              }
              // sx={({ breakpoints, typography: { size } }) => ({
              //   [breakpoints.down("md")]: {
              //     fontSize: size["3xl"],
              //   },
              //   color: ({ palette: { text } }) => text.white,
              // })}
              sx={{
                color: ({ palette: { text } }) => text.white,
              }}
            >
              {title}
            </MKTypography>
            <MKTypography
              variant={
                isXXSmallScreen
                  ? "body2"
                  : isXSmallScreen
                  ? "body2"
                  : isSmallScreen
                  ? "h5"
                  : isMediumScreen
                  ? "h5"
                  : isLargeScreen
                  ? "h5"
                  : isXLargeScreen
                  ? "h4"
                  : isXXLargeScreen
                  ? "h4"
                  : "h4"
              }
              sx={{
                color: ({ palette: { text } }) => text.light,
              }}
              opacity={0.8}
              mt={1}
              mb={3}
            >
              {subTitle}
            </MKTypography>
          </Grid>
        </Container>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({
              palette: { backgroundCard },
              functions: { rgba },
            }) => rgba(backgroundCard.dark, 0.9),
            backdropFilter: "saturate(200%) blur(130px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            backgroundImage: ({
              palette: { black, text },
              functions: { linearGradient, rgba },
            }) =>
              `${linearGradient(
                rgba(black.main, 0.1),
                rgba(black.main, 0.1)
              )}, url(${image})`,
            backgroundSize: "contain",
          }}
        >
          {children}
        </Card>
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
        <MKBox
          sx={{
            color: ({ palette: { text } }) => text.light,
          }}
        >
          <FooterAnimation />
        </MKBox>
      </MKPageHeaderVideoTemplateRoot>
    );
  }
);

// Setting default values for the props of MKPageHeaderVideoTemplate
MKPageHeaderVideoTemplate.defaultProps = {
  variant: "contained",
  bgColor: "transparent",
  bgVideo: "",
  title: "",
  subTitle: "",
  socials: false,
  color: "dark",
  opacity: 1,
  borderRadius: "none",
  shadow: "none",
  coloredShadow: "none",
};

// Typechecking props for the MKPageHeaderVideoTemplate
MKPageHeaderVideoTemplate.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  bgColor: PropTypes.string,
  bgVideo: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  socials: PropTypes.bool,
  color: PropTypes.string,
  opacity: PropTypes.number,
  borderRadius: PropTypes.string,
  shadow: PropTypes.string,
  coloredShadow: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "none",
  ]),
};

export default MKPageHeaderVideoTemplate;
