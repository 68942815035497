// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function BackgroundBlogCard({
  image,
  title,
  description,
  action,
  action2,
  action3,
}) {
  const cardActionStyles = {
    display: "flex",
    alignItems: "center",
    width: "max-content",
    color: "rgba(255, 255, 255)",

    "& .material-icons, .material-icons-round,": {
      transform: `translateX(2px)`,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
      {
        transform: `translateX(6px)`,
      },
  };

  return (
    <Card
      sx={{
        backgroundImage: ({
          palette: { black },
          functions: { linearGradient, rgba },
        }) =>
          `${linearGradient(
            rgba(black.main, 0.5),
            rgba(black.main, 0.5)
          )}, url(${image})`,
        backgroundSize: "cover",
      }}
    >
      <MKBox p={3}>
        <MKBox minHeight="20.625rem" my="auto" py={3}>
          <MKTypography
            variant="h2"
            color="white"
            mb={1}
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
              color: ({ palette: { text } }) => text.main,
            })}
          >
            {title}
          </MKTypography>
          <MKTypography
            variant="body2"
            color="white"
            my={3}
            sx={{
              color: ({ palette: { text } }) => text.main,
            }}
          >
            {description}
          </MKTypography>
          {action.type === "internal" ? (
            <>
              <MKTypography
                component={Link}
                to={action?.route}
                variant="body2"
                fontWeight="regular"
                color="white"
                textTransform="capitalize"
                pb={3}
                sx={cardActionStyles}
              >
                {action?.label}
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </MKTypography>
              {/* <MKTypography
                component={Link}
                to={action2?.route}
                variant="body2"
                fontWeight="regular"
                color="white"
                textTransform="capitalize"
                pb={3}
                sx={cardActionStyles}
              >
                {action2?.label}
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </MKTypography>
              <MKTypography
                component={Link}
                to={action3?.route}
                variant="body2"
                fontWeight="regular"
                color="white"
                textTransform="capitalize"
                pb={3}
                sx={cardActionStyles}
              >
                {action3?.label}
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </MKTypography> */}
            </>
          ) : (
            <MKTypography
              component={MuiLink}
              href={action?.route}
              target="_blank"
              rel="noreferrer"
              variant="body2"
              fontWeight="regular"
              color="white"
              textTransform="capitalize"
              sx={cardActionStyles}
            >
              {action?.label}
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          )}
        </MKBox>
      </MKBox>
    </Card>
  );
}

// Typechecking props for the BackgroundBlogCard
BackgroundBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

export default BackgroundBlogCard;
