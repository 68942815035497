import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Material Dashboard 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { getMyComplaintsList } from "actions/complaintActions";
import DataTable from "examples/Tables/DataTable";
import MKBadge from "components/MKBadge";
import MKAvatar from "components/MKAvatar";
import moment from "moment";

function ComplaintDetails({ title }) {
  const dispatch = useDispatch();

  const myListComplaint = useSelector((state) => state.myListComplaint);
  const { myComplaintsList } = myListComplaint;

  useEffect(() => {
    dispatch(getMyComplaintsList());
  }, [dispatch]);

  const Supplier = ({ image, name, email }) => (
    <MKBox display="flex" alignItems="center" lineHeight={1}>
      <MKAvatar src={image} name={name} size="sm" />
      <MKBox>
        <MKTypography
          variant="button"
          fontWeight="medium"
          ml={1}
          lineHeight={1}
          sx={{
            color: ({ palette: { text } }) => text.darkAccent,
          }}
        >
          {name}
        </MKTypography>
        <MKBox mt={1.5}>
          <MKTypography
            variant="button"
            fontSize="small"
            ml={1}
            lineHeight={1}
            sx={{
              color: ({ palette: { text } }) => text.darkAccent,
            }}
          >
            {email}
          </MKTypography>
        </MKBox>
      </MKBox>
    </MKBox>
  );

  function renderRows(data) {
    const values = [];
    data?.forEach((item, index) => {
      const newElement = {
        supplier: (
          <Link to={`/complaints/${item?._id}`}>
            <Supplier
              key={index}
              image={item?.order?.supplier?.supplierLogo}
              name={item?.order?.supplier?.supplierName}
              email={item?.order?.supplier?.supplierEmail}
            />
          </Link>
        ),
        complaint: (
          <MKBox>
            <MKTypography
              variant="caption"
              sx={{
                color: ({ palette: { text } }) => text.darkAccent,
              }}
              fontWeight="medium"
            >
              {item?.complaintNumber}
            </MKTypography>
            <MKBox mt={1.5}>
              <MKTypography variant="button" fontSize="small" lineHeight={1}>
                Ksh. {item?.amount}
              </MKTypography>
            </MKBox>
          </MKBox>
        ),
        product: (
          <MKBox>
            <MKTypography
              variant="caption"
              sx={{
                color: ({ palette: { text } }) => text.darkAccent,
              }}
              fontWeight="medium"
            >
              {item?.order?.product?.productName}
            </MKTypography>
            <MKBox mt={1.5}>
              <MKTypography variant="button" fontSize="small" lineHeight={1}>
                Ksh. {item?.order?.product?.premiumAmount}
              </MKTypography>
            </MKBox>
          </MKBox>
        ),
        date: (
          <MKTypography
            variant="caption"
            sx={{
              color: ({ palette: { text } }) => text.darkAccent,
            }}
            fontWeight="medium"
          >
            {moment(item?.created_at).format("DD MMM YYYY, HH mm")}
          </MKTypography>
        ),
        status: (
          <MKBox width="8rem" textAlign="center">
            <MKBox ml={-1}>
              <MKBadge
                badgeContent={item?.status}
                color="primary"
                variant="gradient"
                size="sm"
              />
            </MKBox>
          </MKBox>
        ),
      };
      values.push(newElement);
    });
    return values;
  }

  return (
    <MKBox>
      <DataTable
        table={{
          columns: [
            {
              Header: "supplier",
              accessor: "supplier",
              width: "25%",
              align: "left",
            },
            {
              Header: "complaint",
              accessor: "complaint",
              width: "20%",
              align: "left",
            },
            {
              Header: "product",
              accessor: "product",
              width: "20%",
              align: "left",
            },
            {
              Header: "date",
              accessor: "date",
              width: "15%",
              align: "left",
            },
            {
              Header: "status",
              accessor: "status",
              width: "20%",
              align: "center",
            },
          ],

          rows: renderRows(myComplaintsList),
        }}
        showTotalEntries={false}
        isSorted={false}
        noEndBorder
        entriesPerPage={false}
      />
    </MKBox>
  );
}

export default ComplaintDetails;
