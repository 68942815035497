import axios from "axios";
import {
  MY_ORDER_LIST_REQUEST,
  MY_ORDER_LIST_SUCCESS,
  MY_ORDER_LIST_FAIL,
  ORDER_STATS_REQUEST,
  ORDER_STATS_SUCCESS,
  ORDER_STATS_FAIL,
  ORDER_SINGLE_REQUEST,
  ORDER_SINGLE_SUCCESS,
  ORDER_SINGLE_FAIL,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  INCOMPLETE_ORDER_LIST_REQUEST,
  INCOMPLETE_ORDER_LIST_SUCCESS,
  INCOMPLETE_ORDER_LIST_FAIL,
  APPROVED_ORDER_LIST_REQUEST,
  APPROVED_ORDER_LIST_SUCCESS,
  APPROVED_ORDER_LIST_FAIL,
  SHIPPING_ORDER_LIST_REQUEST,
  SHIPPING_ORDER_LIST_SUCCESS,
  SHIPPING_ORDER_LIST_FAIL,
  DELIVERED_ORDER_LIST_REQUEST,
  DELIVERED_ORDER_LIST_SUCCESS,
  DELIVERED_ORDER_LIST_FAIL,
} from "constants/orderConstants";
import { REACT_APP_API_URL } from "constants/apiConstants";
import OrdersService from "services/orders-service";

export const getMyOrderList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: MY_ORDER_LIST_REQUEST,
    });

    const res = await OrdersService.getMyOrders();
    console.log("🚀 ~ file: orderActions.js:32 ~ getMyOrderList ~ res:", res);

    dispatch({
      type: MY_ORDER_LIST_SUCCESS,
      payload: res.foundOrders,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: orderActions.js:39 ~ getMyOrderList ~ error:",
      error
    );
    dispatch({
      type: MY_ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getOrderStats = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_STATS_REQUEST,
    });

    const res = await OrdersService.getOrdersStats();
    console.log("🚀 ~ file: orderActions.js:69 ~ getOrderStats ~ res:", res);

    dispatch({
      type: ORDER_STATS_SUCCESS,
      payload: res.result,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: orderActions.js:76 ~ getOrderStats ~ error:",
      error
    );
    dispatch({
      type: ORDER_STATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getOrderSingle = (orderId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_SINGLE_REQUEST,
    });

    const res = await OrdersService.getSingleOrder(orderId);
    console.log("🚀 ~ file: orderActions.js:97 ~ getOrderSingle ~ res:", res);

    dispatch({
      type: ORDER_SINGLE_SUCCESS,
      payload: res.foundOrder,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: orderActions.js:104 ~ getOrderSingle ~ error:",
      error
    );
    dispatch({
      type: ORDER_SINGLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const createNewOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATE_ORDER_REQUEST,
    });

    const res = await OrdersService.createNewOrder(order);
    console.log("🚀 ~ file: orderActions.js:122 ~ createOrder ~ res:", res);
    dispatch(getMyOrderList());

    dispatch({
      type: CREATE_ORDER_SUCCESS,
      payload: res?.newOrder,
    });
  } catch (error) {
    console.log("🚀 ~ file: orderActions.js:129 ~ createOrder ~ error:", error);
    dispatch({
      type: CREATE_ORDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const updateOrder = (orderId, order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_ORDER_REQUEST,
    });

    const res = await OrdersService.editOrder(orderId, order);
    console.log("🚀 ~ file: orderActions.js:147 ~ updateOrder ~ res:", res);
    dispatch(getOrderList());
    dispatch(getOrderSingle(orderId));

    dispatch({
      type: UPDATE_ORDER_SUCCESS,
      payload: res,
    });
  } catch (error) {
    console.log("🚀 ~ file: orderActions.js:154 ~ updateOrder ~ error:", error);
    dispatch({
      type: UPDATE_ORDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const deleteOrder = (orderId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_ORDER_REQUEST,
    });

    const res = await axios.get(
      `${REACT_APP_API_URL}/admin/orders/order/${orderId}`
    );

    dispatch({
      type: DELETE_ORDER_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ORDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getIncompleteOrderList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: INCOMPLETE_ORDER_LIST_REQUEST,
    });

    const res = await OrdersService.getIncompleteOrders();
    console.log(
      "🚀 ~ file: orderActions.js:82 ~ getIncompleteOrderList ~ res:",
      res
    );

    dispatch({
      type: INCOMPLETE_ORDER_LIST_SUCCESS,
      payload: res.foundOrders,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: orderActions.js:88 ~ getIncompleteOrderList ~ error:",
      error
    );
    dispatch({
      type: INCOMPLETE_ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getApprovedOrderList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPROVED_ORDER_LIST_REQUEST,
    });

    // const { adminLogin: { adminInfo } } = getState();

    // const config = {
    //   headers: {
    //     Authorization: adminInfo.token
    //   }
    // }

    const res = await axios.get(`${REACT_APP_API_URL}/admin/orders/approved`);

    dispatch({
      type: APPROVED_ORDER_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: APPROVED_ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getShippingOrderList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SHIPPING_ORDER_LIST_REQUEST,
    });

    // const { adminLogin: { adminInfo } } = getState();

    // const config = {
    //   headers: {
    //     Authorization: adminInfo.token
    //   }
    // }

    const res = await axios.get(`${REACT_APP_API_URL}/admin/orders/shipping`);

    dispatch({
      type: SHIPPING_ORDER_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SHIPPING_ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};

export const getDeliveredOrderList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELIVERED_ORDER_LIST_REQUEST,
    });

    // const { adminLogin: { adminInfo } } = getState();

    // const config = {
    //   headers: {
    //     Authorization: adminInfo.token
    //   }
    // }

    const res = await axios.get(`${REACT_APP_API_URL}/admin/orders/delivered`);

    dispatch({
      type: DELIVERED_ORDER_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: DELIVERED_ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error,
    });
  }
};
