import { items } from "./data";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function Card({
  id,
  title,
  subTitle,
  subTitle2,
  theme,
  setSelectedId,
  linkButton,
}) {
  return (
    <motion.li
      className={`card`}
      onClick={() => setSelectedId(id)}
      whileHover={{ scale: 1.05 }}
    >
      <Link to={`/${linkButton}`}>
        <div className="card-content-container">
          <div className="card-content" layoutId={`card-container-${id}`}>
            <div
              className="card-image-container"
              layoutId={`card-image-container-${id}`}
            >
              <img className="card-image" src={`images/${id}.jpg`} alt="" />
            </div>
            <div className="title-container" layoutId={`title-container-${id}`}>
              <h4>{title}</h4>
            </div>
            <span className="category">
              {subTitle}
              {"   "}
            </span>
            <span className="category2">{subTitle2}</span>
            <div className="link-container">
              <Link to={`/${linkButton}`} className={`link-button`}>
                More Info
              </Link>
            </div>
          </div>
        </div>
      </Link>
    </motion.li>
  );
}

export function List({ selectedId, setSelectedId }) {
  return (
    <ul className="card-list">
      {items.map((card) => (
        <Card key={card.id} {...card} setSelectedId={setSelectedId} />
      ))}
    </ul>
  );
}
